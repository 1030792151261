import arraySort from 'array-sort'
import _ from 'lodash'

import defaultFacetMap from '@/settings/algolia/default-facet-map'

export default {
  methods: {
    getQueryStringFromUrl(queryName = 'string') {
      return this.$route.query[queryName] || ''
    },
    getFacetsFromUrl() {
      let facets = this.$route.query.facets
      if (facets) {
        if (!Array.isArray(facets)) {
          facets = [facets]
        }
        facets = facets.map((el) =>
          el.slice(1, -1).includes(' OR ')
            ? el.slice(1, -1).split(' OR ')
            : el.slice(1, -1).split(' AND '),
        )
        return facets
      } else {
        return []
      }
    },
    getDecoratorForFacet(facetTitle) {
      return this.getExtendedFacetMap[facetTitle] || {}
    },
    getTitleForValue(valueTitle) {
      const arrTitles = valueTitle.split(' > ')
      return arrTitles[arrTitles.length - 1] || valueTitle
    },
    getQueryFacetsFromSelection(facets) {
      const getRefinedValues = (facetValue) => {
        const refinedValues = []
        function collectRefinedValues(value) {
          if (value.isRefined) {
            refinedValues.push(value)
          } else if (value.subvalues) {
            value.subvalues.forEach((subvalue) => {
              collectRefinedValues(subvalue)
            })
          }
        }
        collectRefinedValues(facetValue)
        return refinedValues
      }

      const refinedFacets = facets.map((facet) => {
        switch (facet.widgetType) {
          case 'checkbox':
          case 'search':
          case 'search-multiselect':
            return facet.facetValues
              .map((facetValue) => {
                const refinedValues = getRefinedValues(facetValue)
                return refinedValues.length ? refinedValues.flat().filter((el) => el) : null
              })
              .flat()
              .filter((el) => el)
          case 'range':
            if (facet.rangeValues.isRefined) return [facet.rangeValues]
            break
        }
      })
      const reduced = refinedFacets.filter((el) => el && el.length)
      return reduced
    },
    getORFacetGroupString(facets) {
      return facets
        .map((el) => el.map((el) => (el.isExcluded ? 'NOT' + el.indexTitle : el.indexTitle)))
        .map((el) => `(${el.join(' OR ')})`)
    },
    getORFacetGroupStringWithExclude(facets) {
      const excluded = facets
        .map((el) => el.filter((el) => el.isExcluded).map((el) => el.indexTitle))
        .map((el) => (el.length ? `(${el.join(' AND ')})` : null))
        .filter((el) => el)

      const unexcluded = facets
        .map((el) => el.filter((el) => !el.isExcluded).map((el) => el.indexTitle))
        .map((el) => (el.length ? `(${el.join(' OR ')})` : null))
        .filter((el) => el)

      return [...excluded, ...unexcluded]
    },
    getANDFacetGroupString(facets) {
      return facets
        .map((el) => el.map((el) => (el.isExcluded ? 'NOT' + el.indexTitle : el.indexTitle)))
        .map((el) => `(${el.join(' AND ')})`)
    },
    getFacetValueFullName(facet, value) {
      return `${facet}:"${value}"`
    },
    getExcludeFacetValueFullName(facet, value) {
      return `NOT"${facet}":"${value}"`
    },
    getSearchFacetValueFullName(facet, value, isExcluded = false) {
      return isExcluded ? `NOT"${facet}List":"${value}"` : `"${facet}List":"${value}"`
    },
    decorateFacetByWidgetType(facetModel, { facets, initialFacetStringsFlat, facet }) {
      const decoratorForFacet = this.getDecoratorForFacet(facet)

      const getCheckboxValues = () =>
        Object.keys(facets[facet]).map((facetValue) => ({
          facetDisplayTitle: facetModel.displayTitle,
          valueTitle: facetValue,
          valueQty: facets[facet][facetValue],
          facetTitle: facet,
          indexTitle: initialFacetStringsFlat.find(
            (el) => el === this.getExcludeFacetValueFullName(facet, facetValue),
          )
            ? this.getExcludeFacetValueFullName(facet, facetValue)
            : this.getFacetValueFullName(facet, facetValue),
          isRefined: !!initialFacetStringsFlat.find(
            (el) =>
              el === this.getFacetValueFullName(facet, facetValue) ||
              el === this.getExcludeFacetValueFullName(facet, facetValue),
          ),
          isOpen: !!initialFacetStringsFlat.find((el) =>
            el
              .match(/"(.*)"/)
              ?.pop()
              .includes(facetValue),
          ),
          facetWidgetType: decoratorForFacet.widgetType,
          chipTitle: `${facetModel.displayTitle} - ${facetValue}`,
          isExcluded: !!initialFacetStringsFlat.find(
            (el) => el === this.getExcludeFacetValueFullName(facet, facetValue),
          ),
        }))

      switch (decoratorForFacet.widgetType) {
        case 'range':
          /* eslint-disable no-case-declarations */
          const initialFacetString = initialFacetStringsFlat.find((el) =>
            el.includes(facetModel.facetTitle),
          )
          const initialRangeValues = initialFacetString
            ? initialFacetString.split(':')[1].split(' TO ')
            : null
          const facetKeys = Object.keys(facets[facet]).sort((a, b) => a - b)
          const defaultMin = +facetKeys[0]
          const defaultMax = +facetKeys.slice(-1)[0]
          /* eslint-enable */
          facetModel.facetValues = []
          facetModel.isOpen = !!initialFacetString || decoratorForFacet.isOpen
          facetModel.facetUom = decoratorForFacet.uom || ''
          facetModel.rangeValues = {
            facetDisplayTitle: facetModel.displayTitle,
            valueTitle: facet,
            get indexTitle() {
              return `"${this.valueTitle}":${this.range[0]} TO ${this.range[1]}`
            },
            min: defaultMin,
            max: defaultMax,
            range: initialFacetString
              ? [+initialRangeValues[0], +initialRangeValues[1]]
              : [defaultMin, defaultMax],
            interval: this.getInterval(defaultMin, defaultMax),
            get isRefined() {
              return !(this.range[0] === this.min && this.range[1] === this.max)
            },
            set isRefined(val) {
              if (val === false) {
                this.range = [this.min, this.max]
              } else {
                // @TODO faulty, doesn't include border values
                this.range = [this.min + 1, this.max - 1]
              }
            },
            facetWidgetType: decoratorForFacet.widgetType,
            get chipTitle() {
              return `${facetModel.displayTitle}: ${this.range[0]} - ${this.range[1]}`
            },
          }
          break
        case 'search':
          const initialSearchValues = [] // eslint-disable-line no-case-declarations
          if (initialFacetStringsFlat.find((el) => el.includes(facetModel.facetTitle))) {
            initialFacetStringsFlat.forEach((el) => {
              if (el.includes(facetModel.facetTitle) && facetModel.widgetType === 'search') {
                let searchTerm = el.split(':')[1]
                searchTerm = searchTerm.substring(1, searchTerm.length - 1)
                const isExcluded = el.substring(0, 3) === 'NOT'
                const value = {
                  facetDisplayTitle: facetModel.displayTitle,
                  chipTitle: `${facetModel.displayTitle} - ${searchTerm}`,
                  facetTitle: facet,
                  facetWidgetType: 'checkbox',
                  indexTitle: this.getSearchFacetValueFullName(facet, searchTerm, isExcluded),
                  isRefined: true,
                  isExcluded: isExcluded,
                  valueTitle: `Contains <b>${searchTerm}</b>`,
                  searchTerm: searchTerm,
                }
                initialSearchValues.push(value)
              }
            })
          }
          facetModel.facetValues = initialSearchValues.length ? initialSearchValues : []
          facetModel.searchTerm = ''
          facetModel.addContainsFacetValue = (facet) => {
            if (
              facet.searchTerm &&
              !facet.facetValues.find(
                (el) => el.valueTitle === `Contains <b>${facet.searchTerm}</b>`,
              )
            ) {
              const value = {
                facetDisplayTitle: facetModel.displayTitle,
                chipTitle: `${facet.displayTitle} - ${facet.searchTerm}`,
                facetTitle: facet.facetTitle,
                facetWidgetType: 'checkbox',
                indexTitle: this.getSearchFacetValueFullName(facet.facetTitle, facet.searchTerm),
                isRefined: false,
                isExcluded: false,
                valueTitle: `Contains <b>${facet.searchTerm}</b>`,
                searchTerm: facet.searchTerm,
              }

              facet.facetValues.push(value)
              facet.searchTerm = ''
              return value
            }
          }
          facetModel.removeContainsFacetValue = (facet, value) => {
            facet.facetValues = facet.facetValues.filter((el) => {
              if (el.valueTitle === value.valueTitle) {
                el.isRefined = false
              } else {
                return el.valueTitle !== value.valueTitle
              }
            })
          }
          facetModel.excludeFacetValue = (value) => {
            value.indexTitle = this.getSearchFacetValueFullName(
              value.facetTitle,
              value.searchTerm,
              value.isExcluded,
            )
          }
          Object.defineProperty(facetModel, 'filteredValues', {
            get: function () {
              const unrefined = []
              const refined = []
              const searchTerm = this.searchTerm

              this.facetValues.forEach((el) => {
                if (el.isRefined) {
                  refined.push(el)
                } else {
                  unrefined.push(el)
                }
              })
              const filtered = searchTerm
                ? unrefined.filter((el) => {
                    return el.valueTitle.toLowerCase().includes(searchTerm.toLowerCase())
                  })
                : unrefined
              return [...refined, ...filtered]
            },
          })
          break
        case 'search-multiselect':
          facetModel.facetValues = getCheckboxValues()
          facetModel.searchTerm = ''
          facetModel.excludeFacetValue = (value) => {
            if (value.isExcluded) {
              value.indexTitle = this.getExcludeFacetValueFullName(
                value.facetTitle,
                value.valueTitle,
              )
            } else {
              value.indexTitle = this.getFacetValueFullName(value.facetTitle, value.valueTitle)
            }
          }
          Object.defineProperty(facetModel, 'filteredValues', {
            get: function () {
              const unrefined = []
              const refined = []
              const searchTerm = this.searchTerm

              this.facetValues.forEach((el) => {
                if (el.isRefined) {
                  refined.push(el)
                } else {
                  unrefined.push(el)
                }
              })
              const filtered = searchTerm
                ? unrefined.filter((el) => {
                    return el.valueTitle.toLowerCase().includes(searchTerm.toLowerCase())
                  })
                : unrefined
              return [...refined, ...filtered.slice(0, 5)]
            },
          })
          break
        case 'checkbox':
        default:
          facetModel.facetValues = getCheckboxValues()
          facetModel.excludeFacetValue = (value) => {
            if (value.isExcluded) {
              value.indexTitle = this.getExcludeFacetValueFullName(
                value.facetTitle,
                value.valueTitle,
              )
            } else {
              value.indexTitle = this.getFacetValueFullName(value.facetTitle, value.valueTitle)
            }
          }
          break
      }
      return facetModel
    },
    updateFacetValuesWithNewQty(facets, newFacetsData, isFiltersApplied = false) {
      facets.forEach((el) => {
        const facet = newFacetsData[el.facetTitle]
        if (el.widgetType === 'range') {
          if (facet && !el.rangeValues.isRefined) {
            const facetKeys = Object.keys(facet).sort((a, b) => a - b)
            el.rangeValues.min = +facetKeys[0]
            el.rangeValues.max = +facetKeys.slice(-1)[0]
            el.rangeValues.range = [+facetKeys[0], +facetKeys.slice(-1)[0]]
            el.rangeValues.interval = this.getInterval(+facetKeys[0], +facetKeys.slice(-1)[0])
          }
          return
        }
        el.facetValues.forEach((facetValue) => {
          facetValue.valueQty = facet?.[facetValue.valueTitle] || 0
          const updateSubvaluesQty = (facetValue) => {
            facetValue.subvalues.forEach((subvalue) => {
              const subvalueFacet = newFacetsData[subvalue.facetTitle]
              subvalue.valueQty = subvalueFacet?.[subvalue.valueTitle] || 0
              if (subvalue.subvalues) updateSubvaluesQty(subvalue)
            })
            facetValue.subvalues = this.sortFacetValues(facetValue.subvalues, isFiltersApplied)
          }
          if (facetValue.subvalues) updateSubvaluesQty(facetValue)
        })

        el.facetValues = this.sortFacetValues(el.facetValues, isFiltersApplied)
      })
    },
    sortFacetValues(facetValues, isFiltersApplied = false) {
      const copyFacetValues = [...facetValues]
      if (isFiltersApplied) {
        copyFacetValues.sort((a, b) => {
          if (a.isRefined !== b.isRefined) return a.isRefined ? -1 : 1
          if (a.valueQty === b.valueQty) return a.valueTitle.localeCompare(b.valueTitle)
          return b.valueQty - a.valueQty
        })
      } else {
        copyFacetValues.sort((a, b) => a.valueTitle.localeCompare(b.valueTitle))
      }
      return copyFacetValues
    },
    getSearchIndexBySorting(sorting) {
      return this.$search.service.getPrimaryIndex(sorting)
    },
    getResourceIndexBySorting(sorting) {
      return this.$search.service.getResourceIndex(sorting)
    },
    async loadAllEntries(fn) {
      const hitsPerPage = 1000
      let currentPage = 0
      let totalPages = 0
      let queryID = ''
      let nbHits = 0
      const hits = []

      do {
        const res = await fn(hitsPerPage, currentPage)
        queryID = res.queryID
        nbHits = res.nbHits
        totalPages = res.nbPages
        currentPage = res.page + 1
        hits.push(...res.hits)
      } while (currentPage < totalPages)

      return {
        hits,
        queryID,
        nbHits,
        nbPages: totalPages,
      }
    },
    fetchResults(params, clickAnalyticsEnabled = true) {
      const sorting = params.sorting
      const query = params.query

      delete params.sorting
      delete params.query

      return this.getSearchIndexBySorting(sorting).search(query, {
        ...params,
        clickAnalytics: clickAnalyticsEnabled,
      })
    },
    fetchResources(params, clickAnalyticsEnabled = true) {
      const sorting = params.sorting
      const query = params.query

      delete params.sorting
      delete params.query

      return this.getResourceIndexBySorting(sorting).search(query, {
        ...params,
        clickAnalytics: clickAnalyticsEnabled,
      })
    },
    fetchInventory(params, clickAnalyticsEnabled = true) {
      const query = params.query
      delete params.query

      return this.$search.service
        .getInventoryIndex()
        .search(query, { ...params, clickAnalytics: clickAnalyticsEnabled })
    },
    fetchSkuProducts(params, clickAnalyticsEnabled = true) {
      const sorting = params.sorting
      const query = params.query
      const defaultFilters = []

      delete params.sorting
      delete params.query

      return this.$search.service.getProductIndex(sorting).search(query, {
        ...params,
        filters: [...defaultFilters, params.filters].filter((el) => el).join(' AND '),
        clickAnalytics: clickAnalyticsEnabled,
      })
    },
    async fetchSkuProduct(queryString) {
      if (!queryString) return
      const defaultFilters = ['sageDisplayFlag:true', 'myCatalog:false']
      const encodedQueryString = encodeURIComponent(queryString)
      const { hits: skuHits } = await this.$search.service.getProductIndex().search(queryString, {
        hitsPerPage: 1,
        filters: [
          ...defaultFilters,
          `(code:"${encodedQueryString}" OR legacyProductCode:"${encodedQueryString}")`,
        ]
          .filter((el) => el)
          .join(' AND '),
      })
      const bareSkuProduct = skuHits[0]
      if (!bareSkuProduct) return
      bareSkuProduct.contentType = 'skuProduct'
      if (!bareSkuProduct.pgpSlug) return bareSkuProduct
      const { hits: contentfulHits } = await this.fetchResults({
        filters: `slug:"${bareSkuProduct.pgpSlug}"`,
        hitsPerPage: 1,
      })
      return contentfulHits.length ? { ...contentfulHits[0], ...bareSkuProduct } : bareSkuProduct
    },
    matchFacetWithPage(el, facet) {
      let level = el.split(':')[0]
      if (level.includes('lvl1')) level = level.replace('lvl1', 'lvl0')
      else if (level.includes('lvl2')) level = level.replace('lvl2', 'lvl1')
      return level === facet
    },
    groupFacets(facets, initialFacetStrings) {
      const initialFacetStringsFlat = initialFacetStrings?.flat() || []
      let grouped = Object.keys(facets).map((facet) => {
        const decoratorForFullFacetName = this.getDecoratorForFacet(facet)
        const facetName = facet.split('.')[0]
        const facetDecorator = this.getDecoratorForFacet(facetName)
        const facetModel = {
          isVisible: !facetDecorator.isHidden,
          facetTitle: facet,
          displayTitle: facetDecorator.displayTitle || decoratorForFullFacetName.title,
          order: facetDecorator.sortOrder || decoratorForFullFacetName.sortOrder,
          facetValuesOrder: facetDecorator.facetValuesOrder,
          isOpen: initialFacetStringsFlat.length
            ? !!initialFacetStringsFlat.find((el) => this.matchFacetWithPage(el, facet)) ||
              decoratorForFullFacetName.isOpen
            : decoratorForFullFacetName.isOpen,
          facetType: facetDecorator.facetType || 'OR',
          widgetType: facetDecorator.widgetType || 'checkbox',
          hasExcludeFeature: facetDecorator.hasExcludeFeature,
          group: facetDecorator.group,
          subGroup: facetDecorator.subGroup,
          isValuesSearchable: !!facetDecorator.isValuesSearchable,
        }
        return this.decorateFacetByWidgetType(facetModel, {
          facets,
          initialFacetStringsFlat,
          facet,
        })
      })
      grouped.forEach((el, idx) => {
        if (el.facetTitle.includes('lvl1')) {
          el.facetValues = el.facetValues.map((el) => ({
            ...el,
            parentTitle: el.valueTitle.split(' > ')[0],
          }))
          const groupedValues = _.groupBy(el.facetValues, 'parentTitle')
          const lvl0Facet = grouped.filter(
            (facet) => facet.facetTitle === `${el.facetTitle.split('.')[0]}.lvl0`,
          )[0]
          lvl0Facet.facetValues.forEach((lvl1Facet) => {
            lvl1Facet.subvalues = groupedValues[lvl1Facet.valueTitle]?.map((lvl2Facet) => {
              lvl2Facet.parent = lvl1Facet
              lvl2Facet.valueDisplayTitle = this.getTitleForValue(lvl2Facet.valueTitle)
              if (lvl2Facet.parent.isRefined === true) lvl2Facet.isRefined = true
              if (groupedValues[lvl1Facet.valueTitle].length === 1 && lvl2Facet.isRefined === true)
                lvl2Facet.parent.isRefined = true

              const lvl2FacetGroup = grouped.filter(
                (facetGroup) => facetGroup.facetTitle === `${el.facetTitle.split('.')[0]}.lvl2`,
              )[0]
              if (lvl2FacetGroup) {
                lvl2FacetGroup.facetValues = lvl2FacetGroup.facetValues.map((el) => ({
                  ...el,
                  parentTitle: el.valueTitle.split(' > ')[1],
                }))
                const groupedLvl3Facets = _.groupBy(lvl2FacetGroup.facetValues, 'parentTitle')
                lvl2Facet.subvalues = groupedLvl3Facets[lvl2Facet.valueTitle.split(' > ')[1]]?.map(
                  (lvl3Facet) => {
                    lvl3Facet.parent = lvl2Facet
                    lvl3Facet.valueDisplayTitle = this.getTitleForValue(lvl3Facet.valueTitle)
                    if (lvl3Facet.parent.isRefined === true) lvl3Facet.isRefined = true
                    if (
                      groupedLvl3Facets[lvl2Facet.valueTitle.split(' > ')[1]].length === 1 &&
                      lvl3Facet.isRefined === true
                    )
                      lvl3Facet.parent.isRefined = true
                    return lvl3Facet
                  },
                )
              }

              return lvl2Facet
            })
          })
          delete grouped[idx]
        }
      })
      grouped.forEach((el, idx) => {
        if (el.facetTitle.includes('lvl2')) delete grouped[idx]
      })
      grouped = grouped.filter(
        (el) => ['contentType', 'isFeatured', 'featuredRank'].indexOf(el.facetTitle) === -1,
      )
      grouped = arraySort(grouped, 'order')
      return grouped
    },
    clearFacets(facets) {
      const clearFacetValue = (value) => {
        value.isRefined = false
        value.isExcluded = false
        value.indexTitle = this.getFacetValueFullName(value.facetTitle, value.valueTitle)
        if (value.subvalues) {
          value.subvalues.forEach((subvalue) => {
            clearFacetValue(subvalue)
          })
        }
      }

      facets.forEach((el) => {
        if (el.widgetType === 'range') {
          el.rangeValues.range = [el.rangeValues.min, el.rangeValues.max]
        }
        el.isOpen = this.getDecoratorForFacet(el.facetTitle).isOpen
        el.facetValues.forEach((value) => {
          clearFacetValue(value)
        })
      })
    },
    toggleItemRefinement(item) {
      if (item.isRefined) item.isExcluded = false
      const newRefinementValue = !item.isRefined
      item.isRefined = newRefinementValue

      function updateSubvalues(subvalues) {
        subvalues.forEach((subvalue) => {
          subvalue.isRefined = newRefinementValue
          if (subvalue.subvalues) updateSubvalues(subvalue.subvalues)
        })
      }
      if (item.subvalues) updateSubvalues(item.subvalues)

      function updateParent(itemToUpdate) {
        if (!itemToUpdate.parent) return

        if (!newRefinementValue) {
          itemToUpdate.parent.isRefined = false
        } else if (itemToUpdate.parent.subvalues.every((subvalue) => subvalue.isRefined)) {
          itemToUpdate.parent.isRefined = true
        }

        if (itemToUpdate.parent.parent) updateParent(itemToUpdate.parent)
      }
      if (item.parent) updateParent(item)
    },
    toggleItemExclusion(item) {
      item.isExcluded = !item.isExcluded
    },
    sendSearchTracking(item, objectID, position, queryID, sorting) {
      const trackingData = {
        positions: [position + 1],
        objectIDs: [objectID],
        queryID: queryID,
        indexName:
          item.entryType === 'product'
            ? this.$search.service.getPrimaryIndexNameBySorting(sorting)
            : this.$search.service.getResourceIndexNameBySorting(sorting),
      }
      this.$analytics.sendSearchClick(trackingData, item)
    },
    getInterval(min, max) {
      if (Number.isInteger(min) && Number.isInteger(max)) {
        return 1
      } else {
        const minFloatingQty = Number.isInteger(min) ? 0 : min.toString().split('.')[1].length
        const maxFloatingQty = Number.isInteger(max) ? 0 : max.toString().split('.')[1].length
        const findInterval = (val) => {
          const intervalList = [0.1, 0.01, 0.001, 0.0001, 0.00001]
          for (let i = 0; i < intervalList.length; i++) {
            if (i + 1 === val) return intervalList[i]
          }
        }
        return minFloatingQty >= maxFloatingQty
          ? findInterval(minFloatingQty)
          : findInterval(maxFloatingQty)
      }
    },
    async searchWithinFacetValues(facetName, facetQuery) {
      if (!this.activeIndex?.indexName) return []
      const { facetHits } = await this.activeIndex.searchForFacetValues(facetName, facetQuery)
      return facetHits
    },
    getFacetValueTitle(facet) {
      return facet.facetWidgetType === 'range'
        ? `${facet.range[0]} - ${facet.range[1]}`
        : facet.valueTitle
    },
  },
  computed: {
    getExtendedFacetMap() {
      return _.merge({}, defaultFacetMap, this.componentFacetMap)
    },
  },
  data() {
    return {
      componentFacetMap: {},
      activeIndex: {},
    }
  },
}
