import { required } from '@vuelidate/validators'
import { mapActions } from 'vuex'

import { email } from '@/lib/utils/validationHelpers'

import FormInput from '@/components/common/FormInput.vue'

import { PAGETYPES } from '@/settings/constants'

export default {
  components: {
    FormInput,
  },
  computed: {
    isModelValid() {
      return !this.v$.$invalid
    },
  },
  methods: {
    ...mapActions('app', ['setNewsletterMail']),
    newsletterRequest() {
      if (this.isModelValid) {
        this.setNewsletterMail(this.newsletterEmail.value)
        const newsletterPage = this.$contentful.service.getPagesByPageType(
          PAGETYPES.CATEGORY_RESOURCE_NEWSLETTER,
        )[0]
        if (newsletterPage) this.$router.push({ path: newsletterPage.link })
      } else {
        this.isValidationRun = true
      }
    },
  },
  validations() {
    return {
      newsletterEmail: {
        value: {
          required,
          email,
        },
      },
    }
  },
  data() {
    return {
      newsletterSuccessMsg: false,
      isValidationRun: false,
      tags: null,
      newsletterEmail: {
        placeholder: 'Email Address',
        value: '',
        title: 'username',
        type: 'email',
        maxLength: 80,
        size: 'sub-middle',
        errors: {
          required: 'Please complete this required field.',
          email: 'Email must be formatted correctly.',
        },
      },
    }
  },
}
