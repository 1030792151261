<template>
  <div class="preview-banner">
    <span>Contentful preview mode activated.</span>
    <app-button
      type="button"
      variant="link-underline"
      text="Click here to load regular mode"
      class="preview-banner__button"
      @clicked="closePreview"
    ></app-button>
  </div>
</template>
<script>
export default {
  methods: {
    closePreview() {
      const url = new URL(window.location.href)
      url.searchParams.delete('preview-token')
      window.location.href = url.toString()
    },
  },
}
</script>
