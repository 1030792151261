import scroll from '@/lib/utils/scroll'

export default {
  methods: {
    anchorTo(refName) {
      if (refName) {
        const element = this.$refs[refName]
        if (element && element.length) {
          scroll.scrollTo(element[0].offsetTop)
        }
      }
    },
    scrollIntoEl(refName) {
      if (refName) {
        const element = this.$refs[refName]
        if (element && element.length) {
          element[0].$el.scrollIntoView()
        }
      }
    },
  },
}
