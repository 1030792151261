import 'swiper/css'
import '@/styles/app.scss'
import '@hoppscotch/vue-toasted/style.css'
import 'floating-vue/dist/style.css'
import 'vue-datepicker-next/index.css'

import Toasted from '@hoppscotch/vue-toasted'
import { VueHeadMixin } from '@unhead/vue'
import { createHead } from '@unhead/vue/client'
import axios from 'axios'
import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import utc from 'dayjs/plugin/utc'
import FloatingVue from 'floating-vue'
import mitt from 'mitt'
import truncate from 'truncate'
import { createApp } from 'vue'

import { setVHStyleVariable } from '@/lib/utils/browserHelpers'

import AlgoliaService from '@/services/AlgoliaService'
import AnalyticsService from '@/services/AnalyticsService'
import ChatService from '@/services/ChatService'
import ContentfulService from '@/services/ContentfulService'
import ErrService from '@/services/ErrService'

import { createRouter, initRouter } from '@/router/index'

import { createStore } from '@/store/index'

import Accordion from '@/components/common/Accordion.vue'
import AppButton from '@/components/common/AppButton/AppButton.vue'
import Loader from '@/components/common/Loader.vue'
import Renderer from '@/components/common/Renderer.vue'
import RichRenderer from '@/components/common/RichRenderer.vue'

import Multiselect from '@/components/vendor/vue-multiselect'
import VueScriptComponent from '@/components/vendor/vue-script-component/VueScriptComponent.vue'

import packageFile from '@/../package'
import App from '@/App.vue'
import { i18n } from '@/i18n'
import settings from '@/settings'

dayjs.extend(isBetween)
dayjs.extend(isSameOrAfter)
dayjs.extend(isSameOrBefore)
dayjs.extend(localizedFormat)
dayjs.extend(utc)

const emitter = mitt()

const router = createRouter()
const store = createStore(router)
initRouter({
  router,
  store,
  emitter,
  ContentfulService,
})

const app = createApp(App)

ErrService.init(app, router)
window.addEventListener('vite:preloadError', () => {
  window.location.reload()
})

app.use(store)
app.use(router)
app.use(Toasted, { iconPack: 'custom-class' })
app.use(FloatingVue, {
  themes: {
    tooltip: {
      triggers: ['hover', 'click'],
    },
  },
})
app.use(i18n)

const head = createHead()
app.mixin(VueHeadMixin)
app.use(head)

app.component('AppButton', AppButton)
app.component('Loader', Loader)
app.component('VueScriptComponent', VueScriptComponent)
app.component('Accordion', Accordion)
app.component('Renderer', Renderer)
app.component('Multiselect', Multiselect)
app.component('RichRenderer', RichRenderer)

app.config.globalProperties.$http = axios
app.config.globalProperties.$settings = settings
app.config.globalProperties.$truncate = truncate
app.config.globalProperties.$contentful = ContentfulService
app.config.globalProperties.$search = AlgoliaService
app.config.globalProperties.$analytics = AnalyticsService
app.config.globalProperties.$chat = ChatService
app.config.globalProperties.append = (path, pathToAppend) =>
  path + (path.endsWith('/') ? '' : '/') + pathToAppend
app.config.globalProperties.$emitter = emitter
app.config.globalProperties.$err = ErrService

setVHStyleVariable()

// @TODO Address caching issues
sessionStorage.clear()

const appVersion = packageFile.version
console.log(`🛒 zCommerce BioIVT v${appVersion}.`) // eslint-disable-line no-undef

app.mount('#app')

export default app
