<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 25 24"
    :fill="color"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.5033 4C10.2007 4 8.33563 5.79 8.33563 8C8.33563 10.21 10.2007 12 12.5033 12C14.8059 12 16.671 10.21 16.671 8C16.671 5.79 14.8059 4 12.5033 4ZM14.6913 8C14.6913 6.84 13.7119 5.9 12.5033 5.9C11.2947 5.9 10.3153 6.84 10.3153 8C10.3153 9.16 11.2947 10.1 12.5033 10.1C13.7119 10.1 14.6913 9.16 14.6913 8ZM18.859 17C18.859 16.36 15.5978 14.9 12.5033 14.9C9.40881 14.9 6.14761 16.36 6.14761 17V18.1H18.859V17ZM4.16797 17C4.16797 14.34 9.72138 13 12.5033 13C15.2852 13 20.8386 14.34 20.8386 17V20H4.16797V17Z"
    />
  </svg>
</template>
<script>
export default {
  name: 'IconPerson',
  props: {
    color: {
      type: String,
      default: '#000',
    },
    width: {
      type: String,
      default: '25',
    },
    height: {
      type: String,
      default: '24',
    },
  },
}
</script>
