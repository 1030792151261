import api from '@/lib/api'

const _defaultState = {
  productPricesCache: {},
  allSelectedProductsForAddingToCart: [],
  inventorySavedSearches: [],
  isInventorySavedSearchesLoaded: false,
}
const getDefaultState = () => JSON.parse(JSON.stringify(_defaultState))
const state = getDefaultState()

const mutations = {
  SET_PRODUCT_PRICES_CACHE(state, data) {
    const newProductPricesCache = Object.assign({}, state.productPricesCache, data)
    state.productPricesCache = newProductPricesCache
  },
  SET_SELECTED_PRODUCTS_FOR_ADDING_TO_CART(state, data) {
    state.allSelectedProductsForAddingToCart = data
  },
  SET_INVENTORY_SAVED_SEARCHES(state, inventorySavedSearches) {
    if (!state.isInventorySavedSearchesLoaded) state.isInventorySavedSearchesLoaded = true
    state.inventorySavedSearches = inventorySavedSearches
  },
  UPDATE_INVENTORY_SAVED_SEARCH(state, inventorySavedSearch) {
    const withoutItemToUpdate = state.inventorySavedSearches.filter(
      (el) => el.code !== inventorySavedSearch.code,
    )
    state.inventorySavedSearches = [...withoutItemToUpdate, inventorySavedSearch]
  },
  RESET_STATE(state) {
    Object.assign(state, getDefaultState())
  },
}

const actions = {
  pushProductPricesToCache({ commit }, productsPriceData) {
    const productPrices = productsPriceData.reduce((sum, { objectID, itemNetPrice }) => {
      sum[objectID] = {
        objectID,
        itemNetPrice: {
          currencyIso: itemNetPrice.currencyIso,
          value: itemNetPrice.value,
          formattedValue: itemNetPrice.formattedValue,
        },
      }
      return sum
    }, {})
    commit('SET_PRODUCT_PRICES_CACHE', productPrices)
  },
  async getPriceForProducts({ dispatch, state }, inventoryProducts) {
    try {
      const productsToLoad = inventoryProducts.filter(
        ({ objectID }) => !state.productPricesCache[objectID],
      )
      if (productsToLoad.length) {
        const productsToLoadPayloads = productsToLoad.map(({ code, objectID, uom }) => ({
          productCode: code,
          priceParametersData: {
            inventoryId: objectID,
            salesUnit: uom,
            salesToStock: 1,
            aliqPerLot: 0,
            volPerAliq: 0,
          },
        }))
        const allPriceEntries = []

        const amountToLoadPerRequest = 20 // Prevent endpoint performance issues
        for (let i = 0; i < productsToLoadPayloads.length; i += amountToLoadPerRequest) {
          const chunk = productsToLoadPayloads.slice(i, i + amountToLoadPerRequest)
          const { data: priceEntries } = await api.products.getProductsPrices(chunk)
          allPriceEntries.push(...priceEntries)
        }

        const pricesToStoreInCache = allPriceEntries.map((priceEntry, idx) => {
          return {
            objectID: productsToLoad[idx].objectID,
            itemNetPrice: priceEntry.itemNetPrice,
          }
        })
        await dispatch('pushProductPricesToCache', pricesToStoreInCache)
      }

      return inventoryProducts.map(({ objectID }) => {
        const priceData = state.productPricesCache[objectID]
        return priceData || { objectID }
      })
    } catch {
      return []
    }
  },
  addProductToSelectionList({ state, commit }, product) {
    if (product.isAddedToCart) return
    const isAlreadyContains = state.allSelectedProductsForAddingToCart.some(
      (el) => el.uiId === product.uiId,
    )
    if (isAlreadyContains) return

    commit('SET_SELECTED_PRODUCTS_FOR_ADDING_TO_CART', [
      ...state.allSelectedProductsForAddingToCart,
      product,
    ])
  },
  removeProductFromSelectionList({ state, commit }, product) {
    const isContainsExactProduct = state.allSelectedProductsForAddingToCart.some(
      (el) => el.uiId === product.uiId,
    )
    if (isContainsExactProduct) {
      const withoutProvidedProduct = state.allSelectedProductsForAddingToCart.filter(
        (el) => el.uiId !== product.uiId,
      )
      commit('SET_SELECTED_PRODUCTS_FOR_ADDING_TO_CART', withoutProvidedProduct)
    }
  },
  setSelectionList({ commit }, products) {
    commit('SET_SELECTED_PRODUCTS_FOR_ADDING_TO_CART', [...products])
  },
  async setInventorySavedSearches({ commit }) {
    const { data } = await api.user.getInventorySavedSearches()
    commit('SET_INVENTORY_SAVED_SEARCHES', data)
  },
  async addInventorySavedSearch({ dispatch }, inventorySavedSearch) {
    await api.user.addInventorySavedSearch(inventorySavedSearch)
    await dispatch('setInventorySavedSearches')
  },
  async deleteInventorySavedSearch({ dispatch }, code) {
    await api.user.deleteInventorySavedSearches(code)
    await dispatch('setInventorySavedSearches')
  },
  async updateInventorySavedSearch({ dispatch }, inventorySavedSearch) {
    await api.user.updateInventorySavedSearch(inventorySavedSearch)
    await dispatch('setInventorySavedSearches')
  },
}

const getters = {
  uniqueSelectedProductsForAddingToCart: (state) => {
    const uniqueProductsMap = {}
    state.allSelectedProductsForAddingToCart.forEach((el) => {
      if (!uniqueProductsMap[el.objectID]) uniqueProductsMap[el.objectID] = el
    })
    return Object.values(uniqueProductsMap)
  },
}

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true,
}
