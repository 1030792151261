<template>
  <div class="asm-password-tab">
    <form class="account__form" @submit.prevent="onSubmit">
      <div class="account__form-row">
        <span class="account__form-label" v-html="form.password.fieldLabel" />
        <div class="account__form-input-wrapper">
          <form-input
            v-model.trim="v$.form.password.$model.value"
            :field="v$.form.password"
            :isValidationRun="isValidationRun"
            @update:modelValue="onFieldChange(form.password)"
          />
        </div>
      </div>

      <div class="account__form-row">
        <span class="account__form-label" v-html="v$.form.newPassword.$model.fieldLabel" />
        <div class="account__form-input-wrapper">
          <form-input
            v-model.trim="v$.form.newPassword.$model.value"
            :field="v$.form.newPassword"
            :isValidationRun="isValidationRun"
            @update:modelValue="onFieldChange(form.newPassword)"
          />
        </div>
      </div>

      <div class="account__form-row">
        <span class="account__form-label" v-html="v$.form.confirmPassword.$model.fieldLabel" />
        <div class="account__form-input-wrapper">
          <form-input
            v-model.trim="v$.form.confirmPassword.$model.value"
            :field="v$.form.confirmPassword"
            :isValidationRun="isValidationRun"
            @update:modelValue="onFieldChange(form.confirmPassword)"
          />
        </div>
      </div>

      <p
        v-for="(error, idx) in form._errors"
        :key="idx"
        class="text-description form-hint"
        v-html="error.message"
      />

      <p
        v-if="isChangesSaved"
        class="account__message text-description text-highlight text-highlight--light-yellow"
        v-html="$t('myAccount.sucessfullSaveMessage')"
      />

      <div class="account__buttons-wrap">
        <app-button
          :text="$t('ASM.buttons.savePassword')"
          type="submit"
          size="middle"
          :disabled="!v$.$dirty || isLoaderVisible"
        />
        <app-button
          :text="$t('common.cancel')"
          type="button"
          variant="only-text"
          size="middle"
          class="asm-password-tab__cancel-btn"
          @clicked="$emit('requestClose')"
        />
      </div>
    </form>
    <loader v-if="isLoaderVisible" :inline="true" />
  </div>
</template>
<script>
import { useVuelidate } from '@vuelidate/core'
import { required, maxLength, minLength, sameAs } from '@vuelidate/validators'
import { mapState } from 'vuex'

import api from '@/lib/api'
import { server, containsDigit, containsLetter } from '@/lib/utils/validationHelpers'

import formValidationMixin from '@/components/common/mixins/formValidationMixin'
import navigationMixin from '@/components/common/mixins/navigationMixin'

import FormInput from '@/components/common/FormInput.vue'

export default {
  name: 'AsmPasswordTab',
  components: {
    FormInput,
  },
  mixins: [formValidationMixin, navigationMixin],
  emits: ['requestClose'],
  setup() {
    return {
      v$: useVuelidate(),
    }
  },
  data() {
    return {
      isValidationRun: false,
      isChangesSaved: false,
      form: {
        password: {
          placeholder: 'Password *',
          fieldLabel: 'Current Password',
          size: 'sub-middle',
          value: '',
          title: 'oldPassword',
          type: 'password',
          maxLength: 128,
          errors: {
            required:
              'Please provide an alternate password with a minimum length of 8 characters and at least 1 number.',
            containsDigit:
              'Please provide an alternate password with a minimum length of 8 characters and at least 1 number.',
            containsLetter:
              'Please provide an alternate password with a minimum length of 8 characters and at least 1 number.',
            minLength:
              'Please provide an alternate password with a minimum length of 8 characters and at least 1 number.',
          },
        },
        newPassword: {
          placeholder: 'New Password *',
          fieldLabel: 'New Password',
          size: 'sub-middle',
          value: '',
          title: 'newPassword',
          type: 'password',
          maxLength: 128,
          errors: {
            required:
              'Please provide an alternate password with a minimum length of 8 characters and at least 1 number.',
            containsDigit:
              'Please provide an alternate password with a minimum length of 8 characters and at least 1 number.',
            containsLetter:
              'Please provide an alternate password with a minimum length of 8 characters and at least 1 number.',
            minLength:
              'Please provide an alternate password with a minimum length of 8 characters and at least 1 number.',
          },
        },
        confirmPassword: {
          placeholder: 'Confirm New Password *',
          fieldLabel: 'Confirm New Password',
          size: 'sub-middle',
          value: '',
          type: 'password',
          maxLength: 128,
          errors: {
            required:
              'Please provide an alternate password with a minimum length of 8 characters and at least 1 number.',
            containsDigit:
              'Please provide an alternate password with a minimum length of 8 characters and at least 1 number.',
            containsLetter:
              'Please provide an alternate password with a minimum length of 8 characters and at least 1 number.',
            minLength:
              'Please provide an alternate password with a minimum length of 8 characters and at least 1 number.',
            same: 'Your passwords do not match.',
          },
        },
        _errors: [],
      },
    }
  },
  computed: {
    ...mapState('asm', ['asmAgentId']),
  },
  methods: {
    onSubmit() {
      this.withPresendRoutine(() => {
        const formData = {
          uid: this.asmAgentId,
          newPassword: this.form.newPassword.value,
          oldPassword: this.form.password.value,
        }

        this.isChangesSaved = false

        this.sendForm(api.auth.updateUserPassword, formData)
          .then(() => {
            this.v$.$reset()
            this.isChangesSaved = true
          })
          .finally(() => {
            this.isValidationRun = true
          })
      })
    },
  },
  validations() {
    return {
      form: {
        password: {
          value: {
            required,
            containsDigit,
            containsLetter,
            server,
            minLength: minLength(8),
            maxLength: maxLength(128),
          },
        },
        newPassword: {
          value: {
            required,
            containsDigit,
            containsLetter,
            server,
            minLength: minLength(8),
            maxLength: maxLength(128),
          },
        },
        confirmPassword: {
          value: {
            same: sameAs(this.form.newPassword.value),
            required,
            containsDigit,
            containsLetter,
            minLength: minLength(8),
            maxLength: maxLength(128),
          },
        },
      },
    }
  },
}
</script>
