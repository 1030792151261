<template>
  <div v-if="richTextData" :class="{ 'rich-text-wrapper': addWrapClass }">
    <Renderer v-if="isStringProvided" :tmpl="richTextData" :addWrapClass="false" />
    <ContentfulRichTextRenderer v-else :document="richTextData" :nodeRenderers="renderNodes" />
  </div>
</template>
<script>
import { BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types'
import { h } from 'vue'

import ContentfulRichTextRenderer from '@/lib/utils/contentfulRichTextRenderer'

import contentfulComponentMixin from '@/components/common/contentful/mixins/contentfulComponentMixin'

import Accordion from '@/components/common/Accordion.vue'
import AppVideo from '@/components/common/AppVideo.vue'
import BlockBenefits from '@/components/common/BlockBenefits.vue'
import ContentTable from '@/components/common/ContentTable.vue'
import HTMLRenderer from '@/components/common/HTMLRenderer.vue'
import HubspotContactForm from '@/components/common/HubspotContactForm.vue'
import ImageCarousel from '@/components/common/ImageCarousel.vue'
import ParticleLink from '@/components/common/ParticleLink.vue'
import Renderer from '@/components/common/Renderer.vue'
import TextSection from '@/components/common/TextSection.vue'

import VueScriptComponent from '@/components/vendor/vue-script-component/VueScriptComponent.vue'

export default {
  components: {
    ContentfulRichTextRenderer,
    Renderer,
  },
  mixins: [contentfulComponentMixin],
  props: {
    richTextData: {
      type: [String, Object],
      default: '',
    },
    addWrapClass: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    isStringProvided() {
      return typeof this.richTextData === 'string'
    },
    tagWrappers() {
      return {
        [MARKS.BOLD]: (children) => `<b>${children}</b>`,
        [MARKS.ITALIC]: (children) => `<em>${children}</em>`,
        [MARKS.UNDERLINE]: (children) => `<u>${children}</u>`,
        [MARKS.CODE]: (children) => `<code>${children}</code>`,
        [MARKS.SUPERSCRIPT]: (children) => `<sup>${children}</sup>`,
        [MARKS.SUBSCRIPT]: (children) => `<sub>${children}</sub>`,
      }
    },
    renderNodes() {
      const getFields = (node) => node.data.target?.fields
      const typeHandlers = {
        textWrapperRenderer: (node, key, next, tag = 'p') => {
          const isTextContent = node.content.every((el) => el.nodeType === 'text')
          if (isTextContent) {
            const sum = node.content
              .map((el) => {
                const templates = next([el], key, next)
                return templates
                  .map((el) => (typeof el === 'string' ? el : el?.type?.template))
                  .join('')
              })
              .join('')
            return h(HTMLRenderer, { tmpl: `<${tag}>${sum}</${tag}>` })
          }
          const sum = node.content.map((el) => {
            const isTextNode = el.nodeType === 'text'
            const textTemplate = isTextNode ? typeHandlers.text(el) : ''
            const template = isTextNode
              ? h(HTMLRenderer, { tmpl: textTemplate })
              : next([el], key, next)
            return template
          })
          return h(tag, { key }, sum.flat())
        },
        text: (node) => {
          const value = this.replaceAllChars(node.value)
          if (!node.marks.length) return value
          const marksReversed = [...node.marks].reverse()
          return marksReversed.reduce(
            (aggregate, mark) => this.tagWrappers[mark.type](aggregate),
            value,
          )
        },
        image: (node) => {
          return h({
            template: `<img class="embedded-image" src='${getFields(node).file.url}'/>`,
          })
        },
        blockHubSpotSnippet: (node) => {
          const snippet = getFields(node).snippet
          const isContactForm = snippet.includes('id="contact-form"')
          if (snippet)
            return isContactForm
              ? h(HubspotContactForm, {
                  script: snippet,
                })
              : h(VueScriptComponent, {
                  script: snippet,
                  className: 'block-hubspot-snippet',
                })
        },
        blockBenefits: (node, key) => {
          const fields = getFields(node)
          const isNumbersBarView = fields.style === 'Numbers-bar'
          const items = fields.benefitItems
            ?.map((item) => {
              if (!item?.fields) return null
              return {
                image: this.getImageUrl(item.fields.icon) || '',
                link: item.fields.link || '',
                title: item.fields.title || '',
                subtitle: item.fields.subtitle || '',
              }
            })
            .filter((el) => el)
          return h(BlockBenefits, { key, isNumbersBarView, items })
        },
        blockBanner: (node) => {
          const fields = getFields(node)
          const bannerEntry = {
            fullWidth: fields.fullWidth,
            link: fields.link || '',
            image: this.getImageUrl(fields.image) || '',
          }
          const targetAttribute = `target="${
            bannerEntry.link.includes('http') ? '_blank' : '_self'
          }"`
          const hrefAttribute = bannerEntry.link ? `href="${bannerEntry.link}"` : ''
          return h({
            template: `<div class="block-banner${
              bannerEntry.fullWidth ? ' block-banner--full-width' : ''
            }">
              <a class="block-banner__image-link" ${targetAttribute} ${hrefAttribute}>
                <img class="block-banner__image" src="${bannerEntry.image}" />
              </a>
            </div>`,
          })
        },
        blockMegaCarousel: (node, key) => {
          const fields = getFields(node)
          const slides = fields?.slides?.map((slideEntry) => ({
            image: this.getImageUrl(slideEntry.fields.image),
            label: slideEntry.fields.paragraphRich,
          }))
          return h(ImageCarousel, { key, carouselSlides: slides })
        },
        blockTextSection: (node, key) => {
          const fields = getFields(node)
          const hasMedia = !!fields.media
          const isVideoMedia = hasMedia && fields.media?.fields?.file?.contentType === 'video/mp4'
          const media = hasMedia
            ? {
                isVideoMedia,
                preview: fields.videoPreview?.fields?.file?.url,
                url: isVideoMedia
                  ? fields.media?.fields?.file?.url
                  : this.getImageUrl(fields.media),
              }
            : null
          return h(TextSection, {
            key,
            mainContent: fields.paragraphRich,
            mediaAlignment: fields.mediaAlignment,
            mediaFloating: fields.mediaFloating,
            paragraphMediaProportion: fields.paragraphMediaProportion,
            marginTop: fields.marginTop,
            container: fields.container,
            media,
          })
        },
        blockClientTestimonialsItem: (node) => {
          const testimonial = getFields(node)
          if (testimonial) {
            h({
              template: `<div class="client-testimonial">
                <p class="client-testimonial__quote">"${testimonial.quote}"</p>
                <p class="client-testimonial__author"> — ${testimonial.author}</p>
              </div>`,
            })
          }
        },
        blockProcessDiagram: (node) => {
          const fields = getFields(node)
          const processStages = fields.processStages?.map((stage) => {
            return `<div class="process-diagram__stage">
                      <div class="process-diagram__stage-wrapper">
                        <img src="${this.getImageUrl(
                          stage.fields?.image,
                        )}" class="process-diagram__stage-img"/>
                        <div class="process-diagram__stage-textwrapper">
                          <h4 class="process-diagram__stage-title">${stage.fields?.title}</h4>
                          <p class="process-diagram__stage-desc">${stage.fields?.description}</p>
                        </div>
                      </div>
                    </div>`
          })
          return h({
            template: `<div class="process-diagram">
                ${processStages.join('')}
              </div>`,
          })
        },
        page: (node) => {
          return h({
            template: `<a
                key='${getFields(node).slug}'
                href='${this.getPageBySlug(getFields(node).slug)?.link}'
              >${node.content[0].value}</a>`,
          })
        },
        resource: (node) => {
          const item = getFields(node)
          if (!item) return null
          let { wrappedSlug } = this.getSlugsForEducationalResource(
            item.resourceGroup.fields,
            item.title,
          )
          const isBlog = item?.resourceGroup?.fields?.slug === 'blogs'
          if (isBlog) wrappedSlug = !item.link ? `blogs/${item.slug}` : item.link
          const title = node.content[0]?.value || item.title
          return h({ template: `<a href='/${wrappedSlug}'>${title}</a>` })
        },
        blockAccordion: (node, key) => {
          return h(Accordion, {
            key,
            header: getFields(node).title,
            main: getFields(node).paragraphRich,
          })
        },
        pdf: (node) => {
          return h({
            template: `<a href="${getFields(node).file.url}" target="_blank">${
              node.content[0] ? node.content[0].value : getFields(node).title
            }</a>`,
          })
        },
        video: (node, key) => {
          return h(AppVideo, {
            key,
            preview: getFields(node).preview,
            url: getFields(node).file.url,
          })
        },
        link: (node) => {
          const domains = ['https://bioivt.com', 'https://www.bioivt.com']
          const target = domains.some((domain) => node.data.uri.startsWith(domain))
            ? '_self'
            : '_blank'
          return h({
            template: `<a href='${node.data.uri}' target="${target}">${node.content[0]?.value}</a>`,
          })
        },
        particleLink: (node) => {
          return h(ParticleLink, { entryFields: getFields(node) })
        },
        blockTable: (node, key) => {
          const entryFields = getFields(node)
          const extractSizes = (sizes) => {
            return sizes?.length
              ? sizes.reduce(
                  (sum, curr) => {
                    const [propString, sizeString] = curr.split('=')
                    const prop = parseInt(propString)
                    const size = parseInt(sizeString)
                    const isValid =
                      typeof prop === 'number' &&
                      !isNaN(prop) &&
                      typeof size === 'number' &&
                      !isNaN(size)
                    if (isValid) {
                      sum[prop] = size
                      sum.total += +size
                    }
                    return sum
                  },
                  { total: 0 },
                )
              : {}
          }
          const colWidths = extractSizes(entryFields?.columnWidth)
          const rowHeights = extractSizes(entryFields?.rowHeight)
          const tableEntry = entryFields.table?.content?.find((el) => el.nodeType === 'table')
          const transformedTabelRows = tableEntry.content.map((tableRow, rowIdx) => {
            const rowHeight = rowHeights[rowIdx + 1]
            return {
              rowHeight: rowHeight ? rowHeight : null,
              rowContent: tableRow.content.map((cellEntry, cellIdx) => {
                const colWidth = colWidths[cellIdx + 1]
                return {
                  cellTag: cellEntry.nodeType === 'table-header-cell' ? 'th' : 'td',
                  cellWidth: colWidth && rowIdx === 0 ? colWidth : null,
                  cellContent: cellEntry,
                }
              }),
            }
          })
          return h(ContentTable, {
            key,
            variant: entryFields.variant,
            showBorders:
              typeof entryFields.showBorders === 'boolean' ? entryFields.showBorders : true,
            totalColWidth: colWidths.total,
            tabelRows: transformedTabelRows,
          })
        },
      }

      return {
        [INLINES.ASSET_HYPERLINK]: (node, key, next) => {
          if (
            node.data.target &&
            node.data.target.fields.file.contentType.includes('application/pdf')
          ) {
            return typeHandlers.pdf(node, key, next)
          } else if (
            node.data.target &&
            node.data.target.fields.file.contentType.includes('image')
          ) {
            return typeHandlers.image(node, key, next)
          }
        },
        [INLINES.EMBEDDED_ENTRY]: (node, key, next) => {
          const typeId = this.getContentTypeId(node.data.target)
          if (typeHandlers[typeId]) return typeHandlers[typeId](node, key, next)
        },
        [BLOCKS.EMBEDDED_ENTRY]: (node, key, next) => {
          const typeId = this.getContentTypeId(node.data.target)
          if (typeHandlers[typeId]) return typeHandlers[typeId](node, key, next)
        },
        [BLOCKS.EMBEDDED_ASSET]: (node, key, next) => {
          const isBelongsToType = (type) =>
            node.data.target?.fields?.file?.contentType?.includes(type)
          const isImage = isBelongsToType('image')
          const isPdf = isBelongsToType('application/pdf')
          const isVideo = isBelongsToType('video')
          if (isImage) {
            return typeHandlers.image(node, key, next)
          } else if (isPdf) {
            return typeHandlers.pdf(node, key, next)
          } else if (isVideo) {
            return typeHandlers.video(node, key, next)
          }
        },
        [BLOCKS.PARAGRAPH]: (node, key, next) =>
          typeHandlers.textWrapperRenderer(node, key, next, 'p'),
        [BLOCKS.HEADING_1]: (node, key, next) =>
          typeHandlers.textWrapperRenderer(node, key, next, 'h1'),
        [BLOCKS.HEADING_2]: (node, key, next) =>
          typeHandlers.textWrapperRenderer(node, key, next, 'h2'),
        [BLOCKS.HEADING_3]: (node, key, next) =>
          typeHandlers.textWrapperRenderer(node, key, next, 'h3'),
        [BLOCKS.HEADING_4]: (node, key, next) =>
          typeHandlers.textWrapperRenderer(node, key, next, 'h4'),
        [BLOCKS.HEADING_5]: (node, key, next) =>
          typeHandlers.textWrapperRenderer(node, key, next, 'h5'),
        [BLOCKS.HEADING_6]: (node, key, next) =>
          typeHandlers.textWrapperRenderer(node, key, next, 'h6'),
        [INLINES.ENTRY_HYPERLINK]: (node, key, next) => {
          const typeId = this.getContentTypeId(node.data.target)
          if (typeHandlers[typeId]) return typeHandlers[typeId](node, key, next)
        },
        [INLINES.HYPERLINK]: (node, key, next) => typeHandlers.link(node, key, next),
        text: (node, key, next) => typeHandlers.text(node, key, next),
      }
    },
  },
  methods: {
    replaceAllChars(str) {
      const toReplace = {
        ' ': ' ',
        '＞': '>',
        '<BR>': '<br/>',
        '<br>': '<br/>',
        ' < ': '&lt;',
      }
      const regExp = new RegExp(Object.keys(toReplace).join('|'), 'gi')
      return str ? str.replace(regExp, (matched) => toReplace[matched]) : ''
    },
  },
}
</script>
