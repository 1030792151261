import * as Sentry from '@sentry/vue'
import _ from 'lodash'

import app from '@/main'
import settings from '@/settings'

window.onerror = function (message, source, lineno, colno, error) {
  if (error && error._ignore === true) {
    console.debug(error)
    return
  }

  ErrService.sendError(error)
  console.error(error)
}

window.addEventListener('unhandledrejection', function (event) {
  if (event.origin && event.origin !== document.location.origin) return

  event.preventDefault()
  // @TODO cases when event has no reason
  const error = event.reason
  if (!error) {
    console.debug(event)
  } else {
    if (
      // vue router navigation duplicated
      // @TODO investiage - redirected by nav guard triggers router error

      error.name?.includes('NavigationDuplicated') ||
      error.message?.includes('redundant navigation to current location') ||
      error.message?.includes('Redirected when going from') ||
      error._ignore === true
    ) {
      console.debug(error)
      return
    }
    ErrService.handleErrorNotifications(error)
    ErrService.sendError(error)
    console.error(error)
  }
})

const ErrService = {
  isSentryEnabled: !!(
    settings.services.sentry.enabled &&
    settings.services.sentry.dsn &&
    settings.services.sentry.env
  ),
  initSentry(app, router) {
    if (this.isSentryEnabled) {
      Sentry.init({
        app,
        dsn: settings.services.sentry.dsn,
        integrations: [
          Sentry.browserTracingIntegration({
            router,
          }),
          Sentry.globalHandlersIntegration({
            onerror: false,
            onunhandledrejection: false,
          }),
          Sentry.replayIntegration(),
        ],
        environment: settings.services.sentry.env,
        logErrors: false,
        replaysSessionSampleRate: 0.01,
        replaysOnErrorSampleRate: 0.1,
        tracesSampleRate: 0.01,
        normalizeDepth: 10,
        ignoreErrors: [
          'Failed to fetch dynamically imported module',
          'Unable to preload CSS',
          "Cannot read property 'parentNode' of null",
          "Cannot read properties of undefined (reading 'fireChangeEvent')",
          "Cannot read properties of undefined (reading 'fireReadyEvent')",
          "Cannot read properties of undefined (reading 'setCurrentPosition')",
          "Cannot read properties of undefined (reading 'setDefaultPosition')",
          "Cannot read properties of undefined (reading 'setMaxSize')",
          "Cannot read properties of undefined (reading 'setScreenSize')",
          "Cannot read properties of undefined (reading 'fireEvent')",
          "Cannot read properties of undefined (reading 'audioVolumeChange')",
        ],
        beforeSend(event, hint) {
          if (hint.originalException === 'Timeout') return null
          return event
        },
      })
    }
  },
  sendError(error) {
    if (this.isSentryEnabled) {
      if (error?.options && !error?.options?.sendError) return
      return Sentry.captureException(error)
    }
  },
  sendMessage(msg, scope = 'error') {
    if (this.isSentryEnabled) {
      return Sentry.captureMessage(msg, scope)
    }
  },
  configureClient(value) {
    if (this.isSentryEnabled) {
      const newValue = value ? { uid: value } : null
      Sentry.getCurrentScope().setUser(newValue)
    }
  },
  throwIgnore(error) {
    if (error) error._ignore = true
    throw error
  },
  handleErrorNotifications: _.debounce(function (error) {
    if (!error?.options || !error?.options?.showNotification) return
    if (error?.options?.customMessage) {
      // toasted plugin docs on how to use actions as array
      app.config.globalProperties.$toasted.show(
        error.options.customMessage,
        error.options.customActions,
      )
    } else {
      switch (error.options.errorLevel) {
        case 'network':
          app.config.globalProperties.$toasted.show(
            '<span>Oops, network request error. Please try again or <a href="/about/contact-us">contact us</a></span>',
            {
              duration: 6000,
            },
          )
          break
        case 'boot':
          setTimeout(() => {
            app.config.globalProperties.$toasted.show(
              'The site has encountered an error. Please try again.',
              {
                action: {
                  text: 'Reload',
                  onClick: () => {
                    window.location.reload()
                  },
                },
              },
            )
          }, 500)
          break
        case 'component':
        default:
          break
      }
    }
  }, 500),
  decorateError(moduleName, error, options = {}) {
    error.options = {
      errorLevel: '',
      customMessage: '',
      customActions: [],
      showNotification: true,
      sendError: true,
      ...options,
    }

    switch (options.errorLevel) {
      case 'network':
        error.name = `[NETWORK] - [${moduleName?.toUpperCase() || 'INTEGRATIONS'}]`
        error.name += error.config
          ? ` - ${error.config.method?.toUpperCase()} on ${error.config.url?.replace(
              settings.services.api.baseUrl,
              '',
            )}`
          : ''
        break
      case 'boot':
        error.name = `[BOOT] - [${moduleName?.toUpperCase() || 'APP'}]`
        break
      case 'component':
        error.name = `[COMPONENT] - [${moduleName?.toUpperCase() || '...'}]`
        break
    }

    return error
  },
  init(app, router) {
    app.config.errorHandler = (error, vm) => {
      // error sending is managed by the integration
      if (!error?.options?.errorLevel)
        ErrService.decorateError(vm?.$options?.name, error, {
          errorLevel: 'component',
        })
      ErrService.handleErrorNotifications(error)
      console.error(error)
    }
    this.initSentry(app, router)
  },
}

export default ErrService
