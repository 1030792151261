import api from '@/lib/api'
import dotNotationToObject from '@/lib/utils/dotNotationToObject'

import AnalyticsService from '@/services/AnalyticsService'

import { getProductCrumbs } from '@/components/common/contentful/helpers/contentfulHelpers'

import { transformDeliveryContact } from '@/components/cart/helpers/transformHelpers'

import app from '@/main'
import settings from '@/settings'

const defaultState = {
  email: '',
  accountDetails: {},
  shippingAddresses: [],
  billingAddresses: [],
  inventorySavedSearches: [],
  deliveryContacts: [],
  supportContacts: [],
  isSupportContactsLoaded: false,
  customerServiceContacts: [
    {
      region: 'North America, Asia Pacific, Middle East & Africa',
      phone: '+1 516-483-1196',
      email: 'customerservice@bioivt.com ',
    },
    {
      region: 'Europe',
      phone: '+44 (0) 1444 707333',
      email: 'cseurope@bioivt.com',
    },
  ],
  recentlyViewedProducts: [],
  recentlyOrderedProducts: [],
  isRecentProductsLoaded: false,
  countries: [],
  regions: {
    US: [],
    CA: [],
  },
}

const getDefaults = () => JSON.parse(JSON.stringify(defaultState))

const state = { lastRequest: null, ...getDefaults() }

const mutations = {
  SET_AUTH_DATA(state, authData) {
    state = Object.assign(state, getDefaults(), authData)
  },
  RESET_AUTH_DATA(state) {
    Object.assign(state, getDefaults())
  },
  SET_LAST_REQUEST(state, date) {
    state.lastRequest = date
  },
  SET_SHIPPING_ADDRESSES(state, shippingAddresses) {
    state.shippingAddresses = shippingAddresses
  },
  SET_BILLING_ADDRESSES(state, billingAddresses) {
    state.billingAddresses = billingAddresses
  },
  SET_DELIVERY_CONTACTS(state, deliveryContacts) {
    state.deliveryContacts = deliveryContacts
  },
  SET_SUPPORT_CONTACTS(state, { supportContacts, isSupportContactsLoaded }) {
    state.supportContacts = supportContacts
    state.isSupportContactsLoaded = isSupportContactsLoaded
  },
  SET_RECENT_PRODUCTS(state, { recentlyViewedProducts, recentlyOrderedProducts }) {
    state.recentlyViewedProducts = recentlyViewedProducts
    state.recentlyOrderedProducts = recentlyOrderedProducts
    state.isRecentProductsLoaded = true
  },
  SET_IS_RECENT_PRODUCTS_LOADED(state, isRecentProductsLoaded) {
    state.isRecentProductsLoaded = isRecentProductsLoaded
  },
  SET_COUNTRIES_AND_REGIONS(state, { countries, regions }) {
    state.countries = countries
    state.regions = regions
  },
}

const actions = {
  resetAuthData({ commit }) {
    commit('RESET_AUTH_DATA')
  },
  setAuthData({ commit }, authData) {
    commit('SET_AUTH_DATA', authData)
  },
  setLastRequest({ commit }, date) {
    commit('SET_LAST_REQUEST', date)
  },
  async login({ dispatch, rootGetters }, data) {
    await api.auth.login(data.username, data.password)
    await dispatch('resetStateOnLoginChange')
    await dispatch('updateUserData', data.username)
    await dispatch('cart/getCart', true, { root: true })
    if (rootGetters['cart/productsQty']) dispatch('cartFlow/setStep', 'view', { root: true })
    await dispatch('search/setCompanyCatalogAvailability', null, { root: true })
    await dispatch('search/setStandardCatalogAvailability', null, { root: true })
  },
  async logout({ dispatch, commit, rootState }) {
    if (rootState.asm.isAsmSession) {
      await dispatch('asm/stopEmulation', null, { root: true })
    } else {
      try {
        dispatch('app/toggleLoader', true, { root: true })
        await api.auth.logout()
      } finally {
        commit('RESET_AUTH_DATA')
        // logout endpoint terminates api session
        commit('SET_LAST_REQUEST', null)
        dispatch('resetStateOnLoginChange')
        AnalyticsService.sendUserLogoutAnalytics()
        dispatch('app/toggleLoader', false, { root: true })
      }
    }
  },
  register(context, data) {
    return api.auth.register(dotNotationToObject(data))
  },
  async refreshAccountDetails({ commit, state, dispatch }, userId) {
    const accountDetails = await api.user.accountDetails(userId)
    await commit('SET_AUTH_DATA', {
      email: accountDetails.uid,
      shippingAddresses: [...state.shippingAddresses],
      billingAddresses: [...state.billingAddresses],
      deliveryContacts: [...state.deliveryContacts],
      accountDetails,
    })
    const { itsSuperAdmin, itsRole } = accountDetails
    await dispatch('roles/setRoles', { itsSuperAdmin, itsRole }, { root: true })
    await dispatch('account-portal/initWidgets', null, { root: true })
  },
  getShippingAddresses({ commit }, userId) {
    return api.user.getShippingAddresses(userId).then((response) => {
      const addresses = response.data?.length ? response.data.filter((el) => el) : []
      commit('SET_SHIPPING_ADDRESSES', addresses)
    })
  },
  getBillingAddresses({ commit }, userId) {
    return api.user.getBillingAddresses(userId).then((response) => {
      const addresses = response.data?.length ? response.data.filter((el) => el) : []
      commit('SET_BILLING_ADDRESSES', addresses)
    })
  },
  submitAddressRequest(context, addressData) {
    return api.user.submitAddressRequest(addressData)
  },
  async setDeliveryContacts({ commit }, userId) {
    const { data } = await api.cart.getDeliveryContacts(userId)
    await commit('SET_DELIVERY_CONTACTS', data.map(transformDeliveryContact))
  },
  async updateUserData({ dispatch }, userId) {
    await dispatch('refreshAccountDetails', userId)
    await Promise.all([
      dispatch('getShippingAddresses', userId),
      dispatch('getBillingAddresses', userId),
      dispatch('reserve-orders/setIsReserveOrdersExist', null, {
        root: true,
      }),
    ])
  },
  updateSomeUserInfoFields({ dispatch }, data) {
    return api.user
      .updateSomeUserInfoFields(data, data.userId)
      .then(() => dispatch('refreshAccountDetails'))
  },

  checkSession({ getters }) {
    if (!getters.isUserLoggedIn) {
      if (!getters.isSessionActive) {
        return api.auth.initSession()
      } else {
        return Promise.resolve()
      }
    } else {
      return Promise.resolve()
    }
  },
  resetApiSession({ commit }) {
    commit('SET_LAST_REQUEST', null)
  },
  async initSessionState({ rootState, dispatch, rootGetters }) {
    // last session value
    if (rootState.punchout.isPunchoutSession) {
      // cookie value
      if (!rootState.punchout.punchoutDunsId) {
        return dispatch('punchout/punchoutLogout', null, { root: true })
      } else {
        await dispatch('updateUserData', 'current')
        await dispatch('cart/getCart', true, { root: true })
        if (rootGetters['cart/productsQty']) dispatch('cartFlow/setStep', 'view', { root: true })
        return
      }
    }

    // last session value
    if (rootState.asm.isAsmSession) {
      return dispatch('asm/asmLogout', null, { root: true })
    }

    if (rootGetters['session/isUserLoggedIn']) await dispatch('updateUserData')
    await dispatch('cart/getCart', true, { root: true })
    if (rootGetters['cart/productsQty']) dispatch('cartFlow/setStep', 'view', { root: true })
  },
  async createShortUrl(context, url) {
    const { data } = await api.user.createShortUrl(url)
    const hash = data.hash
    return hash
  },
  async resolveShortUrl(context, hash) {
    const { data } = await api.user.resolveShortUrl(hash)
    const url = data.url ? decodeURIComponent(data.url) : ''
    return url
  },
  async setSupportContacts({ state, commit }) {
    if (state.isSupportContactsLoaded) return
    const { data } = await api.user.getSupportInfo()
    const supportContacts = []
    const generalSupportContact = {
      name: data.generalSupportName,
      phone: data.generalSupportPhone,
      email: data.generalSupportEmail,
    }
    const isGeneralSupportContactAvailable = Object.values(generalSupportContact).some((el) => el)
    if (isGeneralSupportContactAvailable) supportContacts.push(generalSupportContact)
    commit('SET_SUPPORT_CONTACTS', { supportContacts, isSupportContactsLoaded: true })
  },
  async resetStateOnLoginChange({ commit, dispatch }) {
    await Promise.all([
      dispatch('reserve-orders/resetState', null, { root: true }),
      dispatch('account-portal/resetAllAccountPortal', null, { root: true }),
      dispatch('cart/clearCart', null, { root: true }),
      commit('product/RESET_STATE', null, { root: true }),
      commit('inventory/RESET_STATE', null, { root: true }),
      commit('search/RESET_STATE', null, { root: true }),

      commit('order-template/RESET_STATE', null, { root: true }),
      commit('inventory-thresholds/RESET_STATE', null, { root: true }),
      commit('inventory-log/RESET_STATE', null, { root: true }),
      commit('current-inventory/RESET_STATE', null, { root: true }),
      commit('company/RESET_STATE', null, { root: true }),
      commit('roles/RESET_ROLES', null, { root: true }),
    ])
  },
  async addRecentlyViewedProduct({ commit }, slug) {
    await api.user.addRecentProduct(slug)
    await commit('SET_IS_RECENT_PRODUCTS_LOADED', false)
  },
  async getRecentProducts({ commit }) {
    const response = await api.user.getRecentProducts()
    const { recentlyViewedProducts, recentlyOrderedProducts } = response.data
    const combined = [...recentlyViewedProducts, ...recentlyOrderedProducts]
    if (!combined.length)
      return commit('SET_RECENT_PRODUCTS', {
        recentlyViewedProducts: [],
        recentlyOrderedProducts: [],
      })

    const { items } = await app.config.globalProperties.$contentful.client.getEntries({
      skip: 0,
      limit: 20,
      include: 4,
      content_type: 'product',
      select:
        'sys.id,fields.slug,fields.title,fields.images,fields.types,fields.subtypes,fields.subtypesLevel2',
      'fields.slug[in]': combined.join(','),
    })
    const transformProduct = (slug) => {
      const correspondingEntry = items.find((entry) => entry.fields?.slug === slug)
      if (!correspondingEntry) return
      const crumbs = getProductCrumbs(correspondingEntry)
      const deepestCrumb = crumbs[crumbs.length - 1]
      return {
        link: `/${correspondingEntry.fields.slug}`,
        title: correspondingEntry.fields.title,
        categoryTitle: deepestCrumb?.name || '',
        categoryLink: deepestCrumb?.link || '',
        image:
          correspondingEntry.fields.images?.[0]?.fields?.file?.url ||
          app.config.globalProperties.$contentful.service.getProductDefaultImage(),
      }
    }
    await commit('SET_RECENT_PRODUCTS', {
      recentlyViewedProducts: recentlyViewedProducts.map(transformProduct).filter((el) => el),
      recentlyOrderedProducts: recentlyOrderedProducts.map(transformProduct).filter((el) => el),
    })
  },
  async getCountriesAndRegions({ commit }) {
    const countriesResponse = await api.user.getCountries()
    const sortCountries = (countries) => {
      const fixedOrder = ['US', 'CA']
      const prioritized = countries.filter((country) => fixedOrder.includes(country.isocode))
      const remaining = countries.filter((country) => !fixedOrder.includes(country.isocode))
      prioritized.sort((a, b) => fixedOrder.indexOf(a.isocode) - fixedOrder.indexOf(b.isocode))
      return [...prioritized, ...remaining]
    }
    const [usRegionsResponse, caRegionsResponse] = await Promise.all([
      api.user.getRegions('US'),
      api.user.getRegions('CA'),
    ])
    await commit('SET_COUNTRIES_AND_REGIONS', {
      countries: sortCountries(countriesResponse.data) || [],
      regions: {
        US: usRegionsResponse.data || [],
        CA: caRegionsResponse.data || [],
      },
    })
  },
}

const getters = {
  user: (state) => {
    const user = state.accountDetails?.uid ? { ...state.accountDetails } : null
    return {
      sageX3Id: user ? user.sageX3Id : '',
      uid: user ? user.uid : '',
      email: user ? user.email : '',
      salutation: user ? user.salutation : '',
      firstName: user ? user.firstName : '',
      lastName: user ? user.lastName : '',
      initials: user ? user.firstName.charAt(0) + user.lastName.charAt(0) : '',
      fullName: user ? user.firstName + ' ' + user.lastName : '',
      shippingDetails: user ? user.shippingAddress : {},
      contactInformation: user?.contactAddress
        ? {
            ...user.contactAddress,
            firstName: user.contactAddress.firstName || user.firstName,
            lastName: user.contactAddress.lastName || user.lastName,
            companyName: user.contactAddress.companyName || user.unit?.name,
          }
        : {},
      companyOrdersShipTo: user ? user.activeShipTo : [],
      isCompanyOrdersAvailable: user ? user.showAllSelectedShipTo : false,
      itsRole: user ? user.itsRole : '',
      departments: user?.departments ? user.departments : [],
      settings: user
        ? {
            dateFormat: user.dateFormat,
            timeFormat: user.timeFormat,
            currency: user.currency,
          }
        : {},
      unit: user && user.unit ? user.unit : {},
      isUserProspect: !!user?.unit?.prospect,
      myAccountWidgets: user?.myAccountWidgets || [],
    }
  },
  isUserLoggedIn: (state) => !!state.accountDetails?.uid,
  // asm and regular sessions are controlled by user actions, punchout is sessiontype cookie dependent
  isSessionActive: (state, getters, rootState) =>
    (state.lastRequest &&
      Math.ceil((new Date() - new Date(state.lastRequest)) / 1000) <=
        settings.services.api.sessionTtl) ||
    rootState.punchout.isPunchoutSession,
  isInventoryTrackingVisible: (state, getters, rootState, rootGetters) => {
    return (
      rootGetters['roles/isUserSuperAdmin'] ||
      (!!getters.user?.unit?.itsApplication && rootGetters['roles/hasAtLeastOneRole'])
    )
  },
}

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true,
}
