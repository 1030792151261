import api from '@/lib/api'
import sorting from '@/lib/utils/sorting'

const _defaultState = {
  companies: [],
  isCompaniesLoaded: false,
}
const getDefaultState = () => JSON.parse(JSON.stringify(_defaultState))
const state = getDefaultState()

const mutations = {
  SET_COMPANY(state, companies) {
    state.companies = companies
    state.isCompaniesLoaded = true
  },
  RESET_STATE(state) {
    Object.assign(state, getDefaultState())
  },
}

const actions = {
  async getCompany({ commit, state }) {
    if (state.isCompaniesLoaded) return
    const { data } = await api.user.getCompany()
    const companies =
      data.companies?.map((company) => ({
        ...company,
        name: `${company.name} (${company.uid})`,
      })) || []
    const sortedCompanies = sorting.sortObjectsListByAlphaAsc(companies, 'name')
    commit('SET_COMPANY', sortedCompanies)
  },
}

export default {
  state,
  mutations,
  actions,
  namespaced: true,
}
