import api from '@/lib/api'

const _defaultState = {
  submittedCart: null,
  quotesForWidget: [],
  totalSubmittedQuotes: 0,
  totalReadyQuotes: 0,
}
const getDefaultState = () => JSON.parse(JSON.stringify(_defaultState))
const state = getDefaultState()

const mutations = {
  SET_SUBMITTED_CART(state, submittedCart) {
    state.submittedCart = submittedCart
  },
  SET_QUOTES_FOR_WIDGET(state, { quotes, totalSubmittedQuotes, totalReadyQuotes }) {
    state.quotesForWidget = quotes
    state.totalSubmittedQuotes = totalSubmittedQuotes
    state.totalReadyQuotes = totalReadyQuotes
  },
  RESET_STATE(state) {
    Object.assign(state, getDefaultState())
  },
}

const actions = {
  async getQuoteDetails(context, quoteCode) {
    const { data: quoteDetailsData } = await api.quotes.getQuoteDetails(quoteCode)
    return quoteDetailsData
  },
  async getQuotesForWidget({ commit }) {
    const requestParams = {
      states: 'SUBMITTED,READY',
      sort: 'byDateDESC',
      pageSize: 5,
      statisticsByStatuses: true,
      showCompanyQuotes: true,
    }
    const response = await api.quotes.getQuotes('', requestParams)
    const { quotes = [], statisticsByStatuses = [] } = response.data
    const totalSubmittedQuotes =
      statisticsByStatuses.find((el) => el.name === 'Submitted')?.count || 0
    const totalReadyQuotes =
      statisticsByStatuses.find((el) => el.name === 'Ready to Order')?.count || 0
    commit('SET_QUOTES_FOR_WIDGET', { quotes, totalSubmittedQuotes, totalReadyQuotes })
  },
}

export default {
  state,
  mutations,
  actions,
  namespaced: true,
}
