import _ from 'lodash'

import ApiBase from '@/lib/api/v1/api-base'

class Quotes extends ApiBase {
  constructor(transport) {
    super(transport)
    this.getQuotes = this.getQuotes.bind(this)
    this.payQuoteWithPoNumber = this.payQuoteWithPoNumber.bind(this)
    this.getPaymentToken = this.getPaymentToken.bind(this)
  }

  /**
   * Get a list of quotes.
   * @param userId
   * @param params
   */
  getQuotes(userId, params) {
    return this._withDecoratedPromise(() => {
      params = _.pickBy(params)
      params = new URLSearchParams(params).toString()
      return this.transport.get(`/rest/api/v1/users/${this._getUserId(userId)}/quotes?${params}`)
    })
  }

  getQuoteDetails(quoteId, userId) {
    return this._withDecoratedPromise(() => {
      return this.transport.get(
        `/rest/api/v1/users/${this._getUserId(userId)}/quotes/${encodeURIComponent(quoteId)}`,
      )
    })
  }

  /**
   * Pay quote with purchase order.
   * @param userId
   * @param quoteNumber
   * @param payload
   */
  payQuoteWithPoNumber({ userId, quoteNumber, payload }) {
    const headers = {
      'Content-Type': 'multipart/form-data',
    }
    return this._withDecoratedPromise(() => {
      return this.transport.post(
        `/rest/api/v1/users/${encodeURIComponent(userId)}/quotes/${encodeURIComponent(
          quoteNumber,
        )}/pay`,
        payload,
        headers,
      )
    })
  }

  getQuoteDocument(url) {
    return this._withDecoratedPromise(() => {
      const requestOptions = {
        responseType: 'arraybuffer',
      }
      return this.transport.get(url, null, null, requestOptions)
    })
  }

  getPaymentToken({ quoteNumber, config }) {
    return this._withDecoratedPromise(() => {
      return this.transport.post(
        `/rest/api/v1/users/${this._getUserId()}/payment/quote/${encodeURIComponent(
          quoteNumber,
        )}/form-token`,
        config,
      )
    })
  }

  payQuoteWithCreditCard({ quoteNumber, data }) {
    return this._withDecoratedPromise(() => {
      return this.transport.post(
        `/rest/api/v1/users/${this._getUserId()}/payment/quote/${encodeURIComponent(
          quoteNumber,
        )}/callback`,
        data,
      )
    })
  }
}

export default Quotes
