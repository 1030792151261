<template>
  <div :class="{ 'main-menu--border-bottom': isPunchout }" class="main-menu">
    <div class="main-menu__inner">
      <div class="container main-menu__inner-wrap">
        <ul ref="mainMenu" class="main-menu__items-wrapper">
          <li
            v-for="(item, n) in getMenuItems"
            :key="item.title"
            ref="menuItem"
            :class="{ 'show-submenu': activeItemIdx === n }"
            class="main-menu__item"
            @mouseover="setActiveItemOnHover(n)"
            @mouseleave="setActiveItemOnHover(null)"
          >
            <a
              class="main-menu__link"
              :href="item.link"
              @mouseover="onMenuLinkHover"
              @click.prevent.self="onItemTitleClick(item.link)"
              v-html="item.title"
            />
            <span
              v-if="item.subitems"
              class="main-menu__link-arrow"
              @click.self="setActiveItem(n)"
            />
            <sub-menu
              v-if="!disableSubmenu && item.hasSubmenu && activeItemIdx === n && isInitialized"
              :menuItem="item"
              :style="{
                left: `-${submenuTopPosition}px`,
                width: `${windowWidth}px`,
              }"
              @requestClose="onSubmenuClose"
            />
          </li>
        </ul>
        <div v-if="isRightMenuVisible" class="main-menu__right-list">
          <div class="main-menu__item">
            <span class="main-menu__link">
              <router-link id="header-contact-us-button" to="/about/contact-us">{{
                $t('header.contacts.link')
              }}</router-link>
            </span>
          </div>
          <app-button
            v-if="isDesktop"
            id="header-view-quote-button"
            class="main-menu__item main-menu__quote-btn"
            type="button"
            :text="requestQuoteCaption"
            @clicked="$router.push('/cart')"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { nextTick } from 'vue'
import { mapGetters, mapState } from 'vuex'

import menuUnderlineMixin from '@/components/common/AppHeader/mixins/menuUnderlineMixin'
import contentfulAutowireGlobalMixin from '@/components/common/contentful/mixins/contentfulAutowireGlobalMixin'
import contentfulComponentMixin from '@/components/common/contentful/mixins/contentfulComponentMixin'
import punchoutMixin from '@/components/common/mixins/punchoutMixin'
import screenWidthMixin from '@/components/common/mixins/screenWidthMixin'

import SubMenu from '@/components/common/AppHeader/SubMenu.vue'

export default {
  name: 'MainMenu',
  components: {
    SubMenu,
  },
  mixins: [
    screenWidthMixin,
    contentfulAutowireGlobalMixin,
    punchoutMixin,
    contentfulComponentMixin,
    menuUnderlineMixin,
  ],
  props: {
    isRightMenuVisible: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['linkClicked'],
  data() {
    return {
      activeItemIdx: null,
      activeMenuItem: null,
      submenuLeftPosition: 0,
      windowWidth: 0,
      menuItems: [],
      isInitialized: false,
      disableSubmenu: true,
    }
  },
  computed: {
    ...mapState('cart', ['referenceNumbers']),
    ...mapGetters('cart', ['productsQty']),
    ...mapGetters('session', ['isUserLoggedIn']),
    requestQuoteCaption() {
      const qty = this.referenceNumbers.length ? 0 : this.productsQty
      return `${this.$t('common.viewMyQuote')} <span class="main-menu__item-qty">${qty}</span>`
    },
    getMenuItems() {
      return this.isUserLoggedIn
        ? this.menuItems.filter((el) => el)
        : this.menuItems.filter((el) => el && el.link !== 'inventory')
    },
  },
  mounted() {
    nextTick(() => {
      setTimeout(() => {
        this.isInitialized = true
      }, 10)
    })
  },
  methods: {
    setActiveItem(idx) {
      this.activeItemIdx = idx
      this.getSubmenuTopPosition()
    },
    setActiveItemOnHover(idx) {
      if (this.isDesktop && !this.disableSubmenu)
        this.setActiveItem(this.isInitialized ? idx : null)
    },
    getSubmenuTopPosition() {
      this.windowWidth = document.body.clientWidth
      this.submenuTopPosition =
        this.$refs.menuItem?.[this.activeItemIdx]?.getBoundingClientRect()?.left
    },
    onMenuLinkHover() {
      if (this.isInitialized && this.disableSubmenu) this.disableSubmenu = false
    },
    onLinkClicked() {
      this.$emit('linkClicked')
      this.setActiveItem(null)
    },
    onItemTitleClick(link) {
      this.$router.push(link)
      this.onLinkClicked()
    },
    onSubmenuClose() {
      this.mainMenuMouseleaveListener()
      this.setActiveItemOnHover(null)
    },
    initData(entries) {
      this.menuItems = entries.map((el) => {
        const page = this.$contentful.service.getPageBySlug(el.fields?.catalogRoot.fields?.slug)
        if (page) {
          const relatedPages = el.fields?.relatedPages?.filter((el) => el.fields) || []
          const subpages = page.fields?.subpages?.filter((el) => el.fields) || []
          const rawSubpagesGrouped = page.fields?.subpagesGrouped?.filter((el) => el.fields) || []
          const subpagesGrouped = rawSubpagesGrouped.map((el) => ({
            ...el,
            group: page.fields.subpagesGroupedTitle || '',
          }))
          const rawSubpages = [...subpages, ...subpagesGrouped, ...relatedPages]
          const subitems = rawSubpages.filter(
            (category) => category?.fields && category.fields.title !== 'Services',
          )
          return {
            title: el.fields.title,
            description: page.fields.subtitle,
            link: page.link,
            isNestedSubmenu: page.fields.slug === 'products' || page.fields.slug === 'services',
            isProducts: page.fields.slug === 'products',
            hasSubmenu: !!subitems?.length,
            subitems,
            subitemsGroupedTitle: page.fields.subpagesGroupedTitle || '',
          }
        }
      })
    },
  },
}
</script>
