import { i18n } from '@/i18n'

const _flowCrumbs = [
  {
    value: 'empty',
    hidden: true,
  },
  {
    value: 'view',
    title: i18n.global.te('cart.steps.view.crumbs')
      ? i18n.global.t('cart.steps.view.crumbs')
      : 'cart.steps.view.crumbs',
    nextButtonId: 'cart-continue-to-shipping-button',
  },
  {
    value: 'shipping',
    title: i18n.global.te('cart.steps.shipping.crumbs')
      ? i18n.global.t('cart.steps.shipping.crumbs')
      : 'cart.steps.shipping.crumbs',
    nextButtonId: 'cart-continue-to-billing-button',
  },
  {
    value: 'billing',
    title: i18n.global.te('cart.steps.billing.crumbs')
      ? i18n.global.t('cart.steps.billing.crumbs')
      : 'cart.steps.billing.crumbs',
    nextButtonId: 'cart-continue-to-review-button',
  },
  {
    value: 'review',
    title: i18n.global.te('cart.steps.review.crumbs')
      ? i18n.global.t('cart.steps.review.crumbs')
      : 'cart.steps.review.crumbs',
    nextButtonId: 'cart-submit-quote-bottom-button',
  },
  {
    value: 'confirmation',
    hidden: true,
  },
]

const defaultState = {
  step: _flowCrumbs.find((el) => el.value === 'empty'),
}

const state = { ...defaultState }

const mutations = {
  SET_STEP(state, step) {
    state.step = step
    if (document.querySelector('.steps')) {
      document.querySelector('.steps').scrollIntoView()
    }
  },
}

const actions = {
  setStep({ commit, getters, rootGetters }, stepData) {
    const fallbackStep = rootGetters['cart/productsQty'] ? getters.crumbs[1] : getters.crumbs[0]
    const step = getters.crumbs.find((el) => el.value === stepData) || fallbackStep
    commit('SET_STEP', step)
  },
  incrementStep({ commit, state, getters }) {
    const valuesArr = getters.crumbs.map((el) => el.value)
    const currStepIndex = valuesArr.indexOf(state.step.value)
    commit('SET_STEP', getters.crumbs[currStepIndex + 1])
  },
  decrementStep({ commit, state, getters }) {
    const valuesArr = getters.crumbs.map((el) => el.value)
    const currStepIndex = valuesArr.indexOf(state.step.value)
    commit('SET_STEP', getters.crumbs[currStepIndex - 1])
  },
}

const getters = {
  crumbs: (state, getters, rootState, rootGetters) => {
    return rootGetters['cart/isCartGuestView']
      ? _flowCrumbs.filter((el) => !['shipping', 'billing'].includes(el.value))
      : _flowCrumbs
  },
}

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true,
}
