import api from '@/lib/api'
import sorting from '@/lib/utils/sorting'

import {
  transformInventoryThreshold,
  transformDepartment,
  transformApiFormErrors,
} from '@/components/cart/helpers/transformHelpers'

const _defaultState = {
  modes: {
    new: 'NEW',
    edit: 'EDIT',
    copy: 'COPY',
  },
  inventoryThresholdsData: [],
  pagination: {},
  isThresholdDepartmentsLoaded: false,
  thresholdDepartments: [],
  isCompanySkusLoaded: false,
  companySkus: [],
}
const getDefaultState = () => JSON.parse(JSON.stringify(_defaultState))
const state = getDefaultState()

const mutations = {
  SET_INVENTORY_THRESHOLDS(state, { inventoryThresholdsData, pagination }) {
    state.inventoryThresholdsData = inventoryThresholdsData
    state.pagination = pagination
  },
  SET_TRESHOLD_DEPARTMENTS(state, departments) {
    state.thresholdDepartments = departments
    state.isThresholdDepartmentsLoaded = true
  },
  SET_COMPANY_SKUS(state, skus) {
    state.companySkus = skus
    state.isCompanySkusLoaded = true
  },
  RESET_INVENTORY_THRESHOLDS(state) {
    state.inventoryThresholdsData = []
    state.pagination = {}
  },
  RESET_STATE(state) {
    Object.assign(state, getDefaultState())
  },
}

const actions = {
  async getInventoryThresholds({ commit, state }, filters) {
    const response = await api.inventoryTracking.getInventoryThresholds(filters)
    const { inventoryThresholds, pagination } = response.data
    const transformedThresholds = inventoryThresholds.map(transformInventoryThreshold)
    const inventoryThresholdsData =
      filters.page > 0
        ? [...state.inventoryThresholdsData, ...transformedThresholds]
        : transformedThresholds
    commit('SET_INVENTORY_THRESHOLDS', { inventoryThresholdsData, pagination })
    return response.data.pagination
  },
  async createInventoryThreshold(context, thresholdData) {
    try {
      await api.inventoryTracking.createInventoryThreshold(thresholdData)
    } catch (error) {
      const transformedError = new Error('Threshold validation error')
      transformedError.response = {
        data: transformApiFormErrors(error.response.data),
      }
      throw transformedError
    }
  },
  async editInventoryThreshold(context, thresholdData) {
    try {
      await api.inventoryTracking.updateInventoryThreshold(thresholdData)
    } catch (error) {
      const transformedError = new Error('Threshold validation error')
      transformedError.response = {
        data: transformApiFormErrors(error.response.data),
      }
      throw transformedError
    }
  },
  async validateThreshold(context, thresholdData) {
    try {
      await api.inventoryTracking.validateThreshold(thresholdData)
      return { ok: true }
    } catch (error) {
      return {
        ok: false,
        errors: transformApiFormErrors(error.response.data),
      }
    }
  },
  async getDepartments(context, params) {
    const response = await api.inventoryTracking.getDepartments({
      pageSize: 9999,
      ...params,
    })
    const { departments: rawDepartments = [] } = response.data
    const transformDepartments = rawDepartments.map(transformDepartment)
    const sortedDepartments = sorting.sortObjectsListByAlphaAsc(transformDepartments, 'name')
    return sortedDepartments
  },
  async getThresholdDepartments({ state, rootGetters, dispatch, commit }, params) {
    if (!rootGetters['roles/isUserSuperAdmin'] && state.isThresholdDepartmentsLoaded) {
      return state.thresholdDepartments
    }
    const departments = await dispatch('getDepartments', params)
    if (!rootGetters['roles/isUserSuperAdmin'])
      await commit('SET_TRESHOLD_DEPARTMENTS', departments)
    return departments
  },
  async getCompanySkus({ state, rootGetters, commit }, { company }) {
    if (!rootGetters['roles/isUserSuperAdmin'] && state.isCompanySkusLoaded) {
      return state.companySkus
    }
    const { data } = await api.inventoryTracking.getCompanySkus(company)
    const skus = data.skus?.length
      ? data.skus.map(({ sku, skuName }) => ({
          sku,
          skuName,
          title: `${skuName} (${sku})`,
        }))
      : []
    if (!rootGetters['roles/isUserSuperAdmin']) await commit('SET_COMPANY_SKUS', skus)
    return skus
  },
  resetInventoryThresholds({ commit }) {
    commit('RESET_INVENTORY_THRESHOLDS')
  },
}

export default {
  state,
  mutations,
  actions,
  namespaced: true,
}
