<template>
  <div v-if="currentComponent.component === 'default'" class="container error-container">
    <h1>{{ currentComponent.data }}</h1>
  </div>
  <component :is="currentComponent.component" v-else :data="currentComponent.data" />
</template>
<script>
import { mapState } from 'vuex'

import PunchoutCriticalErrorView from '@/components/PunchoutCriticalErrorView.vue'

export default {
  components: { PunchoutCriticalErrorView },
  computed: {
    ...mapState('app', ['criticalViewData']),
    currentComponent() {
      const components = {
        punchout: {
          component: PunchoutCriticalErrorView,
          data: this.criticalViewData.data,
        },
        default: {
          component: 'default',
          data: this.criticalViewData.data || 'Something went wrong',
        },
      }
      return components[this.criticalViewData?.templateName] || components.default
    },
  },
  mounted() {
    if (this.criticalViewData.data) this.$err.sendMessage(this.criticalViewData.data)
  },
}
</script>
<style>
.error-container {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
</style>
