import api from '@/lib/api'

import app from '@/main'

const DEFAULT_STATE = {
  productsCache: {},
  customizationSnapshot: {
    slugPath: '',
    customizations: [],
    selectedSku: '',
    inventoryIds: [],
  },
  analyticsData: {
    navigatedFromPageTitle: '',
  },
}

const getDefaultState = () => JSON.parse(JSON.stringify(DEFAULT_STATE))
const state = getDefaultState()

const mutations = {
  SET_PRODUCTS_CACHE(state, data) {
    const newProductsCache = Object.assign({}, state.productsCache, data)
    state.productsCache = newProductsCache
  },
  RESET_PRODUCTS_CACHE(state) {
    state.productsCache = {}
  },
  UNSET_CUSTOMIZATION_SNAPSHOT(state) {
    state.customizationSnapshot = {}
  },
  SET_CUSTOMIZATION_SNAPSHOT(state, { customizations, slugPath, selectedSku, inventoryIds }) {
    state.customizationSnapshot = {
      customizations,
      slugPath,
      selectedSku,
      inventoryIds,
    }
  },
  SET_PRODUCT_ANALYTICS_DATA(state, { navigatedFromPageTitle }) {
    state.analyticsData = { navigatedFromPageTitle }
  },
  RESET_STATE(state) {
    Object.assign(state, getDefaultState())
  },
}

const actions = {
  async getProductData({ commit, state }, productToGet) {
    try {
      const cacheKey = Object.entries({
        code: productToGet.code,
        salesToStock: productToGet.salesToStock,
        aliqPerLot: productToGet.aliqPerLot,
        volPerAliq: productToGet.volPerAliq,
      })
        .map(([key, value]) => `${key}:${value}`)
        .join('&')
      const isProductInCache = state.productsCache[cacheKey]
      if (!isProductInCache) {
        const { data: productDataRaw } = await api.products.getProduct(productToGet)
        await commit('SET_PRODUCTS_CACHE', { [cacheKey]: productDataRaw })
      }
      return state.productsCache[cacheKey]
    } catch (error) {
      app.config.globalProperties.$err.sendMessage(
        `Get product request failed. Code: ${productToGet.code}. URL: ${window.location.href}`,
      )
    }
  },
  setCustomizationSnapshot({ commit }, payload) {
    commit('SET_CUSTOMIZATION_SNAPSHOT', payload)
  },
  unsetCustomizationSnapshot({ commit }) {
    commit('UNSET_CUSTOMIZATION_SNAPSHOT')
  },
  setProductAnalyticsData({ commit }, data) {
    commit('SET_PRODUCT_ANALYTICS_DATA', data)
  },
}

const getters = {
  getCustomizationSnapshot: (state) => (slugPath) =>
    state.customizationSnapshot.slugPath === slugPath ? state.customizationSnapshot : null,
}

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true,
}
