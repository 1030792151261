<template>
  <button class="back-to-top" @click="scrollToTop" />
</template>

<script>
import scroll from '@/lib/utils/scroll'

export default {
  name: 'BackToTopBtn',
  methods: {
    scrollToTop() {
      scroll.pageToTop()
    },
  },
}
</script>
