export const PAGETYPES = {
  CATALOG_PRODUCTS: 'Catalog - Products',
  CATEGORY_TYPE: 'Category - Type',
  CATEGORY_SPECIES: 'Category - Species',
  CATEGORY_DESEASE_FOCUS: 'Category - Disease Focus',
  CATEGORY_APPLICATION: 'Category - Applications',
  CATEGORY_INDUSTRIES: 'Category - Industries',
  SUBCATEGORY_SUBTYPE: 'Subcategory - Subtype',
  SUBCATEGORY_SPECIES: 'Subcategory - Species',
  SUBCATEGORY_DESEASE_FOCUS: 'Subcategory - Disease Focus',
  CATALOG_RESOURCES: 'Catalog - Resources',
  CATEGORY_RESOURCE_ISOLATION_CALENDAR: 'Category - Resource - Isolation Calendar',
  CATEGORY_RESOURCE_POSTERS: 'Category - Resource - Posters',
  CATEGORY_RESOURCE_INTRUCTIONAL_SHEETS: 'Category - Resource - Instructional Sheets',
  CATEGORY_RESOURCE_PROTOCOLS_TUTORIALS: 'Category - Resource - Protocols & Tutorials',
  CATEGORY_RESOURCE_BROCHURES: 'Category - Resource - Brochures',
  CATEGORY_RESOURCE_CASE_STUDIES: 'Category - Resource - Case Studies',
  CATEGORY_RESOURCE_WEBINARS_VIDEOS: 'Category - Resource - Webinars & Videos',
  CATEGORY_RESOURCE_INVENTORY: 'Category - Resource - Ready-to-ship Inventory',
  CATEGORY_RESOURCE_EVENTS: 'Category - Resource - Events',
  CATEGORY_RESOURCE_PRESS_RELEASES: 'Category - Resource - Press Releases',
  CATEGORY_RESOURCE_BLOGS: 'Category - Resource - Blogs',
  CATEGORY_RESOURCE_NEWSLETTER: 'Category - Resource - Newsletter',
  CATEGORY_RESOURCE_PRODUCT_RESOURCES: 'Category - Resource - Product Resources',
  CATEGORY_RESOURCE_ARTICLES: 'Category - Resource - Articles',
  CATEGORY_RESOURCE_EDUCATIONAL_CONTENT: 'Category - Resource - Educational Content',
  CATEGORY_RESOURCE_PRODUCTS: 'Category - Resource - Products',
  CATEGORY_RESOURCE_PUBLICATIONS: 'Category - Resource - Publications',
  LANDING_PAGE: 'Landing Page',
}
