import settings from '@/settings'

if (settings.services.eightByEight.enabled) {
  document.addEventListener(
    'DOMContentLoaded',
    () => {
      const container = document.createElement('div')
      container.id = `__8x8-chat-button-container-${settings.services.eightByEight.uuid}`
      container.classList = ['_8x8-chat-button']
      const containerHost = document.querySelector('.popup') || document.body
      containerHost.prepend(container)

      window.__8x8Chat = {
        uuid: settings.services.eightByEight.uuid,
        tenant: settings.services.eightByEight.tenant,
        channel: settings.services.eightByEight.channel,
        domain: 'https://vcc-na18b.8x8.com',
        path: '/.',
        buttonContainerId: `__8x8-chat-button-container-${settings.services.eightByEight.uuid}`,
        align: 'right',
      }

      const chatScript = document.createElement('script')
      chatScript.type = 'text/javascript'
      chatScript.async = true
      chatScript.src = window.__8x8Chat.domain + window.__8x8Chat.path + '/CHAT/common/js/chat.js'
      document.head.appendChild(chatScript)
    },
    false,
  )
}

const ChatService = {}

export default ChatService
