import api from '@/lib/api'

import { transformInventoryLog } from '@/components/cart/helpers/transformHelpers'

const _defaultState = {
  inventoryLogData: [],
  pagination: {},
}
const getDefaultState = () => JSON.parse(JSON.stringify(_defaultState))
const state = getDefaultState()

const mutations = {
  SET_INVENTORY_LOG(state, { inventoryLog, pagination }) {
    state.inventoryLogData = inventoryLog
    state.pagination = pagination
  },
  RESET_STATE(state) {
    Object.assign(state, getDefaultState())
  },
}

const actions = {
  async getInventoryLog({ commit }, filters) {
    const response = await api.inventoryTracking.getInventoryLogs(filters)
    const { inventoryLogs, pagination } = response.data
    const transformedLogs = inventoryLogs.map(transformInventoryLog)
    commit('SET_INVENTORY_LOG', { inventoryLog: transformedLogs, pagination })
    return response.data.pagination
  },
  async downloadInventoryLog(context, searchTerm) {
    const response = await api.inventoryTracking.downloadInventoryLog(searchTerm)
    return response
  },
  resetState({ commit }) {
    commit('RESET_STATE')
  },
}

export default {
  state,
  mutations,
  actions,
  namespaced: true,
}
