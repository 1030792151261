import { isDateExpiring } from '@/lib/utils/dates'

export const transformDeliveryContact = (el) => {
  const userPhone = el.contactAddress?.phone ? el.contactAddress.phone : el.phone
  const phoneExtension = el.contactAddress?.phoneExtension
    ? `, ${el.contactAddress.phoneExtension}`
    : ''
  return {
    title: el.name,
    value: el.uid,
    email: el.uid,
    id: el.sageX3Id,
    rawPhone: el.phone,
    additionalText: [
      userPhone ? `<p>${userPhone}${phoneExtension}</p>` : '',
      el.uid ? `<p>${el.uid}</p>` : '',
      el.contactAddress?.buildingRoomNumber
        ? `<p>Building / Room #: ${el.contactAddress.buildingRoomNumber}</p>`
        : '',
    ]
      .filter((el) => el)
      .join(''),
  }
}

export const transformReserveOrder = (reserve, cartProducts) => {
  const reservedOrderLine = `${reserve.reserveId}:${reserve.entryNumber}`
  const lots = reserve.allocationList.map((lotData) => {
    const cartProduct = cartProducts.find(
      (cartProduct) =>
        cartProduct.reservedOrderLine === reservedOrderLine &&
        cartProduct.inventory.some(
          (cartInventory) => cartInventory.objectID === lotData.inventoryId,
        ),
    )
    return {
      objectID: lotData.inventoryId,
      lotId: lotData.lot,
      quantity: lotData.quantity,
      rollQuantityUnit: lotData.rollQuantityUnit,
      isSalesToStockMainPriceParam:
        lotData.rollQuantityUnit &&
        !['ea', 'vil', 'vial', 'vials'].includes(lotData.rollQuantityUnit.toLowerCase()),
      cartProduct,
      isAddedToCart: !!cartProduct,
      isAddToCartEnabled: !!lotData.inventoryId && !!lotData.quantity,
      reservedOrderLine,
    }
  })
  return {
    reservedOrderLine,
    reserveId: reserve.reserveId,
    entryNumber: reserve.entryNumber,
    productName: reserve.product?.name,
    productCode: reserve.product?.code,
    productPdpSlug: reserve.product?.pgpSlug,
    quantity: reserve.quantity,
    isAddedToCart: lots.some((lot) => lot.isAddedToCart),
    isAddToCartEnabled: lots.some((lot) => lot.isAddToCartEnabled),
    lots,
    endDate: {
      value: reserve.endDate || '',
      isExpiring: reserve.endDate ? isDateExpiring(reserve.endDate, 7) : false,
    },
    contact: reserve.contact,
  }
}

export const transformOrderTemplate = (orderTemplate) => {
  const b2bUnitCode = orderTemplate.b2bUnit
  const b2bUnitName = orderTemplate.b2bUnitName || orderTemplate.b2bUnit
  const b2bUnit = {
    code: b2bUnitCode,
    name: b2bUnitName,
  }

  return {
    id: orderTemplate.id,
    active: orderTemplate.active,
    b2bUnit,
    billingB2bUnit: b2bUnit,
    shippingB2bUnit: b2bUnit,
    department: orderTemplate.department,
    billTo: orderTemplate.billTo,
    billToAddressString: orderTemplate.billToAddress,
    billingContact: orderTemplate.billingContact,
    billingEmails: orderTemplate.billingEmails || [],
    contact: orderTemplate.contact,
    contactName: orderTemplate.contactName || '',
    contactEmail: orderTemplate.contactEmail || '',
    name: orderTemplate.name || '',
    expirationDate: orderTemplate.expirationDate || '',
    notes: orderTemplate.notes || '',
    poNumber: orderTemplate.poNumber || '',
    shipTo: orderTemplate.shipTo,
    shipToAddressString: orderTemplate.shipToAddress,
    shippingContact: orderTemplate.shippingContact,
    shippingEmails: orderTemplate.shippingEmails || [],
  }
}

export const transformDepartment = (department) => ({
  id: department.id,
  businessUnit: department.businessUnit,
  name: department.name,
})

export const transformInventoryThreshold = (threshold) => ({
  name: threshold.name,
  id: threshold.id,
  department: transformDepartment(threshold.department),
  sku: threshold.sku || '',
  skuName: threshold.skuName || '',
  lab: threshold.lab,
  orderTemplate: transformOrderTemplate(threshold.orderTemplate),
  minimumQuantity: threshold.minimumQuantity,
  maximumQuantity: threshold.maximumQuantity,
  notes: threshold.notes || '',
  active: threshold.active,
})

export const transformInventoryLog = (log) => ({
  id: log.id,
  logDate: log.logDate || 0,
  sku: log.sku || '',
  productDescription: log.productDescription || '',
  quantity: log.quantity || 0,
  originalInventory: log.originalInventory || 0,
  currentInventory: log.currentInventory || 0,
  b2bUnit: log.department?.businessUnit || '',
  departmentName: log.department?.name || '',
  po: log.po || '',
  deliveryAddress: log.deliveryAddress || '',
  order: log.order || '',
  orderStatus: log.orderStatus || '',
  orderQty: log.orderQty || 0,
})

export const transformApiFormErrors = (errors) => {
  const fieldErrors = []
  let commonErrors = []

  const splitErrorsBySubject = (errors) => {
    const splitedErrors = []
    if (errors && Array.isArray(errors)) {
      errors.forEach((error) => {
        const subjects = error?.subject?.split(',')
        if (subjects?.length) {
          subjects.forEach((subject) => {
            splitedErrors.push({ ...error, subject })
          })
        }
      })
    }
    return splitedErrors
  }
  const splitedErrors = splitErrorsBySubject(errors)

  if (splitedErrors?.length) {
    const fieldErrorsMap = {}
    const uniqueCommonErrors = new Set()
    splitedErrors.forEach((error) => {
      const fieldName = error.subject
      const message = error.message

      if (!fieldName) {
        uniqueCommonErrors.add(message)
        return
      }

      const isFieldInMap = fieldName in fieldErrorsMap
      if (!isFieldInMap) fieldErrorsMap[fieldName] = new Set()
      fieldErrorsMap[fieldName].add(message)
    })

    Object.entries(fieldErrorsMap).forEach(([field, messages]) => {
      fieldErrors.push({
        type: 'ValidationError',
        subject: field,
        message: Array.from(messages).join('; '),
      })
    })

    commonErrors = Array.from(uniqueCommonErrors).map((message) => ({
      type: 'ValidationError',
      message,
    }))
  }

  return [...fieldErrors, ...commonErrors]
}
