<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.76728 18.4142C9.15808 18.0391 9.37763 17.5304 9.37763 17C9.37763 16.4696 9.15808 15.9609 8.76728 15.5858C8.37649 15.2107 7.84646 15 7.29379 15C6.74113 15 6.2111 15.2107 5.8203 15.5858C5.42951 15.9609 5.20996 16.4696 5.20996 17C5.20996 17.5304 5.42951 18.0391 5.8203 18.4142C6.2111 18.7893 6.74113 19 7.29379 19C7.84646 19 8.37649 18.7893 8.76728 18.4142Z"
      :stroke="color"
    />
    <path
      d="M19.1864 18.4142C19.5772 18.0391 19.7968 17.5304 19.7968 17C19.7968 16.4696 19.5772 15.9609 19.1864 15.5858C18.7957 15.2107 18.2656 15 17.713 15C17.1603 15 16.6303 15.2107 16.2395 15.5858C15.8487 15.9609 15.6291 16.4696 15.6291 17C15.6291 17.5304 15.8487 18.0391 16.2395 18.4142C16.6303 18.7893 17.1603 19 17.713 19C18.2656 19 18.7957 18.7893 19.1864 18.4142Z"
      :stroke="color"
    />
    <path
      d="M13.5451 16V6C13.5451 5.73478 13.4354 5.48043 13.24 5.29289C13.0446 5.10536 12.7796 5 12.5032 5H4.16789C3.89156 5 3.62654 5.10536 3.43115 5.29289C3.23575 5.48043 3.12598 5.73478 3.12598 6V16C3.12598 16.2652 3.23575 16.5196 3.43115 16.7071C3.62654 16.8946 3.89156 17 4.16789 17H5.20981M13.5451 16C13.5451 16.2652 13.4354 16.5196 13.24 16.7071C13.0446 16.8946 12.7796 17 12.5032 17H9.37747M13.5451 16V8C13.5451 7.73478 13.6549 7.48043 13.8503 7.29289C14.0457 7.10536 14.3107 7 14.5871 7H17.2815C17.5578 7.00006 17.8227 7.10545 18.0181 7.293L21.5752 10.707C21.7706 10.8945 21.8804 11.1488 21.8805 11.414V16C21.8805 16.2652 21.7707 16.5196 21.5753 16.7071C21.3799 16.8946 21.1149 17 20.8386 17H19.7966M13.5451 16C13.5451 16.2652 13.6549 16.5196 13.8503 16.7071C14.0457 16.8946 14.3107 17 14.5871 17H15.629M5.20981 17C5.20981 17.5304 5.42935 18.0391 5.82015 18.4142C6.21094 18.7893 6.74097 19 7.29364 19C7.84631 19 8.37634 18.7893 8.76713 18.4142C9.15793 18.0391 9.37747 17.5304 9.37747 17M5.20981 17C5.20981 16.4696 5.42935 15.9609 5.82015 15.5858C6.21094 15.2107 6.74097 15 7.29364 15C7.84631 15 8.37634 15.2107 8.76713 15.5858C9.15793 15.9609 9.37747 16.4696 9.37747 17M19.7966 17C19.7966 17.5304 19.5771 18.0391 19.1863 18.4142C18.7955 18.7893 18.2655 19 17.7128 19C17.1601 19 16.6301 18.7893 16.2393 18.4142C15.8485 18.0391 15.629 17.5304 15.629 17M19.7966 17C19.7966 16.4696 19.5771 15.9609 19.1863 15.5858C18.7955 15.2107 18.2655 15 17.7128 15C17.1601 15 16.6301 15.2107 16.2393 15.5858C15.8485 15.9609 15.629 16.4696 15.629 17"
      :stroke="color"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
<script>
export default {
  name: 'IconTruckThick',
  props: {
    color: {
      type: String,
      default: '#000',
    },
    width: {
      type: [String, Number],
      default: 25,
    },
    height: {
      type: [String, Number],
      default: 24,
    },
  },
}
</script>
