<template>
  <a
    :href="href"
    :target="target"
    :style="{ marginTop: marginTopAsNumber ? `${marginTopAsNumber}px` : null }"
    :class="classList"
    class="button"
    @click.prevent="navigateTo(href)"
    >{{ text }}</a
  >
</template>
<script>
import { mapGetters } from 'vuex'

import contentfulComponentMixin from '@/components/common/contentful/mixins/contentfulComponentMixin'
import navigationMixin from '@/components/common/mixins/navigationMixin'

export default {
  name: 'ParticleLink',
  mixins: [contentfulComponentMixin, navigationMixin],
  props: {
    entryFields: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  computed: {
    ...mapGetters('session', ['isUserLoggedIn']),
    text() {
      return this.isUserLoggedIn
        ? this.entryFields.loggedInLinkTitle || this.entryFields.title
        : this.entryFields.title
    },
    href() {
      const mainLink = this.isUserLoggedIn
        ? this.entryFields.loggedInLink || this.entryFields.link
        : this.entryFields.link

      const loggedInPageLink = this.entryFields.loggedInPageLink?.fields
        ? this.getPageBySlug(this.entryFields.loggedInPageLink.fields.slug)?.link
        : ''
      const page = this.entryFields.pageLink?.fields
        ? this.getPageBySlug(this.entryFields.pageLink.fields.slug)?.link
        : ''
      const pageLink = this.isUserLoggedIn ? loggedInPageLink || page : page

      return pageLink || mainLink
    },
    marginTopAsNumber() {
      return Math.floor(this.entryFields.marginTop)
    },
    target() {
      return this.entryFields.newTab ? '_blank' : '_self'
    },
    classList() {
      const isLinkRepresentation = this.entryFields.representation === 'Link'
      const buttonClassesByColor = {
        Teal: 'button--secondary-inversed',
        Navy: 'button--primary',
        White: 'button--white',
        Lime: 'button--green',
      }
      const buttonClassesBySize = {
        Small: 'button--small',
        'Sub-Medium': 'button--sub-middle',
        Medium: 'button--middle',
        Large: 'button--large',
      }
      const buttonClassesByAlignment = {
        Inline: 'button--inline',
        'Block-left': 'button--block-left',
        'Block-center': 'button--block-center',
        'Block-right': 'button--block-right',
      }
      const buttonClassByColor = this.entryFields.buttonColor
        ? buttonClassesByColor[this.entryFields.buttonColor]
        : buttonClassesByColor.Teal
      const buttonClassBySize = this.entryFields.buttonSize
        ? buttonClassesBySize[this.entryFields.buttonSize]
        : buttonClassesBySize.Medium
      const buttonClassByAlignment = this.entryFields.buttonAlignment
        ? buttonClassesByAlignment[this.entryFields.buttonAlignment]
        : buttonClassesByAlignment.Inline
      return [
        isLinkRepresentation ? 'button--link' : '',
        buttonClassBySize,
        buttonClassByColor,
        buttonClassByAlignment,
      ]
        .filter((el) => el)
        .join(' ')
    },
  },
}
</script>
