<template>
  <div class="loader" :class="[variant ? `loader--${variant}` : '']">
    <div
      class="loader__container"
      :class="{
        'loader__container--inline': inline,
        'loader__container--cartProduct': cartProduct,
      }"
    >
      <div class="spinner" :style="sizeProps" />
      <div v-if="text.length > 0" class="loader__text" v-html="text" />
    </div>
  </div>
</template>
<script>
export default {
  name: 'Loader',
  props: {
    inline: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: Number,
      default: 30,
    },
    text: {
      type: String,
      required: false,
      default: '',
    },
    variant: {
      type: String,
      required: false,
      default: '',
    },
    cartProduct: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    sizeProps() {
      const borderWidth = Math.min(Math.ceil(this.size / 10), 3)
      return {
        width: `${this.size}px`,
        height: `${this.size}px`,
        borderWidth: `${borderWidth}px`,
      }
    },
  },
}
</script>
