import { mapState } from 'vuex'

import contentfulComponentMixin from '@/components/common/contentful/mixins/contentfulComponentMixin'

export default {
  mixins: [contentfulComponentMixin],
  computed: {
    ...mapState('app', { isAppInitialized: 'isInitialized' }),
  },
  methods: {
    getContentfulData() {
      this.contentfulState.loading = false
      const componentSettings = this.parentComponentName
        ? this.$settings.contentfulMap[`${this.parentComponentName}-${this.$options.name}`]
        : this.$settings.contentfulMap[this.$options.name]

      if (!componentSettings || !componentSettings.configurationSlot) {
        console.error('Contentful Error: missing field in component mapping')
        this.contentfulState.error = true
        return
      }

      const entry = this.$contentful.service.getDataForComponent(
        componentSettings.configurationSlot,
      )
      if (entry) {
        const payload = entry.fields ? entry.fields : entry
        this.initData(payload)
      }
    },
  },
  watch: {
    isAppInitialized() {
      if (this.isContentfulAutowireOn) this.getContentfulData()
    },
  },
  mounted() {
    if (this.isAppInitialized && this.isContentfulAutowireOn) this.getContentfulData()
  },
  data() {
    return {
      isContentfulAutowireOn: true,
      item: {},
    }
  },
}
