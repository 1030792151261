import ApiBase from '@/lib/api/v1/api-base'

class Cart extends ApiBase {
  constructor(transport) {
    super(transport)
    this.getCart = this.getCart.bind(this)
    this.setCartShippingAddressId = this.setCartShippingAddressId.bind(this)
    this.setCartBillingAddressId = this.setCartBillingAddressId.bind(this)
    this.setPaymentMethod = this.setPaymentMethod.bind(this)
    this.updateShippingNotificationEmails = this.updateShippingNotificationEmails.bind(this)
    this.updateBillingNotificationEmails = this.updateBillingNotificationEmails.bind(this)
    this.addProducts = this.addProducts.bind(this)
    this.updateProduct = this.updateProduct.bind(this)
    this.deleteProducts = this.deleteProducts.bind(this)
    this.duplicateProduct = this.duplicateProduct.bind(this)
    this.getDeliveryContacts = this.getDeliveryContacts.bind(this)
    this.setDeliveryContact = this.setDeliveryContact.bind(this)
    this.setProductShippingAddress = this.setProductShippingAddress.bind(this)
    this.deleteProductShippingAddress = this.deleteProductShippingAddress.bind(this)
    this.setProductDeliveryContact = this.setProductDeliveryContact.bind(this)
    this.placeQuote = this.placeQuote.bind(this)
    this.deleteCart = this.deleteCart.bind(this)
    this.setPromo = this.setPromo.bind(this)
  }

  /**
   * Return cart.
   */
  getCart({ recalculate }, userId) {
    return this._withDecoratedPromise(() => {
      return this.transport.get(
        `/rest/api/v1/users/${this._getUserId(
          userId,
        )}/carts/current/v2?recalculate=${encodeURIComponent(recalculate)}`,
      )
    })
  }

  createCart(data, userId) {
    return this._withDecoratedPromise(() => {
      return this.transport.post(`/rest/api/v1/users/${this._getUserId(userId)}/carts/`, data)
    })
  }

  /**
   * Set cart shipping address data.
   * @param addressId
   */
  setCartShippingAddressId(payload, userId) {
    return this._withDecoratedPromise(() => {
      return this.transport.put(
        `/rest/api/v1/users/${this._getUserId(userId)}/carts/current/addresses/delivery`,
        payload,
      )
    })
  }

  /**
   * Set cart billing address Id.
   * @param data addressId
   */
  setCartBillingAddressId(payload, userId) {
    return this._withDecoratedPromise(() => {
      return this.transport.put(
        `/rest/api/v1/users/${this._getUserId(userId)}/carts/current/addresses/billing`,
        payload,
      )
    })
  }

  /**
   * Set payment method.
   * @param data
   */
  setPaymentMethod({ data }, userId) {
    return this._withDecoratedPromise(() => {
      return this.transport.put(
        `/rest/api/v1/users/${this._getUserId(userId)}/carts/current/paymentmethod`,
        data,
      )
    })
  }

  updateShippingNotificationEmails(emails, userId) {
    return this._withDecoratedPromise(() => {
      const payload = { emails: emails }
      return this.transport.put(
        `/rest/api/v1/users/${this._getUserId(userId)}/carts/current/delivery/notifications`,
        payload,
      )
    })
  }

  updateBillingNotificationEmails(emails, userId) {
    return this._withDecoratedPromise(() => {
      const payload = { emails: emails }
      return this.transport.put(
        `/rest/api/v1/users/${this._getUserId(userId)}/carts/current/billing/notifications`,
        payload,
      )
    })
  }

  /**
   * Add product to the cart.
   * @returns {Promise<any>}
   */
  addProducts(data, userId) {
    return this._withDecoratedPromise(() => {
      return this.transport.post(
        `/rest/api/v1/users/${this._getUserId(userId)}/carts/current/v2/entries`,
        data,
      )
    })
  }

  /**
   * Update product from the product page.
   */
  updateProduct(entryId, data, userId) {
    return this._withDecoratedPromise(() => {
      return this.transport.put(
        `/rest/api/v1/users/${this._getUserId(userId)}/carts/current/v2/entries/${entryId}`,
        data,
      )
    })
  }

  /**
   * Delete products from the cart.
   * @param entryNumbers
   */
  deleteProducts(entryNumbers, userId) {
    return this._withDecoratedPromise(() => {
      return this.transport.delete(
        `/rest/api/v1/users/${this._getUserId(userId)}/carts/current/entries`,
        entryNumbers,
      )
    })
  }

  /**
   * Duplicate product to the cart.
   * @returns {Promise<any>}
   */
  duplicateProduct(entryId, userId) {
    return this._withDecoratedPromise(() => {
      return this.transport.post(
        `/rest/api/v1/users/${this._getUserId(userId)}/carts/current/entries/${entryId}/duplicate`,
      )
    })
  }

  /**
   * Get users's unit related customers.
   */
  getDeliveryContacts(userId) {
    return this._withDecoratedPromise(() => {
      return this.transport.get(`/rest/api/v1/users/${this._getUserId(userId)}/unitcustomers`)
    })
  }

  /**
   * Apply delivery contact to cart.
   */
  setDeliveryContact(data, userId) {
    return this._withDecoratedPromise(() => {
      return this.transport.put(
        `/rest/api/v1/users/${this._getUserId(userId)}/carts/current/delivery/contact`,
        data,
      )
    })
  }

  /**
   * Apply delivery address for cart entry.
   */
  setProductShippingAddress(entryId, data, userId) {
    return this._withDecoratedPromise(() => {
      return this.transport.put(
        `/rest/api/v1/users/${this._getUserId(
          userId,
        )}/carts/current/entries/${entryId}/addresses/delivery`,
        data,
      )
    })
  }

  /**
   * Remove delivery address from cart entry.
   */
  deleteProductShippingAddress(entryId, data, userId) {
    return this._withDecoratedPromise(() => {
      return this.transport.delete(
        `/rest/api/v1/users/${this._getUserId(
          userId,
        )}/carts/current/entries/${entryId}/addresses/delivery`,
        data,
      )
    })
  }

  /**
   * Apply delivery contact for cart entry.
   */
  setProductDeliveryContact(entryId, data, userId) {
    return this._withDecoratedPromise(() => {
      return this.transport.put(
        `/rest/api/v1/users/${this._getUserId(
          userId,
        )}/carts/current/entries/${entryId}/contact/delivery`,
        data,
      )
    })
  }

  /**
   * Place quote.
   */
  placeQuote(data, userId) {
    return this._withDecoratedPromise(() => {
      return this.transport.post(
        `/rest/api/v1/users/${this._getUserId(userId)}/carts/current/quote`,
        data,
      )
    })
  }

  /**
   * Place punchout quote.
   */
  placePunchoutQuote(userId) {
    return this._withDecoratedPromise(() => {
      return this.transport.get(
        `/rest/api/v1/users/${this._getUserId(userId)}/carts/current/punchout/cxml/requisition`,
      )
    })
  }

  /**
   * Delete cart for the logged in user.
   */
  deleteCart(userId) {
    return this._withDecoratedPromise(() => {
      return this.transport.delete(`/rest/api/v1/users/${this._getUserId(userId)}/carts/current`)
    })
  }

  /**
   * Set promotion.
   */
  setPromo({ promoCode }, userId) {
    return this._withDecoratedPromise(() => {
      const headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
      }
      return this.transport.put(
        `/rest/api/v1/users/${this._getUserId(userId)}/carts/current/setPromo`,
        promoCode,
        headers,
      )
    })
  }
}

export default Cart
