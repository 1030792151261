import _ from 'lodash'

import ApiBase from '@/lib/api/v1/api-base'

class Cart extends ApiBase {
  constructor(transport) {
    super(transport)
    this.getReserveOrders = this.getReserveOrders.bind(this)
    this.getAllocationDetails = this.getAllocationDetails.bind(this)
    this.exportReserves = this.exportReserves.bind(this)
    this.removeReserve = this.removeReserve.bind(this)
    this.sendReserveQuestion = this.sendReserveQuestion.bind(this)
  }

  getReserveOrders(params, userId) {
    return this._withDecoratedPromise(() => {
      params = _.pickBy(params)
      params = new URLSearchParams(params).toString()
      return this.transport.get(
        `/rest/api/v1/users/${this._getUserId(userId)}/reserved-orders?${params}`,
      )
    })
  }

  getAllocationDetails({ reserveId, entryNumber, lotId }, userId) {
    return this._withDecoratedPromise(() => {
      return this.transport.get(
        `/rest/api/v1/users/${this._getUserId(userId)}/reserved-orders/${encodeURIComponent(
          reserveId,
        )}/entry/${encodeURIComponent(entryNumber)}/lot/${lotId}`,
      )
    })
  }

  exportReserves(data, userId) {
    return this._withDecoratedPromise(() => {
      return this.transport.post(
        `/rest/api/v1/users/${this._getUserId(userId)}/reserved-orders/export`,
        data,
      )
    })
  }

  removeReserve({ reserveId, entryNumber, lotsToRemove }, userId) {
    return this._withDecoratedPromise(() => {
      return this.transport.delete(
        `/rest/api/v1/users/${this._getUserId(userId)}/reserved-orders/${encodeURIComponent(
          reserveId,
        )}/entry/${encodeURIComponent(entryNumber)}`,
        lotsToRemove,
      )
    })
  }

  sendReserveQuestion(payload, userId) {
    return this._withDecoratedPromise(() => {
      return this.transport.post(
        `/rest/api/v1/users/${this._getUserId(userId)}/reserved-orders/question`,
        payload,
      )
    })
  }
}

export default Cart
