<template>
  <svg width="25" height="24" viewBox="0 0 25 24" :fill="color" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.2518 2H14.5871L20.8386 8V20C20.8386 21.1 19.9009 22 18.7548 22H6.24138C5.09527 22 4.16797 21.1 4.16797 20L4.17839 4C4.17839 2.9 5.10569 2 6.2518 2ZM6.2518 4V20H18.7548V9H13.5452V4H6.2518Z"
    />
  </svg>
</template>
<script>
export default {
  name: 'IconFileWithSpace',
  props: {
    color: {
      type: String,
      default: '#000',
    },
  },
}
</script>
