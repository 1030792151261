<template>
  <div v-if="isAsmPanelVisible" class="asm-root">
    <section
      class="asm"
      :class="{
        hidden: !isAsmInterfaceVisible,
        fixed: isAsmLoggedIn && !isAsmEmulation,
      }"
    >
      <AsmLogin v-if="!isAsmLoggedIn" />
      <AsmInterface v-else />
      <div v-if="!isAsmLoggedIn || isAsmEmulation" class="asm-toggle" @click="togglePanel" />
    </section>
    <div v-if="isAsmLoggedIn && !isAsmEmulation" class="asm-blocker" />
    <div v-if="!isAsmInterfaceVisible" class="asm-open" @click="togglePanel" />
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'

import AsmInterface from '@/components/asm/AsmInterface.vue'
import AsmLogin from '@/components/asm/AsmLogin.vue'

export default {
  name: 'AsmPanel',
  components: {
    AsmInterface,
    AsmLogin,
  },
  data() {
    return {
      isAsmInterfaceVisible: false,
    }
  },
  computed: {
    ...mapGetters('asm', ['isAsmLoggedIn', 'isAsmEmulation']),
    ...mapState('asm', ['isAsmPanelVisible']),
  },
  methods: {
    togglePanel() {
      this.isAsmInterfaceVisible = !this.isAsmInterfaceVisible
    },
  },
}
</script>
