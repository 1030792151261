import api from '@/lib/api'

const _defaultState = {
  isNavigationCollapsed: false,
  isWidgetsOverlayActive: false,
  widgets: {},
}
const getDefaultState = () => JSON.parse(JSON.stringify(_defaultState))
const state = getDefaultState()

const mutations = {
  TOGGLE_NAVIGATION_COLLAPSE(state, value) {
    state.isNavigationCollapsed = typeof value === 'boolean' ? value : !state.isNavigationCollapsed
  },
  TOGGLE_WIDGETS_OVERLAY(state, value) {
    state.isWidgetsOverlayActive =
      typeof value === 'boolean' ? value : !state.isWidgetsOverlayActive
  },
  SET_WIDGETS(state, widgets) {
    state.widgets = widgets
  },
  RESET_STATE(state) {
    Object.assign(state, getDefaultState())
  },
}

const actions = {
  toggleWidget({ state, rootGetters, commit }, widgetToSet = {}) {
    const newWidgets = Object.values(state.widgets).map((widget) => ({
      ...widget,
      isActive:
        widget.id === widgetToSet.id && typeof widgetToSet.isActive === 'boolean'
          ? widgetToSet.isActive
          : widget.isActive,
      isOpen:
        widget.id === widgetToSet.id && typeof widgetToSet.isOpen === 'boolean'
          ? widgetToSet.isOpen
          : widget.isOpen,
    }))
    const newWidgetsAsObject = newWidgets.reduce((sum, curr) => {
      sum[curr.id] = curr
      return sum
    }, {})
    api.user.updateUserInfo({
      firstName: rootGetters['session/user'].firstName,
      lastName: rootGetters['session/user'].lastName,
      myAccountWidgets: newWidgets.map((widget) => ({
        name: widget.id,
        open: widget.isOpen,
        active: widget.isActive,
      })),
    })
    commit('SET_WIDGETS', newWidgetsAsObject)
  },
  async initWidgets({ commit, rootGetters }) {
    const widgets = {
      quotes: {
        id: 'quotes',
        title: 'Quotes',
        isActive: true,
        isOpen: false,
      },
      orders: {
        id: 'orders',
        title: 'Orders',
        isActive: true,
        isOpen: false,
      },
      recentlyShipped: {
        id: 'recentlyShipped',
        title: 'Recently Shipped',
        isActive: true,
        isOpen: false,
      },
      upcomingShipments: {
        id: 'upcomingShipments',
        title: 'Upcoming Shipments',
        isActive: true,
        isOpen: false,
      },
      reserves: {
        id: 'reserves',
        title: 'Reserves',
        isActive: true,
        isOpen: false,
      },
      inventorySearch: {
        id: 'inventorySearch',
        title: 'Inventory Search',
        isActive: true,
        isOpen: false,
      },
    }
    const userWidgets = rootGetters['session/user'].myAccountWidgets
    userWidgets.forEach((userWidget) => {
      const correspondingWidget = widgets[userWidget.name]
      if (correspondingWidget) {
        correspondingWidget.isActive = userWidget.active
        correspondingWidget.isOpen = userWidget.open
      }
    })
    await commit('SET_WIDGETS', widgets)
  },
  resetAllAccountPortal({ commit }) {
    commit('RESET_STATE')
    commit('orders/RESET_STATE', null, { root: true })
    commit('quotes/RESET_STATE', null, { root: true })
  },
}

const getters = {
  availableWidgets: (state, getters, rootState) => {
    const filtered = Object.entries(state.widgets).filter(([key]) => {
      return key === 'reserves' ? rootState['reserve-orders'].isReserveOrdersExist : true
    })
    return Object.fromEntries(filtered)
  },
}

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true,
}
