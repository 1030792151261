<template>
  <div :id="compId" :class="className" />
</template>

<script>
import ready from 'document-ready'
import postscribe from 'postscribe'

export default {
  name: 'VueScriptComponent',
  props: {
    script: {
      type: String,
      default: '',
    },
    className: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      compId: Date.now(), // we will use date.now as a soft ID so that users may load multiple vue-script-components without conflicting ids
    }
  },

  mounted() {
    var vm = this
    ready(function () {
      const addEl = new Promise((resolve) => {
        postscribe(`#${vm.compId}`, vm.script, {
          done: function () {},
        })
        resolve()
      })
      addEl
        .then(function () {
          // do nothing
        })
        .catch(() => {})
    })
  },
}
</script>

<style scoped></style>
