export const unEscapeString = (htmlStr) => {
  htmlStr = htmlStr.replace(/&lt;/g, '<')
  htmlStr = htmlStr.replace(/&gt;/g, '>')
  htmlStr = htmlStr.replace(/&amp;/g, '&')
  htmlStr = htmlStr.replace(/&quot;/g, '"')
  htmlStr = htmlStr.replace(/&#39;/g, "'") // eslint-disable-line no-useless-escape
  return htmlStr
}

export const wrapStringWithTag = (string, tag = 'p') => (string ? `<${tag}>${string}</${tag}>` : '')

export const prependStringWithIcon = (string, iconClass = 'icon-info') =>
  string ? `<i class="${iconClass}"></i> ${string}` : ''
