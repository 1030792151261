import ErrService from '@/services/ErrService'

import app from '@/main'
class ApiBase {
  constructor(transport) {
    if (!transport) {
      throw new Error('Api transport cannot be undefined')
    }
    this.transport = transport
  }

  _getUserId(userId) {
    return (
      userId ||
      (app.config.globalProperties.$store.getters['session/isUserLoggedIn'] &&
      app.config.globalProperties.$store.getters['session/user']?.uid
        ? app.config.globalProperties.$store.getters['session/user'].uid
        : 'anonymous')
    )
  }

  _decorateNetworkError(error, options = {}) {
    options = { errorLevel: 'network', ...options }
    return ErrService.decorateError(this.constructor.name, error, options)
  }

  _withDecoratedPromise(cb, options) {
    return new Promise((resolve, reject) => {
      cb()
        .then((response) => {
          resolve(response)
        })
        .catch((reason) => {
          reject(this._decorateNetworkError(reason, options))
        })
    })
  }
}

export default ApiBase
