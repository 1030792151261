<template>
  <input
    v-if="type === 'submit'"
    type="submit"
    :title="title"
    :value="text"
    :disabled="disabled"
    :class="classList"
  />
  <a
    v-else-if="type === 'link'"
    :href="url"
    :class="classList"
    @click.prevent="navigateTo(url)"
    v-html="text"
  />
  <button
    v-else
    :type="type"
    :title="title"
    :disabled="disabled"
    :class="classList"
    @click="$emit('clicked', $event)"
    v-html="text"
  />
</template>
<script>
import navigationMixin from '@/components/common/mixins/navigationMixin'

export default {
  name: 'AppButton',
  mixins: [navigationMixin],
  props: {
    size: {
      type: String,
      default: 'middle',
      validator: (value) =>
        ['large', 'middle', 'sub-middle', 'small', 'sub-small', 'extra-small'].includes(value),
    },
    variant: {
      type: String,
      default: 'primary',
      validator: (value) =>
        [
          'primary',
          'secondary',
          'secondary-inversed',
          'secondary-inversed-mono',
          'only-text',
          'transparent',
          'transparent-white',
          'white',
          'link',
          'link-underline',
          'green',
          'action',
        ].includes(value),
    },
    url: {
      type: String,
      default: '#',
    },
    type: {
      type: String,
      default: 'button',
      validator: (value) => ['submit', 'button', 'link'].includes(value),
    },
    text: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    isDisabledGreyStyle: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['clicked'],
  computed: {
    classList() {
      return [
        'button',
        `button--${this.size}`,
        `button--${this.variant}`,
        this.disabledClass,
        this.type === 'link' ? 'button--inline' : '',
      ]
    },
    disabledClass() {
      if (!this.disabled) return ''
      return this.isDisabledGreyStyle ? 'button--disabled-grey' : 'button--disabled'
    },
  },
}
</script>
