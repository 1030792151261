<template>
  <div
    v-if="queryString"
    class="search-overlay"
    :class="{ 'search-overlay--product': !categories.length }"
  >
    <loader v-if="isLoaderVisible" :inline="true" variant="white" />
    <div class="search-overlay__container">
      <div v-if="categories.length" class="search-overlay__category category">
        <h3 class="text-h3 text-color-navy category__title" v-html="$t('searchOverlay.title')" />
        <div class="category__items-wrapper">
          <p
            v-for="(category, n) in categories"
            :key="`${category.facetTitle}-${n}`"
            class="text-paragraph category__link"
            @click="onCategoryClick(category)"
          >
            <span class="category__name">{{ category.facetTitle }}</span>
            <span v-if="category.facetQty" class="category__qty">{{ category.facetQty }}</span>
          </p>
        </div>
      </div>
      <div class="search-overlay__results results">
        <div class="results__title-wrapper">
          <h4
            class="text-h4 text-color-navy results__title"
            v-html="$t('searchOverlay.resultsTitle', { searchItem: queryString })"
          />
          <a
            v-if="resultsTotal"
            class="text-link text-link--inline results__all-link"
            :href="`/search?string=${queryString}`"
            @click.prevent="navigateTo(`/search?string=${queryString}`)"
            v-html="$t('searchOverlay.resultsLink', { resultsQty: resultsTotal })"
          />
        </div>
        <div class="results__items-wrapper">
          <a
            v-for="(item, n) in results"
            :key="item.objectID"
            class="results__item"
            :href="item.link"
            @click.prevent="onItemClick(item, n)"
          >
            <div class="results__img-wrapper">
              <img v-if="item.img" :src="item.img" :alt="item.imgAlt" class="results__img" />
            </div>
            <div class="results__copy-wrapper">
              <h5 class="text-h4 text-color-navy results__item-title" v-html="item.title" />
              <a
                v-if="item.parent"
                :href="item.parent.link"
                class="text-paragraph results__item-descr"
                @click.stop.prevent="navigateTo(item.parent.link)"
                v-html="item.parent.title"
              />
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex'

import navigationMixin from '@/components/common/mixins/navigationMixin'
import searchComponentMixin from '@/components/common/mixins/searchComponentMixin'
import transformEntriesMixin from '@/components/common/mixins/transformEntriesMixin'

export default {
  name: 'SearchOverlay',
  mixins: [searchComponentMixin, transformEntriesMixin, navigationMixin],
  props: {
    queryString: {
      type: String,
      default: '',
    },
  },
  emits: ['itemClicked'],
  data() {
    return {
      isLoaderVisible: false,
      productsIndexData: {
        nbHits: 0,
        hits: [],
        facets: {},
        queryID: '',
      },
      resourcesIndexData: {
        nbHits: 0,
        hits: [],
        facets: {},
        queryID: '',
      },
      skuProducts: [],
      maxVisibleResultsQty: 4,
      maxVisibleCategoriesQty: 5,
    }
  },
  computed: {
    categories() {
      const facetsBlacklist = ['contentType']
      const facetsToShowAsCaterogies = this.productsIndexData.nbHits
        ? this.productsIndexData.facets
        : this.resourcesIndexData.facets
      const grouped = Object.keys(facetsToShowAsCaterogies).map((facetName) => ({
        facetName,
        facetValues: Object.keys(facetsToShowAsCaterogies[facetName]).map((facetTitle) => ({
          facetTitle,
          facetName,
          facetQty: facetsToShowAsCaterogies[facetName][facetTitle],
          linkFacetsParam: [`(${this.getFacetValueFullName(facetName, facetTitle)})`],
        })),
      }))
      return grouped
        .filter((el) => !facetsBlacklist.includes(el.facetName))
        .map((el) => el.facetValues)
        .flat(2)
        .sort((a, b) => b.facetQty - a.facetQty)
        .slice(0, this.maxVisibleCategoriesQty)
    },
    results() {
      const results = [...this.skuProducts]
      const remainingSpaceForProducts = this.maxVisibleResultsQty - results.length
      const productsToAddInResults = this.productsIndexData.hits.slice(0, remainingSpaceForProducts)
      results.push(...productsToAddInResults)
      const remainingSpaceForResources = this.maxVisibleResultsQty - results.length
      if (remainingSpaceForResources > 0) {
        const resourcesToAddInResults = this.resourcesIndexData.hits.slice(
          0,
          remainingSpaceForResources,
        )
        results.push(...resourcesToAddInResults)
      }
      return this.transformEntries(results)
    },
    resultsTotal() {
      return (
        this.skuProducts.length + this.productsIndexData.nbHits + this.resourcesIndexData.nbHits
      )
    },
  },
  watch: {
    queryString: {
      handler() {
        this.doSearch()
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions('product', ['setProductAnalyticsData']),
    onCategoryClick(category) {
      this.$emit('itemClicked')
      this.$router.push({
        name: 'search',
        query: { facets: category.linkFacetsParam, string: this.queryString },
      })
    },
    onItemClick(item, position) {
      if (item.entryType !== 'skuProduct') {
        const queryID =
          item.entryType === 'product' || item.entryType === 'page'
            ? this.productsIndexData.queryID
            : this.resourcesIndexData.queryID
        this.sendSearchTracking(item, item.objectID, position, queryID)
      }
      this.setProductAnalyticsData({ navigatedFromPageTitle: 'Direct PGP' })
      this.navigateTo(item.link)
    },
    async doSearch() {
      try {
        this.isLoaderVisible = true
        const results = await Promise.all([
          this.fetchSkuProduct(this.queryString),
          this.fetchResults({
            query: this.queryString,
            hitsPerPage: 4,
            facets: '*',
            filters: 'NOT types.lvl0:"Custom Product"',
          }),
        ])
        this.skuProducts = results[0] ? [results[0]] : []
        this.productsIndexData.nbHits = results[1].nbHits
        this.productsIndexData.hits = results[1].hits
        this.productsIndexData.facets = results[1].facets

        let resourcesNbHits = 0
        let resourcesHits = []
        let resourcesFacets = {}
        const totalResults = this.skuProducts.length + this.productsIndexData.hits.length
        if (totalResults < this.maxVisibleResultsQty) {
          const { nbHits, hits, facets } = await this.fetchResources({
            query: this.queryString,
            hitsPerPage: this.maxVisibleResultsQty - totalResults,
            facets: '*',
            filters:
              'NOT types.lvl0:"Custom Product" AND NOT resourceGroup:"Press Releases" AND NOT resourceGroup:"Events"',
          })
          resourcesNbHits = nbHits
          resourcesHits = hits
          resourcesFacets = facets
        }
        this.resourcesIndexData.nbHits = resourcesNbHits
        this.resourcesIndexData.hits = resourcesHits
        this.resourcesIndexData.facets = resourcesFacets
      } finally {
        this.isLoaderVisible = false
      }
    },
  },
}
</script>
