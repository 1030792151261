<template>
  <ul
    v-if="items.length"
    :class="[
      `benefit-items-list--${itemsPerRow}`,
      { 'benefit-items-list--numbers-bar': isNumbersBarView },
    ]"
    class="benefit-items-list"
  >
    <li v-for="item in items" :key="item.title" class="benefit-items-list__item">
      <template v-if="isNumbersBarView">
        <span class="benefit-items-list__label">
          <RichRenderer :richTextData="item.subtitle" />
        </span>
        <span class="benefit-items-list__value" v-html="item.link"></span>
      </template>
      <template v-else>
        <a class="text-color-navy benefit-items-list__title text-h3" :href="item.link">{{
          item.title
        }}</a>
        <div class="benefit-items-list__subtitle">
          <RichRenderer :richTextData="item.subtitle" />
        </div>
        <img class="benefit-items-list__image" :src="item.image" :alt="item.title" />
      </template>
    </li>
  </ul>
</template>
<script>
export default {
  props: {
    classList: {
      type: Array,
      default: () => [],
    },
    items: {
      type: Array,
      default: () => [],
    },
    isNumbersBarView: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    itemsPerRow() {
      return this.items.length >= 5 ? 5 : this.items.length
    },
  },
}
</script>
