import { createI18n } from 'vue-i18n'

import en from './en-US'

import settings from '@/settings'

const locale = 'en-US'
let messages = {}
const numberFormats = {}

if (!('i18n' in settings) || !('locales' in settings.i18n)) {
  console.error('i18n settings are missing in settings.')
  messages = {
    en,
  }
} else {
  for (const l in settings.i18n.locales) {
    let strings = {}
    switch (l) {
      case 'en-US':
        strings = en
        break

      default:
        console.error(`Locale ${settings.i18n.locales[l]} not found!`)
    }

    messages[l] = strings

    if ('currency' in strings) {
      numberFormats[l] = {
        currency: strings.currency,
      }
    }
  }
}

const i18n = createI18n({
  locale,
  messages,
  numberFormats,
  legacy: false,
})

const locales = Object.keys(messages)

export { i18n, locales }
