import { STATUS } from '@/lib/utils/statuses'

const DEFAULT_STATE = {
  bioVTAdmin: false,
  companyAdmin: false,
  departmentMember: false,
}

const getDefaultState = () => JSON.parse(JSON.stringify(DEFAULT_STATE))
const state = getDefaultState()

const mutations = {
  SET_ROLES(state, roles) {
    Object.assign(state, roles)
  },
  RESET_ROLES(state) {
    Object.assign(state, getDefaultState())
  },
}

const actions = {
  setRoles({ commit }, { itsSuperAdmin, itsRole }) {
    commit('RESET_ROLES')
    const roles = {
      bioVTAdmin: itsSuperAdmin,
      companyAdmin: itsRole === STATUS.COMPANY_ADMIN,
      departmentMember: itsRole === STATUS.DEPARTMENT_MEMBER,
    }
    commit('SET_ROLES', roles)
  },
  resetRoles({ commit }) {
    commit('RESET_ROLES')
  },
}

const getters = {
  hasAtLeastOneRole: (state) => state.companyAdmin || state.bioVTAdmin || state.departmentMember,
  isUserSuperAdmin: (state) => state.bioVTAdmin,
  isUserAdmin: (state) => state.bioVTAdmin || state.companyAdmin,
}

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true,
}
