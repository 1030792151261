import ApiBase from '@/lib/api/v1/api-base'

class Auth extends ApiBase {
  /**
   * Initialize a new instance of the Auth class.
   * @param transport
   * @param apiKey
   */
  constructor(transport, apiKey) {
    super(transport)
    this.apiKey = apiKey
    this.initSession = this.initSession.bind(this)
    this.validateApiKey = this.validateApiKey.bind(this)
    this.login = this.login.bind(this)
    this.register = this.register.bind(this)
    this.sendResetEmail = this.sendResetEmail.bind(this)
    this.sendResetPassword = this.sendResetPassword.bind(this)
    this.checkResetPasswordLinkExpiration = this.checkResetPasswordLinkExpiration.bind(this)
    this.updateUserPassword = this.updateUserPassword.bind(this)
    this.logout = this.logout.bind(this)
  }

  /**
   * Connect to the API to obtain session cookie and get session ID.
   * @returns {Promise<any>}
   */
  initSession() {
    return this._withDecoratedPromise(() => {
      return this.validateApiKey()
    })
  }

  /**
   * Validate API key.
   * @returns {*}
   */
  validateApiKey() {
    const data = `username=${this.apiKey}`
    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded',
    }
    return this.transport.post(
      '/j_spring_security_check',
      data,
      headers,
      {},
      { checkSession: false },
    )
  }

  /**
   * Authorize user and obtain refresh and access tokens.
   * @param username
   * @param password
   * @returns {*|AxiosPromise<any>|void}
   */
  login(username, password) {
    return this._withDecoratedPromise(
      () => {
        const data = {
          username,
          password,
        }
        return this.transport.post('/rest/api/v1/users/authenticate', data)
      },
      { showNotification: false },
    )
  }

  register(data) {
    return this._withDecoratedPromise(
      () => {
        return this.transport.post('/rest/api/v1/users/registration', data)
      },
      { showNotification: false },
    )
  }

  sendResetEmail(email) {
    return this._withDecoratedPromise(() => {
      return this.transport.post(
        `/rest/api/v1/forgottenpasswordtokens?userId=${encodeURIComponent(email)}`,
      )
    })
  }

  sendResetPassword(data) {
    return this._withDecoratedPromise(() => {
      return this.transport.post('/rest/api/v1/resetpassword', data)
    })
  }

  checkResetPasswordLinkExpiration(token) {
    return this._withDecoratedPromise(() => {
      return this.transport.post(`/rest/api/v1/verifytoken?token=${encodeURIComponent(token)}`)
    })
  }

  updateUserPassword(data, userId) {
    const { uid, ...payload } = data
    return this._withDecoratedPromise(() => {
      return this.transport.put(
        `/rest/api/v1/users/${this._getUserId(uid || userId)}/password`,
        payload,
      )
    })
  }

  /**
   * Perform a logout request.
   * @returns {*}
   */
  logout() {
    return this.transport.post(
      '/j_spring_security_logout',
      {},
      {},
      {},
      { checkSession: false, trackRequest: false },
    )
  }
}

export default Auth
