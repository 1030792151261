<template>
  <div class="modal" :class="{ show: isShow }" @mousedown.self="onOverlayClick($event)">
    <div class="modal__container">
      <i v-if="showCloseIcon" class="icon-close modal__close" @mousedown="close" />
      <slot />
      <loader v-if="isLoading" :inline="true" />
    </div>
  </div>
</template>
<script>
export default {
  name: 'Modal',
  props: {
    isClosable: {
      type: Boolean,
      default: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    isOverflowHidden: {
      type: Boolean,
      default: true,
    },
    showCloseIcon: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['close'],
  data() {
    return {
      isShow: false,
    }
  },
  created() {
    setInterval(() => {
      this.isShow = true
    }, 1)
  },
  mounted() {
    if (this.isOverflowHidden) document.body.classList.add('overflow-hidden')
  },
  beforeUnmount() {
    if (this.isOverflowHidden) document.body.classList.remove('overflow-hidden')
  },
  methods: {
    close(e) {
      if (this.isClosable) this.$emit('close', e)
    },
    onOverlayClick(e) {
      this.close(e)
    },
  },
}
</script>
