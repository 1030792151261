import { PAGETYPES } from '@/settings/constants'

const Catalog = () => import('@/components/Catalog.vue')
const Category = () => import('@/components/Category.vue')
const Subcategory = () => import('@/components/Subcategory.vue')
const Resources = () => import('@/components/resources/Resources.vue')
const ProductDetails = () => import('@/components/pdp/ProductDetails.vue')
const LandingPage = () => import('@/components/LandingPage.vue')

export default {
  product: {
    className: 'product-details',
    component: ProductDetails,
  },
  catalog: {
    className: 'catalog-page',
    component: Catalog,
    pageTypes: [PAGETYPES.CATALOG_PRODUCTS],
  },
  category: {
    className: 'category-page',
    component: Category,
    pageTypes: [
      PAGETYPES.CATEGORY_TYPE,
      PAGETYPES.CATEGORY_SPECIES,
      PAGETYPES.CATEGORY_DESEASE_FOCUS,
      PAGETYPES.CATEGORY_APPLICATION,
      PAGETYPES.CATEGORY_INDUSTRIES,
    ],
  },
  subcategory: {
    className: 'subcategory-page',
    component: Subcategory,
    pageTypes: [
      PAGETYPES.SUBCATEGORY_SUBTYPE,
      PAGETYPES.SUBCATEGORY_SPECIES,
      PAGETYPES.SUBCATEGORY_DESEASE_FOCUS,
    ],
  },
  resource: {
    className: 'resources',
    component: Resources,
    pageTypes: [
      PAGETYPES.CATALOG_RESOURCES,
      PAGETYPES.CATEGORY_RESOURCE_ISOLATION_CALENDAR,
      PAGETYPES.CATEGORY_RESOURCE_INVENTORY,
      PAGETYPES.CATEGORY_RESOURCE_EVENTS,
      PAGETYPES.CATEGORY_RESOURCE_PRESS_RELEASES,
      PAGETYPES.CATEGORY_RESOURCE_BLOGS,
      PAGETYPES.CATEGORY_RESOURCE_NEWSLETTER,
      PAGETYPES.CATEGORY_RESOURCE_ARTICLES,
      PAGETYPES.CATEGORY_RESOURCE_EDUCATIONAL_CONTENT,
      PAGETYPES.CATEGORY_RESOURCE_PRODUCTS,
      PAGETYPES.CATEGORY_RESOURCE_PUBLICATIONS,
    ],
  },
  landingPage: {
    className: 'landing-page',
    component: LandingPage,
    pageTypes: [PAGETYPES.LANDING_PAGE],
  },
}
