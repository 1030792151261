import _ from 'lodash'

import ApiBase from '@/lib/api/v1/api-base'

class InventoryTracking extends ApiBase {
  constructor(transport) {
    super(transport)
    this.getInventoryThresholds = this.getInventoryThresholds.bind(this)
    this.createInventoryThreshold = this.createInventoryThreshold.bind(this)
    this.updateInventoryThreshold = this.updateInventoryThreshold.bind(this)
    this.validateThreshold = this.validateThreshold.bind(this)
    this.getInventoryLogs = this.getInventoryLogs.bind(this)
    this.addNewOrderTemplate = this.addNewOrderTemplate.bind(this)
    this.updateOrderTemplate = this.updateOrderTemplate.bind(this)
    this.getDepartments = this.getDepartments.bind(this)
    this.getCompanySkus = this.getCompanySkus.bind(this)
  }

  getInventoryThresholds(params, userId) {
    return this._withDecoratedPromise(() => {
      params = _.pickBy(params)
      params = new URLSearchParams(params).toString()
      return this.transport.get(
        `/rest/api/v1/users/${this._getUserId(userId)}/its/inventory/threshold?${params}`,
      )
    })
  }

  getCurrentInventory(params, userId) {
    return this._withDecoratedPromise(() => {
      params = _.pickBy(params)
      params = new URLSearchParams(params).toString()
      return this.transport.get(
        `/rest/api/v1/users/${this._getUserId(userId)}/its/inventory?${params}`,
      )
    })
  }

  createInventoryThreshold(thresholdData, userId) {
    return this._withDecoratedPromise(() => {
      return this.transport.post(
        `/rest/api/v1/users/${this._getUserId(userId)}/its/inventory/threshold`,
        thresholdData,
      )
    })
  }

  updateInventoryThreshold(thresholdData, userId) {
    return this._withDecoratedPromise(() => {
      return this.transport.put(
        `/rest/api/v1/users/${this._getUserId(userId)}/its/inventory/threshold`,
        thresholdData,
      )
    })
  }

  validateThreshold(thresholdData, userId) {
    return this._withDecoratedPromise(() => {
      return this.transport.put(
        `/rest/api/v1/users/${this._getUserId(userId)}/its/inventory/validate/threshold`,
        thresholdData,
      )
    })
  }

  getInventoryLogs(params, userId) {
    return this._withDecoratedPromise(() => {
      params = _.pickBy(params)
      params = new URLSearchParams(params).toString()
      return this.transport.get(
        `/rest/api/v1/users/${this._getUserId(userId)}/its/inventory/log?${params}`,
      )
    })
  }

  getOrderTemplates(params, userId) {
    return this._withDecoratedPromise(() => {
      params = _.pickBy(params)
      params = new URLSearchParams(params).toString()
      return this.transport.get(
        `/rest/api/v1/users/${this._getUserId(userId)}/its/order-template?${params}`,
      )
    })
  }

  addNewOrderTemplate(orderTemplateData, userId) {
    return this._withDecoratedPromise(() => {
      return this.transport.post(
        `/rest/api/v1/users/${this._getUserId(userId)}/its/order-template`,
        orderTemplateData,
      )
    })
  }

  editCurrentInventory({ quantity, departmentId, inventoryId }, userId) {
    return this._withDecoratedPromise(() => {
      return this.transport.patch(
        `/rest/api/v1/users/${this._getUserId(
          userId,
        )}/its/department/${departmentId}/id/${inventoryId}`,
        quantity,
      )
    })
  }

  updateOrderTemplate(orderTemplateData, userId) {
    return this._withDecoratedPromise(() => {
      return this.transport.put(
        `/rest/api/v1/users/${this._getUserId(userId)}/its/order-template`,
        orderTemplateData,
      )
    })
  }

  validateOrderTemplate(orderTemplateData, userId) {
    return this._withDecoratedPromise(() => {
      return this.transport.put(
        `/rest/api/v1/users/${this._getUserId(userId)}/its/validate/order-template`,
        orderTemplateData,
      )
    })
  }

  getDepartments(params, userId) {
    return this._withDecoratedPromise(() => {
      params = _.pickBy(params)
      params = new URLSearchParams(params).toString()
      return this.transport.get(
        `/rest/api/v1/users/${this._getUserId(userId)}/its/department?${params}`,
      )
    })
  }

  getCompanySkus(companyId, userId) {
    return this._withDecoratedPromise(() => {
      return this.transport.get(
        `/rest/api/v1/users/${this._getUserId(userId)}/its/company/${encodeURIComponent(
          companyId,
        )}/sku`,
      )
    })
  }

  downloadCurrentInventory(params, userId) {
    const requestOptions = {
      responseType: 'arraybuffer',
    }
    return this._withDecoratedPromise(() => {
      return this.transport.get(
        `/rest/api/v1/users/${this._getUserId(userId)}/its/inventory/export?searchTerm=${params}`,
        undefined,
        null,
        requestOptions,
      )
    })
  }

  downloadInventoryLog(params, userId) {
    const requestOptions = {
      responseType: 'arraybuffer',
    }
    return this._withDecoratedPromise(() => {
      return this.transport.get(
        `/rest/api/v1/users/${this._getUserId(userId)}/its/inventory/log/export?${params}`,
        undefined,
        null,
        requestOptions,
      )
    })
  }
}

export default InventoryTracking
