import { createStore as _createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import modules from '@/store/modules/index'

export function createStore(router) {
  return _createStore({
    modules: {
      ...modules,
      route: {
        state: {
          get route() {
            return router.currentRoute.value
          },
        },
        namespaced: true,
      },
    },
    plugins: [
      createPersistedState({
        reducer: (state) => {
          const filteredCart = { ...state.cart }
          delete filteredCart.editableEntry

          const filteredCartFlow = { ...state.cartFlow }
          const filteredSession = { ...state.session }
          delete filteredSession.deliveryContacts
          delete filteredSession.countries

          const filteredAsm = { ...state.asm }
          const filteredPunchout = { ...state.punchout }
          const filteredRoles = { ...state.roles }

          const filteredApp = { ...state.app }
          const filteredAccountPortal = { ...state['account-portal'] }

          return {
            session: filteredSession,
            cart: filteredCart,
            asm: filteredAsm,
            punchout: filteredPunchout,
            cartFlow: filteredCartFlow,
            roles: filteredRoles,
            app: {
              isFooterQuoteVisible: filteredApp.isFooterQuoteVisible,
              isEmergencyPopupVisible: filteredApp.isEmergencyPopupVisible,
            },
            ['account-portal']: {
              isNavigationCollapsed: filteredAccountPortal.isNavigationCollapsed,
              widgets: filteredAccountPortal.widgets,
            },
          }
        },
      }),
    ],
  })
}
