import dayjs from 'dayjs'

export const exctactZoneOffset = (dateString = '') => {
  if (!dateString) return ''
  const timePart = dateString.split('T')[1]
  if (!timePart) return ''
  const isMinus = timePart.includes('-')
  const isPlus = timePart.includes('+')
  const getRemainingSubstring = (str, char) => {
    const index = str.indexOf(char)
    return index !== -1 ? str.substring(index) : ''
  }
  return isMinus
    ? getRemainingSubstring(timePart, '-')
    : isPlus
      ? getRemainingSubstring(timePart, '+')
      : ''
}

export const convertDateToZone = (dateString, zoneOffset = '', format = 'M/D/YY') => {
  return dayjs(dateString).utcOffset(zoneOffset).format(format)
}

export const convertDateToItsZone = (dateString, format = 'M/D/YY') => {
  const zoneOffset = exctactZoneOffset(dateString)
  return convertDateToZone(dateString, zoneOffset, format)
}

export const isEventExpired = (date, additionalDate) => {
  const isStartDateExists = !!date
  if (!isStartDateExists) return

  const isEndDateExists = !!additionalDate
  const startDateZoneOffset = exctactZoneOffset(date)
  const endDateZoneOffset = exctactZoneOffset(additionalDate)
  const isStartDatePast = dayjs().utcOffset(startDateZoneOffset).isAfter(date, 'day')
  const isEndDatePast = dayjs().utcOffset(endDateZoneOffset).isAfter(additionalDate, 'day')
  return isEndDateExists ? isEndDatePast : isStartDatePast
}

export const isDateExpiring = (date, deadlineDays = 7) => {
  const today = dayjs()
  const calcDate = dayjs(date)
  const diff = calcDate.diff(today, 'day', true)
  return diff < deadlineDays
}
