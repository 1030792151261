export default {
  pageToTop() {
    return this.scrollTo(0)
  },
  scrollTo(scrollTop) {
    // This implementation addresses the issue where scrolling occasionally halts midway through the process,
    // resulting in the final position not aligning with the expected value in the scrollTop property.
    return new Promise((resolve) => {
      let scrollPosition = 0
      const stickyHeaderTriggerPosition = 200
      const stickyHeaderHeight =
        document.querySelector('#sticky-header')?.getBoundingClientRect()?.height || 70
      scrollPosition =
        scrollTop > stickyHeaderTriggerPosition ? scrollTop - stickyHeaderHeight : scrollTop
      scrollPosition = Math.round(scrollPosition)
      if (scrollPosition < 0) scrollPosition = 0
      const isPositionWillChange = scrollPosition !== document.documentElement.scrollTop
      if (isPositionWillChange) {
        const onScrollEnd = () => {
          window.removeEventListener('scrollend', onScrollEnd)
          const isScrollSuccess = scrollPosition === document.documentElement.scrollTop
          if (isScrollSuccess) {
            resolve()
          } else {
            window.scrollTo({
              top: scrollPosition,
              behavior: 'instant',
            })
            setTimeout(() => {
              resolve()
            }, 1)
          }
        }
        window.addEventListener('scrollend', onScrollEnd)
        window.scrollTo({
          top: scrollPosition,
          behavior: 'smooth',
        })
      } else {
        resolve()
      }
    })
  },
  async scrollToElement(element) {
    if (!element || typeof element.getBoundingClientRect !== 'function') return
    const scrollPosition = element.getBoundingClientRect().top + document.documentElement.scrollTop
    await this.scrollTo(scrollPosition)
  },
  toElement(selector, offset = 0) {
    const element = document.querySelector(selector)
    if (!element || !element.scrollIntoView) {
      this.pageToTop()
    } else {
      this.scrollTo(element.offsetTop + offset)
    }
  },
  scrollElementInView(element, options = {}) {
    if (element && typeof element.scrollIntoView === 'function') {
      element.scrollIntoView({ behavior: 'smooth', ...options })
    }
  },
}
