/* eslint-disable no-undef */
'use strict'

import contentfulMap from '@/settings/contentful/global-autowire-map'
import defaultSettings from '@/settings/global'

const settings = { ...defaultSettings }

// Override specific settings from environment variables
settings.services.api.apiKey = import.meta.env.VITE_API_KEY || settings.services.api.apiKey
settings.services.api.baseUrl = import.meta.env.VITE_API_BASEURL || settings.services.api.baseUrl
settings.services.api.sessionTtl =
  import.meta.env.VITE_API_SESSION_TTL || settings.services.api.sessionTtl
settings.services.contentful.space.id =
  import.meta.env.VITE_CONTENTFUL_SPACE_ID || settings.services.contentful.space.id
settings.services.contentful.space.name =
  import.meta.env.VITE_CONTENTFUL_SPACE_NAME || settings.services.contentful.space.name
settings.services.contentful.space.environment =
  import.meta.env.VITE_CONTENTFUL_SPACE_ENV || settings.services.contentful.space.environment
settings.services.contentful.accessToken =
  import.meta.env.VITE_CONTENTFUL_ACCESS_TOKEN || settings.services.contentful.accessToken
settings.services.contentful.host =
  import.meta.env.VITE_CONTENTFUL_HOST || settings.services.contentful.host
settings.services.contentful.previewHost =
  import.meta.env.VITE_CONTENTFUL_PREVIEW_HOST || settings.services.contentful.previewHost
settings.services.algolia.appId =
  import.meta.env.VITE_ALGOLIA_APPID || settings.services.algolia.appId
settings.services.algolia.accessToken =
  import.meta.env.VITE_ALGOLIA_ACCESS_TOKEN || settings.services.algolia.accessToken
settings.services.algolia.primaryIndex =
  import.meta.env.VITE_ALGOLIA_PRIMARY_INDEX || settings.services.algolia.primaryIndex
settings.services.algolia.primaryIndexAlphaAsc =
  import.meta.env.VITE_ALGOLIA_PRIMARY_INDEX_ALPHA_ASC ||
  settings.services.algolia.primaryIndexAlphaAsc
settings.services.algolia.primaryIndexAlphaDesc =
  import.meta.env.VITE_ALGOLIA_PRIMARY_INDEX_ALPHA_DESC ||
  settings.services.algolia.primaryIndexAlphaDesc
settings.services.algolia.primaryIndexFeatured =
  import.meta.env.VITE_ALGOLIA_PRIMARY_INDEX_FEATURED ||
  settings.services.algolia.primaryIndexFeatured
settings.services.algolia.resourceIndex =
  import.meta.env.VITE_ALGOLIA_RESOURCE_INDEX || settings.services.algolia.resourceIndex
settings.services.algolia.resourceIndexAlphaAsc =
  import.meta.env.VITE_ALGOLIA_RESOURCE_INDEX_ALPHA_ASC ||
  settings.services.algolia.resourceIndexAlphaAsc
settings.services.algolia.resourceIndexAlphaDesc =
  import.meta.env.VITE_ALGOLIA_RESOURCE_INDEX_ALPHA_DESC ||
  settings.services.algolia.resourceIndexAlphaDesc
settings.services.algolia.productIndex =
  import.meta.env.VITE_ALGOLIA_PRODUCT_INDEX || settings.services.algolia.productIndex
settings.services.algolia.productIndexAlphaAsc =
  import.meta.env.VITE_ALGOLIA_PRODUCT_INDEX_ALPHA_ASC ||
  settings.services.algolia.productIndexAlphaAsc
settings.services.algolia.productIndexAlphaDesc =
  import.meta.env.VITE_ALGOLIA_PRODUCT_INDEX_ALPHA_DESC ||
  settings.services.algolia.productIndexAlphaDesc
settings.services.algolia.inventoryIndex =
  import.meta.env.VITE_ALGOLIA_INVENTORY_INDEX || settings.services.algolia.inventoryIndex
settings.services.algolia.analytics.enabled =
  import.meta.env.VITE_ALGOLIA_ANALYTICS_ENABLED || settings.services.algolia.analytics.enabled
settings.services.google.analyticsToken =
  import.meta.env.VITE_GOOGLE_ANALYTICS_TOKEN || settings.services.google.analyticsToken
settings.services.google.adsToken =
  import.meta.env.VITE_GOOGLE_ADS_TOKEN || settings.services.google.adsToken
settings.services.google.adsLabel =
  import.meta.env.VITE_GOOGLE_ADS_LABEL || settings.services.google.adsLabel
settings.services.google.gtm = import.meta.env.VITE_GOOGLE_GTM || settings.services.google.gtm
settings.services.sentry.env = import.meta.env.VITE_SENTRY_ENV || settings.services.sentry.env
settings.services.sentry.dsn = import.meta.env.VITE_SENTRY_DSN || settings.services.sentry.dsn
settings.services.sentry.enabled =
  import.meta.env.VITE_SENTRY_ENABLED || settings.services.sentry.enabled
settings.services.eightByEight.enabled =
  import.meta.env.VITE_EIGHTBYEIGHT_ENABLED || settings.services.eightByEight.enabled
settings.services.eightByEight.uuid =
  import.meta.env.VITE_EIGHTBYEIGHT_UUID || settings.services.eightByEight.uuid
settings.services.eightByEight.tenant =
  import.meta.env.VITE_EIGHTBYEIGHT_TENANT || settings.services.eightByEight.tenant
settings.services.eightByEight.channel =
  import.meta.env.VITE_EIGHTBYEIGHT_CHANNEL || settings.services.eightByEight.channel
settings.services.authorizeNet.formPostUrl =
  import.meta.env.VITE_AUTHORIZE_NET_FORM_POST_URL || settings.services.authorizeNet.formPostUrl
settings.services.authorizeNet.merchantId =
  import.meta.env.VITE_AUTHORIZE_NET_MERCHANT_ID || settings.services.authorizeNet.merchantId
settings.contentfulMap = contentfulMap

export default settings
