import _ from 'lodash'

export const setCanonicalLink = () => {
  const canonicalLinkId = 'canonicalLink'
  const existingCanonicalLink = document.getElementById(canonicalLinkId)
  if (existingCanonicalLink) existingCanonicalLink.remove()

  const href =
    window.location.pathname === '/'
      ? window.location.origin
      : window.location.pathname === '/search'
        ? window.location.origin + '/search'
        : window.location.href
  const canonicalLink = document.createElement('link')
  canonicalLink.setAttribute('id', canonicalLinkId)
  canonicalLink.setAttribute('rel', 'canonical')
  canonicalLink.setAttribute('href', href)
  document.head.appendChild(canonicalLink)
}

export const setVHStyleVariable = () => {
  const handler = _.debounce(() => {
    const vh = (window.innerHeight * 0.01).toFixed(0)
    document.documentElement.style.setProperty('--vh', `${vh}px`)
  }, 100)
  handler()
  window.addEventListener('resize', handler)
}
