import routes from '@/router/routes'

import { i18n } from '@/i18n'

export default {
  getCaptionByRouteName(routeName) {
    const translationKey = `routes.${routeName}`

    return i18n.global.t(translationKey) || routeName
  },
  getSlug(path, position) {
    const stripped = path.replace(/^\/|\/$/, '')
    return position
      ? stripped.split('/').slice(position - 1, position)[0]
      : stripped.split('/').slice(-1)[0]
  },
  getSlugFromPathMatch(pathMatch, position) {
    const positionToGet = typeof position === 'number' ? position : pathMatch.length - 1
    const slug = pathMatch[positionToGet]
    return slug || pathMatch[pathMatch.length - 1]
  },
  /**
   * Find a route and check whether it's root route or not.
   * @param name
   * @returns {*|boolean}
   */
  isRoot(name) {
    const route = this.find(name)
    return route && 'root' in route && route.root
  },

  /**
   * Get root route.
   * @returns {*}
   */
  getRoot() {
    return routes.find((item) => item.root === true)
  },

  /**
   * Get parent route name.
   * @param name
   * @returns {null}
   */
  getParent(name) {
    const route = this.find(name)
    if (!route) {
      return null
    }
    return 'parent' in route ? route.parent : null
  },

  /**
   * Find route by its name.
   * @param name
   * @returns {*}
   */
  find(name) {
    let result
    routes.forEach((el) => {
      if (el.children) {
        result = el.children.find((item) => item.name === name)
      }
    })
    if (result) {
      return result
    } else {
      return routes.find((item) => item.name === name)
    }
  },

  /**
   * Check whether the given route is public or not.
   * @param name
   * @return {*}
   */
  isPublic(name) {
    const route = this.find(name)
    return route && Object.keys(route).length > 0 && 'publicRoute' in route && route.publicRoute
  },

  /**
   * Check whether the route has setting to hide breadcrumbs or not.
   * @param name
   * @return {*}
   */
  isHideBreadcrumbs(name) {
    const route = this.find(name)
    return (
      route && Object.keys(route).length > 0 && 'hideBreadcrumbs' in route && route.hideBreadcrumbs
    )
  },
}
