/* eslint-disable no-case-declarations */
import _ from 'lodash'

import contentfulComponentMixin from '@/components/common/contentful/mixins/contentfulComponentMixin'

export default {
  mixins: [contentfulComponentMixin],
  methods: {
    transformEntry(entry) {
      if (!entry) return
      const contentType = entry.contentType || this.getContentTypeId(entry)
      const item = entry.fields || entry
      try {
        if (item.objectID) {
          switch (contentType) {
            case 'skuProduct':
              return {
                ...item,
                objectID: item.objectID,
                entryType: 'skuProduct',
                slug: `${item.pgpSlug}/${item.code}`,
                link: `/${item.pgpSlug}/${item.code}`,
                img: item.image
                  ? `${item.image}?fl=progressive&q=70&w=340&h=340`
                  : this.$contentful.service.getProductDefaultImage(),
                title: item.name.trim(),
                parent: {
                  type: null,
                  title: item.title,
                  slug: item.pgpSlug,
                  link: `/${item.pgpSlug}`,
                },
              }
            case 'product':
              return {
                objectID: item.objectID,
                entryType: 'product',
                slug: item.slug,
                link: `/${item.slug}`,
                img: item.image
                  ? `${item.image}?fl=progressive&q=70&w=340&h=340`
                  : this.$contentful.service.getProductDefaultImage(),
                imgAlt: item.imageAlt || item.title,
                title: item.title.trim(),
                subtitle: null,
                description: _.unescape(item.description),
                paragraph: null,
                date: null,
                additionalDate: null,
                payloadUrl: null,
                parent: {
                  type: null,
                  title: item.parentPage?.title,
                  slug: item.parentPage?.slug,
                  link: `/${item.parentPage?.slug}`,
                },
                includes: [],
                isFeatured: item.isFeatured,
                featuredRank: item.featuredRank,
                createdAt: item.createdAt,
                isInventory: item.isInventory,
                relatedProducts: item.relatedProducts?.length
                  ? item.relatedProducts.map((productEntry) => ({
                      title: productEntry.title,
                      link: `/${productEntry.slug}`,
                    }))
                  : null,
                metaDescription: item.metaDescription,
              }
            case 'page':
              return {
                objectID: item.objectID,
                entryType: 'page',
                slug: item.slug,
                link: this.$contentful.service.getPageBySlug(item.slug)?.link || `/${item.slug}`,
                img: item.image
                  ? `${item.image}?fl=progressive&q=70&w=340&h=340`
                  : this.$contentful.service.getProductDefaultImage(),
                imgAlt: item.imageAlt || item.title,
                title: item.title.trim(),
                subtitle: item.subtitle,
                pageType: item.pageType,
                paragraph: null,
                date: null,
                additionalDate: null,
                payloadUrl: null,
                createdAt: item.createdAt,
                metaDescription: item.metaDescription,
              }
            case 'resource':
              const defaultImage = this.$contentful.service.getResourceDefaultImage(
                item.parentPage?.slug,
              )
              let { slug, wrappedSlug, parentSlug } = this.getSlugsForEducationalResource(
                item.parentPage,
                item.title,
                item.objectID,
              )
              let loggedInUrl = item.asset || item.link
              let guestUrl = item.link
              const isBlog = item.resourceGroup === 'Blogs'
              if (isBlog) {
                slug = !item.link ? `blogs/${item.slug}` : item.link
                wrappedSlug = slug
                loggedInUrl = guestUrl = this.$contentful.service.transformSlugToLink(slug)
                parentSlug = this.$contentful.service.getBlogsPage()?.link
              }
              return {
                objectID: item.objectID,
                entryType: 'resource',
                resourceGroup: item.resourceGroup,
                slug: wrappedSlug,
                link: `/${wrappedSlug}`,
                parent: {
                  type: null,
                  title: item.parentPage?.title,
                  slug: parentSlug,
                  link: `/${parentSlug}`,
                },
                isSelfOpenTarget: isBlog,
                loggedInUrl,
                guestUrl,
                isLoginGated: item.isLoginGated,
                img: item.image ? `${item.image}?fl=progressive&q=70` : defaultImage,
                imgAlt: item.imageAlt || item.title,
                title: item.title.trim(),
                subtitle: null,
                description: _.unescape(item.description),
                paragraph: null,
                date: item.date || null,
                additionalDate: item.additionalDate,
                includes: [],
                isFeatured: item.isFeatured,
                featuredRank: item.featuredRank,
                createdAt: item.createdAt,
                author: item.author,
                metaDescription: item.metaDescription,
              }
          }
        } else {
          switch (contentType) {
            case 'product':
              return {
                entryType: 'product',
                slug: item.slug,
                link: `/${item.slug}`,
                img: item.images
                  ? this.getImageUrl(item.images[0], 60, 408, 400)
                  : this.$contentful.service.getProductDefaultImage(),
                imgAlt: item.images ? this.getImageAlt(item.images[0], item.title) : item.title,
                thumbnailImg: item.thumbnailImage ? this.getImageUrl(item.thumbnailImage) : null,
                title: item.title.trim(),
                subtitle: null,
                description: item.description,
                paragraph: item.descriptionRich,
                date: null,
                additionalDate: null,
                payloadUrl: null,
                parent: {
                  type: item.types && item.types.length ? item.types[0].fields?.pageType : null,
                  title: item.types && item.types.length ? item.types[0].fields?.title : null,
                  slug: item.types && item.types.length ? item.types[0].fields?.slug : null,
                  link: item.types && item.types.length ? `/${item.types[0].fields?.slug}` : null,
                },
                includes: item.includes || [],
                isFeatured: item.isFeatured,
                featuredRank: item.featuredRank,
                createdAt: entry.sys.createdAt,
              }
            case 'page':
              return {
                entryType: 'page',
                slug: item.slug,
                link: this.$contentful.service.getPageBySlug(item.slug)?.link || `/${item.slug}`,
                img: item.image
                  ? `${item.image}?fl=progressive&q=70&w=340&h=340`
                  : this.$contentful.service.getProductDefaultImage(),
                imgAlt: item.imageAlt || item.title,
                title: item.title.trim(),
                subtitle: item.subtitle,
                pageType: item.pageType,
                paragraph: null,
                date: null,
                additionalDate: null,
                payloadUrl: null,
                createdAt: item.createdAt,
              }
            case 'resource':
              const defaultImage = this.$contentful.service.getResourceDefaultImage(
                item.resourceGroup.fields.slug,
              )
              let { slug, wrappedSlug, parentSlug } = this.getSlugsForEducationalResource(
                item.resourceGroup.fields,
                item.title,
              )
              const isBlog = item?.resourceGroup?.fields?.slug === 'blogs'
              let loggedInUrl = item.asset?.fields?.file?.url || item.asset || item.link
              let guestUrl = item.link
              if (isBlog) {
                slug = !item.link ? `blogs/${item.slug}` : item.link
                wrappedSlug = slug
                loggedInUrl = guestUrl = this.$contentful.service.transformSlugToLink(slug)
                parentSlug = this.$contentful.service.getBlogsPage()?.link
              }
              return {
                entryType: 'resource',
                resourceGroup: item.resourceGroup?.fields?.title,
                slug: wrappedSlug,
                link: `/${wrappedSlug}`,
                parent: {
                  type: item.resourceGroup?.fields?.pageType,
                  title: item.resourceGroup?.fields?.title,
                  slug: parentSlug,
                  link: `/${parentSlug}`,
                },
                isSelfOpenTarget: isBlog,
                loggedInUrl,
                guestUrl,
                isLoginGated: item.isLoginGated,
                img: this.getImageUrl(item.image, 80) || defaultImage,
                imgAlt: this.getImageAlt(item.image, item.title),
                title: item.title.trim(),
                subtitle: item.subtitle,
                description: item.description,
                paragraph: item.paragraphRich,
                date: item.date || null,
                additionalDate: item.additionalDate || null,
                includes: item.includes || [],
                isFeatured: item.isFeatured,
                featuredRank: item.featuredRank,
                createdAt: entry.sys.createdAt,
              }
          }
        }
      } catch (e) {
        console.log(e)
      }
    },
    transformEntries(entries) {
      if (entries) {
        return entries.map(this.transformEntry).filter((el) => el)
      }
    },
  },
}
