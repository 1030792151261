import api from '@/lib/api'

import AnalyticsService from '@/services/AnalyticsService'

import { transformDeliveryContact } from '@/components/cart/helpers/transformHelpers'

import app from '@/main'

const cartDefaultState = {
  products: [],
  contactInformation: {},
  shippingInformation: {},
  billingInformation: {},
  poNumber: '',
  paymentMethod: '',
  cartNumber: '',
  referenceNumbers: [],
  additionalInformation: '',
  shippingNotificationEmails: [],
  billingNotificationEmails: [],
  summary: {},
  cartEditableEntry: {},
  deliveryContact: {},
  toClear: false,
  inventoryProducts: [],
  promo: null,
  promoError: '',
  isPlaceQuoteAllowed: true,
  submitAsTwo: false,
  isContactFormValid: false,
  isSubmitRegistration: null,
  registrationData: {},
  registrationCompletedData: {},
  isCreditCardAllowed: false,
  isPoAllowed: false,
}
const getDefaults = () => JSON.parse(JSON.stringify(cartDefaultState))
const state = getDefaults()

const mutations = {
  GET_CART(state, { cartData, inventoryData, isLoggedIn }) {
    state.cartNumber = cartData.id
    const products = cartData.entries.map((el) => {
      const isReserveProduct = !!el.reservedOrderLine
      el.deliveryContact = el.deliveryContact.uid
        ? transformDeliveryContact(el.deliveryContact)
        : {}
      el.customizations = el.customizations || []
      if (!isReserveProduct && el.inventory) {
        el.inventory = el.inventory.map((el) => {
          const inventoryDataItem = inventoryData[el.objectID]
          if (inventoryDataItem) el.rollQuantity = inventoryDataItem.rollQuantity
          return el
        })
      }
      return el
    })
    state.products = products

    if (isLoggedIn) {
      state.billingInformation = cartData.billingAddress ? cartData.billingAddress : {}
      state.shippingInformation = cartData.deliveryAddress ? cartData.deliveryAddress : {}
      state.paymentMethod = cartData.paymentType.code
      state.poNumber = cartData.poNumber
      state.shippingNotificationEmails = cartData.deliveryNotificationEmails
      state.billingNotificationEmails = cartData.billingNotificationEmails
      state.deliveryContact = cartData.deliveryContact.uid
        ? transformDeliveryContact(cartData.deliveryContact)
        : {}
      state.promo = cartData.promoCode || null
      state.summary = {
        deliveryCost: cartData.deliveryCost || {},
        packagingCost: cartData.packagingCost || {},
        subTotal: cartData.subTotal,
        totalDiscounts: cartData.totalDiscounts,
        totalPromotionDiscounts: cartData.totalPromotionDiscounts,
        totalPrice: cartData.totalPrice,
        totalPriceWithTax: cartData.totalPriceWithTax,
        totalTax: cartData.totalTax,
        taxError: cartData?.calculationResult?.taxErrorMessage || '',
        productsQty: cartData.entries.length,
        pricedProductsQty: cartData.entries.filter((el) => el.itemNetPrice.value).length,
        nonPricedProductsQty: cartData.entries.filter((el) => !el.itemNetPrice.value).length,
      }
      state.inventoryProducts = products.filter((el) => el.inventory?.some((el) => el.objectID))
      state.promoError =
        cartData?.calculationResult?.errorMessage ||
        cartData?.calculationResult?.warningMessage ||
        ''
      state.isPlaceQuoteAllowed = !!(
        cartData?.calculationResult?.isSuccessful || cartData?.calculationResult?.isEligibleForQuote
      )
      state.isCreditCardAllowed = cartData.payable
      state.isPoAllowed = cartData.poAllowed
    }
  },
  CLEAR_CART(state) {
    Object.assign(state, getDefaults())
  },
  SET_CART_CLEAR(state, toClear) {
    state.toClear = toClear
  },
  SET_CONTACT_INFORMATION(state, contactInformation) {
    state.contactInformation = contactInformation
  },
  SET_SHIPPING_INFORMATION(state, shippingInformation) {
    state.shippingInformation = shippingInformation
  },
  SET_BILLING_INFORMATION(state, billingInformation) {
    state.billingInformation = billingInformation
  },
  SET_ADDITIONAL_INFORMATION(state, additionalInformation) {
    state.additionalInformation = additionalInformation
  },
  SET_PRODUCT_TO_UPDATE(state, cartEditableEntry) {
    state.cartEditableEntry = cartEditableEntry
  },
  SET_REFERENCE_NUMBERS(state, referenceNumbers) {
    state.referenceNumbers = referenceNumbers
  },
  SET_PROMO_ERROR(state, promoError) {
    state.promoError = promoError
  },
  SET_SUBMIT_AS_TWO(state, submitAsTwo) {
    state.submitAsTwo = submitAsTwo
  },
  SET_IS_CONTACT_FORM_VALID(state, isContactFormValid) {
    state.isContactFormValid = isContactFormValid
  },
  SET_IS_SUBMIT_REGISTRATION(state, isSubmitRegistration) {
    state.isSubmitRegistration = isSubmitRegistration
  },
  SET_REGISTRATION_DATA(state, registrationData) {
    state.registrationData = registrationData
  },
  SET_REGISTRATION_COMPLETED_DATA(state, registrationCompletedData) {
    state.registrationCompletedData = registrationCompletedData
  },
}

const actions = {
  async getCart({ commit, rootGetters }, recalculate = true) {
    const { data } = await api.cart.getCart({ recalculate })
    const isContainsInventory = data.entries.some((entry) => entry.inventory?.length)
    let inventoryData = []
    if (isContainsInventory) {
      const loadInventoryData = async () => {
        const toLoad = data.entries.reduce((sum, curr) => {
          if (curr.inventory?.length) {
            sum.push(...curr.inventory.map((el) => el.objectID))
          }
          return sum
        }, [])
        const { hits } = await app.config.globalProperties.$search.service
          .getInventoryIndex()
          .search('', {
            hitsPerPage: 1000,
            filters: toLoad.map((el) => `objectID:${el}`).join(' OR '),
            clickAnalytics: false,
          })
        return hits.reduce((sum, curr) => {
          sum[curr.objectID] = curr
          return sum
        }, {})
      }
      inventoryData = await loadInventoryData()
    }

    await commit('GET_CART', {
      cartData: data,
      inventoryData,
      isLoggedIn: rootGetters['session/isUserLoggedIn'],
    })
  },
  async clearCart({ commit, rootGetters }, userId) {
    if (rootGetters['session/isUserLoggedIn']) {
      await api.cart.deleteCart(userId)
      commit('CLEAR_CART')
    } else {
      commit('CLEAR_CART')
    }
  },
  initCartState({ state, commit, dispatch }) {
    if (state.toClear) {
      commit('CLEAR_CART')
      dispatch('cartFlow/setStep', 'empty', { root: true })
    }
  },
  resetCartStep({ dispatch, getters }) {
    if (!getters.productsQty) dispatch('cartFlow/setStep', 'empty', { root: true })
    else dispatch('cartFlow/setStep', 'view', { root: true })
  },
  setContactInformation({ commit }, contactInformation) {
    commit('SET_CONTACT_INFORMATION', contactInformation)
  },
  async setShippingInformation({ commit, dispatch }, { addressData }) {
    await api.cart.setCartShippingAddressId({ addressId: addressData.id })
    await commit('SET_SHIPPING_INFORMATION', addressData)
    await dispatch('getCart')
  },
  async setBillingInformation({ commit, dispatch }, { addressData }) {
    await api.cart.setCartBillingAddressId({ addressId: addressData.id })
    await commit('SET_BILLING_INFORMATION', addressData)
    await dispatch('getCart', false)
  },
  setAdditionalInformation({ commit }, additionalInformation) {
    commit('SET_ADDITIONAL_INFORMATION', additionalInformation)
  },
  async addShippingNotificationEmail({ state, dispatch }, email) {
    const isDuplicate = state.shippingNotificationEmails.some((el) => el === email)
    if (!email || isDuplicate) return
    const newEmailsList = [...state.shippingNotificationEmails, email]
    await api.cart.updateShippingNotificationEmails(newEmailsList)
    await dispatch('getCart', false)
  },
  async deleteShippingNotificationEmail({ state, dispatch }, email) {
    if (!email) return
    const newEmailsList = state.shippingNotificationEmails.filter((el) => el !== email)
    await api.cart.updateShippingNotificationEmails(newEmailsList)
    await dispatch('getCart', false)
  },
  async addBillingNotificationEmail({ state, dispatch }, email) {
    const isDuplicate = state.billingNotificationEmails.some((el) => el === email)
    if (!email || isDuplicate) return
    const newEmailsList = [...state.billingNotificationEmails, email]
    await api.cart.updateBillingNotificationEmails(newEmailsList)
    await dispatch('getCart', false)
  },
  async deleteBillingNotificationEmail({ state, dispatch }, email) {
    if (!email) return
    const newEmailsList = state.billingNotificationEmails.filter((el) => el !== email)
    await api.cart.updateBillingNotificationEmails(newEmailsList)
    await dispatch('getCart', false)
  },
  setProductToUpdate({ commit }, cartEditableEntry) {
    commit('SET_PRODUCT_TO_UPDATE', cartEditableEntry)
  },
  clearUpdateProduct({ commit }) {
    commit('SET_PRODUCT_TO_UPDATE', {})
  },
  async setDeliveryContact({ dispatch }, deliveryContact) {
    await api.cart.setDeliveryContact({ customerUid: deliveryContact.value })
    await dispatch('getCart', false)
  },
  async setProductShippingAddress({ dispatch }, { entryNumber, shippingAddress }) {
    await api.cart.setProductShippingAddress(entryNumber, {
      addressId: shippingAddress.id,
    })
    await dispatch('getCart', false)
  },
  async deleteProductShippingAddress({ dispatch }, { entryNumber, shippingAddress }) {
    await api.cart.deleteProductShippingAddress(entryNumber, {
      addressId: shippingAddress.id,
    })
    await dispatch('getCart', false)
  },
  async updateProductDeliveryContact({ dispatch }, { entryNumber, deliveryContact }) {
    await api.cart.setProductDeliveryContact(entryNumber, {
      customerUid: deliveryContact.value ? deliveryContact.value : null,
    })
    await dispatch('getCart', false)
  },
  async addProductsToCart({ dispatch, state, rootState }, productsToAdd) {
    const { data: responseEntries } = await api.cart.addProducts(productsToAdd)

    if (rootState.cartFlow.step.value === 'empty')
      dispatch('cartFlow/setStep', 'view', { root: true })
    await dispatch('getCart')

    const addedProductEntries = responseEntries.filter(
      (responseEntry) => responseEntry.statusCode === 'success',
    )
    const addedProductCartEntriesData = addedProductEntries
      .map((addedProductEntry) => {
        const cartEntry = state.products.find(
          (cartEntry) => cartEntry.entryNumber === addedProductEntry.entry.entryNumber,
        )
        return cartEntry ? { ...cartEntry, quantityAdded: addedProductEntry.quantityAdded } : null
      })
      .filter((el) => el)

    if (addedProductCartEntriesData.length) {
      AnalyticsService.sendAddToCartEvent({
        list: rootState.product.analyticsData.navigatedFromPageTitle || 'Direct PGP',
        products: addedProductCartEntriesData.map((cartEntry) => ({
          id: cartEntry.sku,
          name: cartEntry.productName,
          category: cartEntry.productType,
          quantity: cartEntry.quantityAdded,
        })),
      })
    }

    return responseEntries
  },
  async updateCartProduct({ dispatch }, { entryNumber, productData }) {
    await api.cart.updateProduct(entryNumber, productData)
    await dispatch('getCart')
  },
  async deleteProductsFromCart({ dispatch, getters }, entriesToDelete) {
    await api.cart.deleteProducts(entriesToDelete)
    await dispatch('getCart')
    if (!getters.productsQty) {
      dispatch('clearCart')
      dispatch('cartFlow/setStep', 'empty', { root: true })
    }
  },
  async duplicateCartProduct({ dispatch }, entryNumber) {
    await api.cart.duplicateProduct(entryNumber)
    await dispatch('getCart')
  },
  async submitQuote({ commit, state }, { address = {}, quoteCartReminders = false } = {}) {
    const { data } = await api.cart.placeQuote({
      submitAsTwo: state.submitAsTwo,
      quoteCartReminders,
      additionalInformation: state.additionalInformation,
      address,
    })
    await commit(
      'SET_REFERENCE_NUMBERS',
      data.quotes.map((quote) => quote.id),
    )
  },
  async setPromo({ dispatch, commit }, data) {
    try {
      await api.cart.setPromo(data)
      await dispatch('getCart')
    } catch (reason) {
      const errorMessage =
        reason?.response?.data?.message ||
        reason?.response?.data?.[0]?.message ||
        'Something went wrong'
      commit('SET_PROMO_ERROR', errorMessage)
    }
  },
}

const getters = {
  productsQty: (state) => state.products.length,
  pricedProducts: (state) => {
    return state.products.filter(
      (el) => el.totalNetPrice?.value || el.priceDetails?.grossPrice?.value,
    )
  },
  nonPricedProducts: (state) => {
    return state.products.filter((el) => !el.totalNetPrice?.value)
  },
  isPaymentAllowed: (state, getters, rootState, rootGetters) => {
    return (
      !getters.nonPricedProducts.length &&
      !rootGetters['session/user'].isUserProspect &&
      (state.isCreditCardAllowed || state.isPoAllowed)
    )
  },
  isCartGuestView: (state, getters, rootState, rootGetters) =>
    !rootGetters['session/isUserLoggedIn'] || rootGetters['session/user'].isUserProspect,
}

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true,
}
