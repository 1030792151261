import settings from '@/settings'

let routes = [
  {
    path: '/',
    name: 'home',
    root: true,
    skipIdle: true,
    hideBreadcrumbs: true,
    pageTitle: 'routes.home',
    meta: {
      className: 'home',
      isScrollingUp: true,
    },
    component: () => import('@/components/home/Home.vue'),
  },
  {
    path: '/404',
    name: 'not-found',
    pageTitle: 'routes.not-found',
    skipIdle: true,
    hideBreadcrumbs: true,
    meta: {
      className: 'not-found',
    },
    component: () => import('@/components/NotFound.vue'),
  },
  {
    path: '/authentication',
    name: 'authentication',
    pageTitle: 'routes.authentication',
    skipIdle: true,
    meta: {
      guestRoute: true,
      className: 'auth',
      isScrollingUp: true,
    },
    component: () => import('@/components/auth/Authentication.vue'),
  },
  {
    path: '/checkout-authentication',
    name: 'checkout-authentication',
    pageTitle: 'routes.checkout-authentication',
    meta: {
      guestRoute: true,
      className: 'checkout-authentication',
      isScrollingUp: true,
    },
    component: () => import('@/components/auth/CheckoutAuthentication.vue'),
  },
  {
    path: '/logout',
    name: 'logout',
    skipIdle: true,
    meta: {
      logoutRoute: true,
    },
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    skipIdle: true,
    pageTitle: 'routes.reset-password',
    meta: {
      className: 'reset-password',
      guestRoute: true,
    },
    component: () => import('@/components/auth/ResetPassword.vue'),
  },
  {
    path: '/set-password',
    name: 'set-password',
    skipIdle: true,
    pageTitle: 'routes.set-password',
    meta: {
      guestRoute: true,
    },
    component: () => import('@/components/auth/ResetPassword.vue'),
  },
  {
    path: '/settings',
    name: 'settings',
    skipIdle: true,
    pageTitle: 'routes.settings',
    meta: {
      privateRoute: true,
      className: 'account',
      isScrollingUp: true,
    },
    component: () => import('@/components/account-portal/tabs/AccountPortalSettingsTab.vue'),
  },
  {
    path: '/invoices',
    name: 'invoices',
    skipIdle: true,
    pageTitle: 'routes.invoices',
    meta: {
      className: 'orders',
      privateRoute: true,
      isScrollingUp: true,
    },
    component: () => import('@/components/account-portal/tabs/AccountPortalInvoicesTab.vue'),
  },
  {
    path: '/quotes',
    name: 'quoteHistory',
    skipIdle: true,
    pageTitle: 'routes.quoteHistory',
    meta: {
      privateRoute: true,
      isScrollingUp: true,
      className: 'orders',
    },
    component: () => import('@/components/account-portal/tabs/AccountPortalQuotesTab.vue'),
  },
  {
    path: '/quotes/:id',
    name: 'quoteDetails',
    pageTitle: 'routes.quoteDetails',
    parent: 'quoteHistory',
    meta: {
      privateRoute: true,
      className: 'order-details',
      isScrollingUp: true,
      isAfterLoginRedirectToPrevRoute: true,
    },
    component: () => import('@/components/account-portal/tabs/AccountPortalQuoteDetailsTab.vue'),
  },
  {
    path: '/search',
    name: 'search',
    skipIdle: true,
    pageTitle: 'routes.search',
    meta: {
      className: 'search',
    },
    component: () => import('@/components/search/Search.vue'),
  },
  {
    path: '/orders',
    name: 'orderHistory',
    pageTitle: 'routes.orderHistory',
    meta: {
      privateRoute: true,
      className: 'orders',
      isScrollingUp: true,
    },
    component: () => import('@/components/account-portal/tabs/AccountPortalOrdersTab.vue'),
  },
  {
    path: '/orders/:id',
    name: 'orderDetails',
    pageTitle: 'routes.orderDetails',
    parent: 'orderHistory',
    meta: {
      privateRoute: true,
      className: 'order-details',
      isScrollingUp: true,
      isAfterLoginRedirectToPrevRoute: true,
    },
    component: () => import('@/components/account-portal/tabs/AccountPortalOrderDetailsTab.vue'),
  },
  {
    path: '/shipments',
    name: 'shipments',
    pageTitle: 'routes.shipments',
    meta: {
      privateRoute: true,
      className: 'orders',
    },
    component: () => import('@/components/account-portal/tabs/AccountPortalShipmentsTab.vue'),
  },
  {
    path: '/cart',
    name: 'cart',
    pageTitle: 'routes.cart',
    meta: {
      className: 'cart',
      isScrollingUp: true,
    },
    component: () => import('@/components/cart/Cart.vue'),
  },
  {
    path: '/inventory',
    name: 'inventory',
    pageTitle: 'routes.inventory',
    meta: {
      className: 'inventory-page',
      isBackToTopBtn: true,
      isAfterLoginRedirectToPrevRoute: true,
    },
    component: () => import('@/components/inventory/Inventory.vue'),
  },
  {
    path: '/replenishment-program',
    name: 'replenishment-program',
    pageTitle: 'routes.replenishment-program',
    meta: {
      privateRoute: true,
      className: 'inventory-tracking-page',
      crumbs: [
        {
          link: 'dashboard',
          name: 'Dashboard',
        },
        {
          link: 'replenishment-program',
          name: 'BioIVT Replenishment Program',
        },
      ],
    },
    component: () =>
      import('@/components/account-portal/tabs/AccountPortalInventoryTrackingTab.vue'),
  },
  {
    path: '/blogs/:id',
    name: 'blog-details',
    meta: {
      className: 'blog-details',
      isScrollingUp: true,
    },
    component: () => import('@/components/BlogDetails.vue'),
  },
  {
    path: '/preview/:type/:id',
    name: 'preview',
    skipIdle: true,
    component: () => import('@/components/Preview.vue'),
  },
  {
    path: '/ivd-lnl',
    redirect: () => {
      window.location.href = 'https://info.bioivt.com/ivd-lnl-std'
    },
  },
  {
    path: '/hepatocyte',
    redirect: () => {
      window.location.href = 'https://info.bioivt.com/human-hepatocytes-characterization-tables'
    },
  },
  {
    path: '/ukdonorcenter',
    redirect: () => {
      window.location.href = 'https://info.bioivt.com/london-exp'
    },
  },
  {
    path: '/cell-products/renal-cells',
    redirect: '/custom-cell-product',
  },
  {
    path: '/human-renal-proximal-tubule-cells',
    redirect: '/custom-cell-product',
  },
  {
    path: '/short-url/:hash',
    name: 'short-url',
    hideBreadcrumbs: true,
    component: () => import('@/components/ShortUrl.vue'),
  },
  {
    path: '/experts',
    name: 'experts',
    pageTitle: 'routes.experts',
    meta: {
      className: 'experts-page',
      isScrollingUp: true,
    },
    component: () => import('@/components/experts/Experts.vue'),
  },
  {
    path: '/experts/:slug',
    name: 'expert',
    meta: {
      className: 'expert-page',
      isScrollingUp: true,
    },
    component: () => import('@/components/experts/Expert.vue'),
  },
  {
    path: '/ui-kit',
    name: 'ui-kit',
    root: true,
    skipIdle: true,
    hideBreadcrumbs: true,
    pageTitle: 'Ui kit',
    meta: {
      isScrollingUp: true,
    },
    component: () => import('@/components/UiKit.vue'),
  },
  {
    path: '/reserves',
    name: 'reserves',
    skipIdle: true,
    pageTitle: 'routes.reserves',
    meta: {
      className: 'reserves-body',
      privateRoute: true,
      isScrollingUp: true,
    },
    component: () => import('@/components/account-portal/tabs/AccountPortalReservesTab.vue'),
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    skipIdle: true,
    pageTitle: 'routes.dashboard',
    meta: {
      privateRoute: true,
      isScrollingUp: true,
      className: 'dashboard',
    },
    component: () => import('@/components/dashboard/Dashboard.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'redirect',
    skipIdle: true,
    component: () => import('@/components/Redirect.vue'),
  },
]
if (settings.services.sentry.env === 'PRODUCTION')
  routes = routes.filter((route) => route.name !== 'ui-kit')

export default routes
