<template>
  <div class="container error-container">
    <h1>{{ data }}</h1>
  </div>
</template>
<script>
export default {
  name: 'PunchoutCriticalErrorView',
  props: {
    data: {
      type: String,
      default: '',
    },
  },
}
</script>
