import arraySort from 'array-sort'
import dayjs from 'dayjs'

export default {
  sortByFirstTwoWordsOfProp(prop) {
    return function (a, b) {
      if (a[prop].split(' ')[0] === b[prop].split(' ')[0]) {
        return a[prop].split(' ')[1].localeCompare(b[prop].split(' ')[1])
      } else {
        return a[prop].localeCompare(b[prop])
      }
    }
  },
  sortByFeatured(arr) {
    if (arr && arr.length) {
      return arr.sort((a, b) => {
        if (a.isFeatured && b.isFeatured) {
          if (a.featuredRank < b.featuredRank) return -1
          if (a.featuredRank > b.featuredRank) return 1
          return a.title.localeCompare(b.title)
        } else if (a.isFeatured && !b.isFeatured) {
          return -1
        } else if (!a.isFeatured && b.isFeatured) {
          return 1
        } else {
          return a.title.localeCompare(b.title)
        }
      })
    } else {
      return []
    }
  },
  sortObjectsListByAlphaAsc(arr, prop) {
    if (!Array.isArray(arr)) return []

    return arraySort(arr, prop)
  },
  sortObjectsListByAlphaDesc(arr, prop) {
    if (!Array.isArray(arr)) return []

    return arraySort(arr, prop, { reverse: true })
  },
  sortObjectsListByDateAsc(arr, prop) {
    if (!Array.isArray(arr)) return []
    return arr.sort((a, b) => {
      if (b[prop] === a[prop]) return 0
      if (!b[prop]) return 1
      if (!a[prop]) return -1
      return dayjs(a[prop]).diff(dayjs(b[prop]))
    })
  },
  sortObjectsListByDateDesc(arr, prop) {
    if (!Array.isArray(arr)) return []

    return arr.sort((a, b) => {
      if (a[prop] === b[prop]) return 0
      if (!a[prop]) return 1
      if (!b[prop]) return -1
      return dayjs(b[prop]).diff(dayjs(a[prop]))
    })
  },
}
