import api from '@/lib/api'
import { isDateExpiring } from '@/lib/utils/dates'

import { transformReserveOrder } from '@/components/cart/helpers/transformHelpers'

const _defaultState = {
  isReserveOrdersExist: false,
  isExpiringReserveOrdersExistLoaded: false,
  isExpiringReserveOrdersExist: false,
}
const getDefaultState = () => JSON.parse(JSON.stringify(_defaultState))
const state = getDefaultState()

const mutations = {
  SET_IS_RESERVE_ORDERS_EXIST(state, isReserveOrdersExist) {
    state.isReserveOrdersExist = isReserveOrdersExist
  },
  SET_IS_EXPIRING_RESERVE_ORDERS_EXIST(state, isExpiringReserveOrdersExist) {
    state.isExpiringReserveOrdersExist = isExpiringReserveOrdersExist
    state.isExpiringReserveOrdersExistLoaded = true
  },
  RESET_STATE(state) {
    Object.assign(state, getDefaultState())
  },
}

const actions = {
  async getReserveOrders({ rootState }, { params }) {
    const response = await api.reserveOrders.getReserveOrders(params)
    const transformedReserves = response.data.entries.map((el) =>
      transformReserveOrder(el, rootState.cart.inventoryProducts),
    )
    return {
      reserveOrders: transformedReserves,
      pagination: response.data.pagination,
    }
  },
  async setIsReserveOrdersExist({ commit }) {
    const params = { pageSize: 1, showCompanyOrders: true }
    const response = await api.reserveOrders.getReserveOrders(params)
    commit('SET_IS_RESERVE_ORDERS_EXIST', !!response.data.entries.length)
  },
  async setIsExpiringReserveOrdersExist({ commit }) {
    const params = { sort: 'byEndDateASC', pageSize: 1, showCompanyOrders: true }
    const response = await api.reserveOrders.getReserveOrders(params)
    const reserves = response.data.entries
    const isSomeExpiring = reserves.some((reserve) => isDateExpiring(reserve.endDate, 30))
    await commit('SET_IS_EXPIRING_RESERVE_ORDERS_EXIST', isSomeExpiring)
  },
  async getAllocationDetails(context, { reserveId, entryNumber, lotId }) {
    const response = await api.reserveOrders.getAllocationDetails({
      reserveId,
      entryNumber,
      lotId,
    })
    return response.data
  },
  removeReserve(context, payload) {
    return api.reserveOrders.removeReserve(payload)
  },
  resetState({ commit }) {
    commit('RESET_STATE')
  },
}

export default {
  state,
  mutations,
  actions,
  namespaced: true,
}
