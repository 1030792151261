import { createClient as createContentfulClient } from 'contentful'
import _ from 'lodash'

import app from '@/main'
import settings from '@/settings'
import { PAGETYPES } from '@/settings/constants'

export default {
  initDefaultClient() {
    this.isPreviewMode = false
    this.service.rootContext = this
    this.client = createContentfulClient({
      space: settings.services.contentful.space.id,
      environment: settings.services.contentful.space.environment,
      accessToken: settings.services.contentful.accessToken,
      host: settings.services.contentful.host,
    })
  },
  initPreviewClient(previewAccessToken) {
    this.isPreviewMode = true
    this.service.rootContext = this
    this.client = createContentfulClient({
      space: settings.services.contentful.space.id,
      environment: settings.services.contentful.space.environment,
      accessToken: previewAccessToken,
      host: settings.services.contentful.previewHost,
    })
  },
  isPreviewMode: false,
  client: {},
  service: {
    data: {
      catalogs: [],
      productsMeta: [],
      punchout: {},
      settings: {
        redirects: {},
      },
      slots: {},
      eventPageId: '',
    },
    getEventPageId() {
      return this.data.eventPageId
    },
    getPGPConfigurationFieldsMapping() {
      const configEntries = this.data.settings.pgpConfigurationRelationship?.fields?.items || []
      return configEntries
        .filter(
          (el) =>
            el?.fields && el?.sys?.contentType?.sys?.id === 'productOptionsRelationshipWrapper',
        )
        .map((el) => el.fields)
    },
    getSiteCopy(copyGroupId, copyItemId) {
      return this.data.settings.siteCopy?.[copyGroupId]?.[copyItemId]
    },
    getDataForComponent(slotName) {
      return this.data.slots[slotName]
    },
    getProductMetaBySlug(slug) {
      return this.data.productsMeta.find((el) => el.fields?.slug === slug)
    },
    getPagesByPageType(pageType) {
      let cPageCollection = []
      const parseCatalogs = (arr) => {
        cPageCollection = [
          ...cPageCollection,
          ...arr.filter((el) => el.fields?.pageType === pageType),
        ]
        arr.forEach((el) => {
          if (el.fields.subpages) {
            parseCatalogs(el.fields.subpages)
          }
          if (el.fields.subpagesGrouped) {
            parseCatalogs(el.fields.subpagesGrouped)
          }
        })
      }
      parseCatalogs(this.data.settings.catalogs)
      return cPageCollection
    },
    getPageBySlug(slug) {
      let cPage
      const parseCatalogs = (arr) => {
        if (!cPage) {
          cPage = arr.find((el) => el.fields?.slug === slug)
          if (!cPage) {
            arr.forEach((el) => {
              if (el.fields?.subpages) {
                parseCatalogs(el.fields.subpages)
              }
              if (el.fields?.subpagesGrouped) {
                parseCatalogs(el.fields.subpagesGrouped)
              }
            })
          }
        }
      }
      parseCatalogs(this.data.settings.catalogs)
      if (!cPage) cPage = this.data.settings.landingPages?.find((page) => page.slug === slug)
      if (this.rootContext.isPreviewMode && !cPage) parseCatalogs(this.data.settings.allPageEntries)
      return cPage
    },
    getProductDefaultImage() {
      return this.data.settings.productDefaultImage?.fields?.file.url
    },
    getResourceDefaultImage(slug) {
      return (
        this.getPageBySlug(slug)?.fields?.includes?.find(
          (el) => el.sys.contentType?.sys.id === 'resourceDefaultImage',
        )?.fields?.image.fields?.file.url ||
        this.data.settings.productDefaultImage?.fields?.file.url
      )
    },
    getHubspotGlobalTrackerSnippet() {
      return this.data.settings.hubSpotGlobalTracker?.fields?.snippet
    },
    getUniqueRelatedProducts(products, n) {
      const relatedProducts = []
        .concat(products.filter((item) => item.relatedProducts).map((item) => item.relatedProducts))
        .flat()
      if (relatedProducts.length) {
        const uniqueRelatedProducts = _.uniqWith(relatedProducts, _.isEqual)
        if (uniqueRelatedProducts.length > n) {
          const shuffledRelatedProducts = _.shuffle(uniqueRelatedProducts)
          shuffledRelatedProducts.length = n
          return shuffledRelatedProducts
        } else {
          return uniqueRelatedProducts
        }
      } else {
        return null
      }
    },
    getPunchout() {
      return this.data.punchout || {}
    },
    getEducationalContentPage() {
      return this.getPagesByPageType(PAGETYPES.CATEGORY_RESOURCE_EDUCATIONAL_CONTENT).pop()
    },
    getBlogsPage() {
      return this.getPagesByPageType(PAGETYPES.CATEGORY_RESOURCE_BLOGS).pop()
    },
    transformSlugToLink(slug = '') {
      return slug.includes('http') ? slug : `/${slug}`
    },
    isEducationalContent(page) {
      const pageType = page.pageType || page.fields.pageType
      return [
        PAGETYPES.CATEGORY_RESOURCE_INTRUCTIONAL_SHEETS,
        PAGETYPES.CATEGORY_RESOURCE_POSTERS,
        PAGETYPES.CATEGORY_RESOURCE_PROTOCOLS_TUTORIALS,
        PAGETYPES.CATEGORY_RESOURCE_BROCHURES,
        PAGETYPES.CATEGORY_RESOURCE_WEBINARS_VIDEOS,
        PAGETYPES.CATEGORY_RESOURCE_CASE_STUDIES,
        PAGETYPES.CATEGORY_RESOURCE_BLOGS,
      ].includes(pageType)
    },
    extractEntryType(entry) {
      return entry?.sys?.contentType?.sys?.id
    },
    getExpertPage(slug) {
      const expertsPageEntry = this.getDataForComponent('expertsPage')
      return expertsPageEntry
        ? expertsPageEntry.fields?.includes?.find((el) => el?.fields?.slug === slug)
        : null
    },
    getRedirect(from = '') {
      const redirectFrom = from.startsWith('//') ? `https:${from}` : from
      return this.data.settings.redirects?.[redirectFrom] || ''
    },
    async initData(data) {
      this.data = data
      const educationalContentItems = []
      const decoratePages = (arr, parentEl, currentIter = 1) => {
        if (currentIter === 6) return []
        currentIter++
        return arr
          .map((el) => {
            if (el && el.fields) {
              if (el.fields.pageType === PAGETYPES.CATEGORY_RESOURCE_EVENTS)
                this.data.eventPageId = el.sys.id
              if (!el.crumbs) el.crumbs = [el]
              if (!el.link) {
                if (this.isEducationalContent(el)) {
                  educationalContentItems.push(el)
                } else {
                  el.link = this.transformSlugToLink(el.fields.slug)
                  el.slug = el.fields.slug
                }
              }
              const pageTitleAsFacet = el.fields.title?.trim()
              el.pageTitleAsFacet = pageTitleAsFacet
              if (parentEl) {
                el.parent = parentEl
                el.crumbs = [...parentEl.crumbs, ...el.crumbs]
                if (
                  parentEl.fields.slug !== 'products' &&
                  parentEl.fields.slug !== 'disease-focus' &&
                  parentEl.fields.slug !== 'species' &&
                  parentEl.fields.slug !== 'applications' &&
                  parentEl.fields.slug !== 'industry'
                ) {
                  el.pageTitleAsFacet = `${parentEl.pageTitleAsFacet} > ${pageTitleAsFacet}`
                  if (parentEl.link) {
                    el.link = `${parentEl.link}${el.link}`
                    el.slug = el.fields.slug
                  }
                }
              }
              if (el.fields.subpages) {
                el.fields.subpages = decoratePages(el.fields.subpages, el, currentIter)
              }
              if (el.fields.subpagesGrouped) {
                el.fields.subpagesGrouped = decoratePages(
                  el.fields.subpagesGrouped,
                  el,
                  currentIter,
                )
              }
              return el
            }
          })
          .filter((el) => el)
      }
      const initEducationalContent = (arr) => {
        arr.forEach((el) => {
          el.link =
            this.getEducationalContentPage().link +
            `?facets=(resourceGroup:"${encodeURIComponent(el.fields.title)}")`
        })
      }
      const initRichTextLinkInterceptor = () => {
        document.addEventListener('click', (e) => {
          if (
            e.target &&
            e.target.tagName.toLowerCase() === 'a' &&
            Array.from(document.querySelectorAll('.rich-text-wrapper')).some((el) =>
              el.contains(e.target),
            )
          ) {
            const redirectUrl = this.getRedirect(e.target.href)
            if (redirectUrl) {
              window.open(redirectUrl, '_self')
              return
            }
            if (
              ['www.bioivt.com', 'bioivt.com', window.location.hostname].includes(e.target.hostname)
            ) {
              e.stopPropagation()
              e.preventDefault()
              app.config.globalProperties.$emitter.emit('contentful.rich:bio-link', e.target)
            }
          }
        })
      }
      const initSiteCopyData = (settings) => {
        const siteCopyFieldKey = 'siteCopy'
        const siteCopyField = settings[siteCopyFieldKey]
        const transformedSiteCopyField = {}
        const rawCopyGroups = siteCopyField?.fields?.items
        if (!rawCopyGroups?.length) {
          settings[siteCopyFieldKey] = transformedSiteCopyField
          return
        }

        const transformedCopyGroups = rawCopyGroups
          .filter((copyGroupItem) => {
            const entryType = copyGroupItem.sys.contentType?.sys.id
            return (
              entryType === 'blockGeneralItem' ||
              copyGroupItem.fields?.id ||
              copyGroupItem.fields?.items?.length
            )
          })
          .map((copyGroupItem) => {
            const copyItems =
              copyGroupItem.fields.items
                ?.filter((copyItem) => {
                  const entryType = copyItem.sys.contentType?.sys.id
                  return (
                    entryType === 'blockGeneralItem' &&
                    copyItem.fields?.id &&
                    copyItem.fields?.paragraphRich
                  )
                })
                .map((copyItem) => ({
                  copyItemId: copyItem.fields?.id,
                  copyItemValue: copyItem.fields?.paragraphRich,
                })) || []
            return {
              groupId: copyGroupItem.fields?.id,
              copyItems,
            }
          })
          .filter((copyGroupItem) => copyGroupItem.copyItems.length)

        transformedCopyGroups.forEach((copyGroupItem) => {
          transformedSiteCopyField[copyGroupItem.groupId] = copyGroupItem.copyItems.reduce(
            (sum, curr) => {
              sum[curr.copyItemId] = curr.copyItemValue
              return sum
            },
            {},
          )
        })
        settings[siteCopyFieldKey] = transformedSiteCopyField
      }
      const initLandingPages = async (landingPages) => {
        if (!this.rootContext.isPreviewMode && !landingPages) return []
        const toLoad = landingPages.map((el) => el.sys.id)
        const requestConig = this.rootContext.isPreviewMode
          ? {
              skip: 0,
              limit: 999,
              include: 4,
              order: 'sys.createdAt',
              content_type: 'page',
              'fields.pageType': 'Landing Page',
            }
          : {
              skip: 0,
              limit: toLoad.length,
              include: 4,
              order: 'sys.createdAt',
              content_type: 'page',
              'sys.id[in]': toLoad.toString(),
            }

        const { items } = await this.rootContext.client.getEntries(requestConig)
        return items.map((pageEntry) => {
          const transformedEntry = {
            ...pageEntry,
            slug: pageEntry.fields.slug,
            link: this.transformSlugToLink(pageEntry.fields.slug),
          }
          transformedEntry.crumbs = [transformedEntry]
          return transformedEntry
        })
      }
      const initRedirects = (redirectEntries = []) => {
        return redirectEntries
          .filter((el) => el.fields)
          .reduce((sum, curr) => {
            sum[curr.fields.from] = curr.fields.to
            return sum
          }, {})
      }
      try {
        data.settings.catalogs = decoratePages(data.settings.catalogs)
        data.settings.allPageEntries = decoratePages(data.allPageEntries)
        initEducationalContent(educationalContentItems)
        initRichTextLinkInterceptor()
        initSiteCopyData(data.settings)
        data.settings.landingPages = await initLandingPages(data.settings.landingPages)
        data.settings.redirects = initRedirects(data.settings.redirects)
      } catch (e) {
        app.config.globalProperties.$err.sendError(e)
        console.error(e)
      }
    },
  },
}
