<template>
  <div v-if="slides.length" class="image-carousel">
    <div class="container">
      <div class="image-carousel__main">
        <transition name="opacity">
          <img
            v-if="prevSlide"
            :src="prevSlide.image"
            :draggable="false"
            class="image-carousel__side-image image-carousel__side-image--prev"
          />
        </transition>
        <div class="image-carousel__active-image-wrap">
          <transition name="opacity-main">
            <img
              v-if="activeSlide"
              :src="activeSlide.image"
              :draggable="false"
              class="image-carousel__active-image"
            />
          </transition>
          <div v-if="activeSlide && activeSlide.label" class="image-carousel__active-image-label">
            <RichRenderer :richTextData="activeSlide.label" :addWrapClass="false" />
          </div>
          <button
            v-if="isPrevSlideButtonVisible"
            class="image-carousel__nav-button image-carousel__nav-button--prev"
            @click="onPrevClick"
          >
            <i class="icon-arrow" />
          </button>
          <button
            v-if="isNextSlideButtonVisible"
            class="image-carousel__nav-button image-carousel__nav-button--next"
            @click="onNextClick"
          >
            <i class="icon-arrow" />
          </button>
        </div>
        <transition name="opacity">
          <img
            v-if="nextSlide"
            :src="nextSlide.image"
            :draggable="false"
            class="image-carousel__side-image image-carousel__side-image--next"
          />
        </transition>
      </div>
    </div>
  </div>
</template>
<script>
import { nextTick } from 'vue'

export default {
  props: {
    carouselSlides: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      activeSlideIdx: 0,
      activeSlide: null,
      prevSlide: null,
      nextSlide: null,
      autoSlidesChangeInterval: null,
      slides: [],
    }
  },
  computed: {
    isPrevSlideButtonVisible() {
      return !!this.prevSlide
    },
    isNextSlideButtonVisible() {
      return !!this.nextSlide
    },
  },
  created() {
    if (!this.carouselSlides?.length) return
    this.slides = this.carouselSlides.map((el) => ({
      image: el.image,
      label: el.label,
    }))
    if (this.slides.length > 2) this.activeSlideIdx = 1
    if (this.slides.length > 1) this.initAutoSlideChange()
    this.updateSlides()
  },
  beforeUnmount() {
    this.cleaAutoSlideChange()
  },
  methods: {
    onPrevClick() {
      this.cleaAutoSlideChange()
      this.initAutoSlideChange()

      this.activeSlideIdx--
      this.updateSlides()
    },
    onNextClick() {
      this.cleaAutoSlideChange()
      this.initAutoSlideChange()

      this.activeSlideIdx++
      this.updateSlides()
    },
    initAutoSlideChange() {
      const delay = 5000
      this.autoSlidesChangeInterval = setInterval(() => {
        const isNextSlideExists = !!this.slides[this.activeSlideIdx + 1]
        if (isNextSlideExists) {
          this.activeSlideIdx++
          this.updateSlides()
        } else {
          this.activeSlideIdx = 0
          this.updateSlides()
        }
      }, delay)
    },
    cleaAutoSlideChange() {
      if (this.autoSlidesChangeInterval) clearInterval(this.autoSlidesChangeInterval)
    },
    updateSlides() {
      this.activeSlide = null
      this.prevSlide = null
      this.nextSlide = null
      nextTick(() => {
        this.activeSlide = this.slides[this.activeSlideIdx]
        this.prevSlide = this.slides[this.activeSlideIdx - 1]
        this.nextSlide = this.slides[this.activeSlideIdx + 1]
      })
    },
  },
}
</script>
