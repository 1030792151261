<template>
  <div>
    <master v-if="!criticalViewData">
      <router-view :key="getRouterViewKeyByRouteName($route.name)" />
    </master>
    <critical-error-view v-else />
    <RichRenderer :richTextData="hubspotTrackerTmpl" />
  </div>
</template>
<script>
import { mapState } from 'vuex'

import appInit from '@/components/common/mixins/app-init'

import Master from '@/components/common/Master.vue'

import CriticalErrorView from '@/components/CriticalErrorView.vue'

export default {
  name: 'App',
  components: {
    CriticalErrorView,
    Master,
  },
  mixins: [appInit],
  data() {
    return {
      hubspotTrackerTmpl: '',
    }
  },
  computed: {
    ...mapState('app', [
      'isLoaderVisible',
      'isInitialized',
      'isBackToTopBtnVisible',
      'criticalViewData',
    ]),
  },
  mounted() {
    // @TODO move to init
    const queryParams = new URLSearchParams(window.location.search)
    if (queryParams.get(this.$settings.app.openCartOnInitToken)) this.$router.push('cart')
  },
  methods: {
    getRouterViewKeyByRouteName(routeName) {
      const routesWithKeyOnly = ['search', 'inventory', 'replenishment-program', 'shipments']
      if (
        this.$route.path === '/resources/animal-isolation-calendar' ||
        this.$route.path === '/animal-isolation-calendar'
      )
        return this.$route.params.key
      return routesWithKeyOnly.includes(routeName)
        ? this.$route.params.key
        : this.$route.params.key || this.$route.fullPath
    },
  },
}
</script>
