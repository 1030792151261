export default {
  /**
   * Get document cookie by name.
   * @param name
   * @returns {*}
   */
  getCookie(name) {
    const value = '; ' + document.cookie
    const parts = value.split('; ' + name + '=')
    if (parts.length === 2) {
      return parts.pop().split(';').shift()
    }
    return null
  },

  /**
   * Remove cookie by name.
   * @param name
   */
  removeCookie(name, path = '', domain = '') {
    let cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:01 GMT;`
    if (path) {
      cookie = `${cookie}; path=${path};`
    }

    if (domain) {
      cookie = `${cookie}; domain=${domain};`
    }

    document.cookie = cookie
  },
}
