import { helpers } from '@vuelidate/validators'

const containsDigit = helpers.regex(/\d/)
const containsLetter = helpers.regex(/[a-zA-Z]/)
const nameOnly = (val) => {
  // eslint-disable-next-line
  const reg = /[\x00-\x1F\x21-\x26\x28-\x2C\x2E-\x40\x5B-\x60\x7B-\x89]+/
  return !reg.test(val)
}
const server = (value, model) => !model.errors?.server
const email = helpers.regex(
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
)
const alphaNumHyphen = helpers.regex(/^[a-zA-Z0-9-]+$/)
const notContainsSubstring = (value, testStrings = []) => {
  const defaulForbiddenValues = ['@gmail.com', '@msn.com', '@yahoo.com', '@ukr.net', '@hotmail.com']
  const forbiddenValues = testStrings.length ? testStrings : defaulForbiddenValues
  if (!value || typeof value !== 'string') return true
  return !forbiddenValues.some((forbiddenValue) => value.includes(forbiddenValue))
}

export {
  containsDigit,
  containsLetter,
  server,
  nameOnly,
  email,
  alphaNumHyphen,
  notContainsSubstring,
}
