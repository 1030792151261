import _ from 'lodash'

import ApiBase from '@/lib/api/v1/api-base'

class Invoices extends ApiBase {
  constructor(transport) {
    super(transport)
    this.getInvoices = this.getInvoices.bind(this)
    this.getPaymentToken = this.getPaymentToken.bind(this)
    this.payInvoice = this.payInvoice.bind(this)
  }

  /**
   * Get a list of invoices.
   * @param userId
   * @param params
   */
  getInvoices(params, userId) {
    return this._withDecoratedPromise(() => {
      params = _.pickBy(params)
      params = new URLSearchParams(params).toString()
      return this.transport.get(`/rest/api/v1/users/${this._getUserId(userId)}/invoices?${params}`)
    })
  }

  getPaymentToken({ invoiceNumber, config }) {
    return this._withDecoratedPromise(() => {
      return this.transport.post(
        `/rest/api/v1/users/${this._getUserId()}/payment/invoice/${encodeURIComponent(
          invoiceNumber,
        )}/form-token`,
        config,
      )
    })
  }

  getInvoiceDocument(url) {
    return this._withDecoratedPromise(() => {
      const requestOptions = {
        responseType: 'arraybuffer',
      }
      return this.transport.get(url, null, null, requestOptions)
    })
  }

  payInvoice({ invoiceNumber, data }) {
    return this._withDecoratedPromise(() => {
      return this.transport.post(
        `/rest/api/v1/users/${this._getUserId()}/payment/invoice/${encodeURIComponent(
          invoiceNumber,
        )}/callback`,
        data,
      )
    })
  }
}

export default Invoices
