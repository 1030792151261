<template>
  <svg width="25" height="24" viewBox="0 0 25 24" :fill="color" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M19.7966 3H5.20981C4.0637 3 3.12598 3.9 3.12598 5V19C3.12598 20.1 4.0637 21 5.20981 21H19.7966C20.9427 21 21.8805 20.1 21.8805 19V5C21.8805 3.9 20.9427 3 19.7966 3ZM18.7547 7.5H13.5451V9.5H18.7547V7.5ZM18.7547 14.5H13.5451V16.5H18.7547V14.5ZM5.20981 19H19.7966V5H5.20981V19ZM11.4613 6H6.25172V11H11.4613V6ZM7.29364 10H10.4194V7H7.29364V10ZM11.4613 13H6.25172V18H11.4613V13ZM7.29364 17H10.4194V14H7.29364V17Z"
    />
  </svg>
</template>
<script>
export default {
  name: 'IconBallot',
  props: {
    color: {
      type: String,
      default: '#000',
    },
  },
}
</script>
