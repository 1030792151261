<template>
  <div class="asm-search">
    <div class="form-group" :class="{ error: !!hasError }">
      <label>
        <input
          v-model="searchQuery"
          type="text"
          class="asm-search__input form-input form-input--large"
          :placeholder="$t('ASM.placeholders.customerName')"
          :disabled="disabled"
          name="customer-name"
          autocomplete="off"
          maxlength="50"
          @focusin.prevent="onFocusIn"
          @keydown="onFocusIn"
          @focusout.prevent="onEsc"
          @keyup.esc="onEsc"
        />
      </label>
      <p v-if="hasError" class="error-text">
        {{ $t('ASM.errors.noCustomerIdFound') }}
      </p>
      <table
        v-if="suggestions && suggestions.length && inFocus"
        class="asm-suggestions"
        cellpadding="0"
        cellspacing="0"
      >
        <tr v-for="item in suggestions" :key="item.email" @mousedown="select(item)">
          <td>{{ item.firstName }} {{ item.lastName }}</td>
          <td>{{ item.email }}</td>
        </tr>
      </table>
    </div>
    <loader v-if="isLoaderVisible" :inline="true" />
  </div>
</template>
<script>
import _ from 'lodash'

import api from '@/lib/api'
import { debounce } from '@/lib/utils/debounce'

// @TODO get validation for the input field, cleanup code and deps
export default {
  name: 'AsmSearch',
  props: {
    disabled: Boolean,
  },
  emits: ['selected'],
  data() {
    return {
      searchQuery: '',
      inFocus: false,
      maxItems: 5,
      selectedCustomer: null,
      hasError: false,
      suggestions: [],
      isLoaderVisible: false,
    }
  },
  watch: {
    searchQuery: debounce(function (newVal) {
      if (newVal) this.doSearch()
    }, 300),
  },
  methods: {
    onFocusIn() {
      this.inFocus = true
    },
    onEsc() {
      this.inFocus = false
    },
    select(customer) {
      this.searchQuery = customer.email
      this.selectedCustomer = customer
      this.checkForError()
      this.$emit('selected', customer)
    },
    resetSelectedCustomer() {
      this.selectedCustomer = null
      this.$emit('selected', this.selectedCustomer)
    },
    checkForError() {
      if (this.searchQuery && this.searchQuery !== _.get(this.selectedCustomer, 'email')) {
        this.hasError = true
      } else {
        this.hasError = false
      }
    },
    clear() {
      this.hasError = false
      this.searchQuery = ''
      this.selectedCustomer = null
      this.$emit('selected', null)
    },
    async doSearch() {
      this.isLoaderVisible = true
      const res = await api.asm.suggestions(this.searchQuery)
      this.isLoaderVisible = false
      this.suggestions = res.data.slice(0, this.maxItems) || [{ firstName: 'No Customer ID found' }]

      this.selectedCustomer = this.suggestions.find((item) => item.email === this.searchQuery)
      this.$emit('selected', this.selectedCustomer)
    },
  },
}
</script>
