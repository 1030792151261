<template>
  <div>
    <div id="content" :class="{ blurred__full: !isInitialized, blurred: isLoaderVisible }">
      <template v-if="isContentfulInitialized">
        <preview-banner v-if="isContentfulPreview"></preview-banner>
        <asm-panel v-if="$settings.services.asm.enabled" />
        <app-header />
        <breadcrumbs v-if="canShowBreadcrumbs" />
        <div id="main-page-data">
          <slot />
        </div>
        <app-footer />
        <div class="popup">
          <emergency-popup />
        </div>
        <gdpr-popup />
      </template>
    </div>
    <loader v-if="isLoaderVisible" />
    <back-to-top-btn v-if="isBackToTopBtnVisible" />
  </div>
</template>
<script>
import { mapState } from 'vuex'

import routes from '@/lib/utils/routes'

import AppFooter from '@/components/common/AppFooter.vue'
import AppHeader from '@/components/common/AppHeader/AppHeader.vue'
import BackToTopBtn from '@/components/common/BackToTopBtn.vue'
import Breadcrumbs from '@/components/common/Breadcrumbs.vue'
import EmergencyPopup from '@/components/common/EmergencyPopup.vue'
import GdprPopup from '@/components/common/GdprPopup.vue'
import PreviewBanner from '@/components/common/PreviewBanner.vue'

import AsmPanel from '@/components/asm/AsmPanel.vue'

export default {
  name: 'Master',
  components: {
    AppFooter,
    AppHeader,
    Breadcrumbs,
    GdprPopup,
    EmergencyPopup,
    AsmPanel,
    BackToTopBtn,
    PreviewBanner,
  },
  computed: {
    canShowBreadcrumbs() {
      return !routes.isHideBreadcrumbs(this.$route.name)
    },
    ...mapState('app', [
      'isLoaderVisible',
      'isInitialized',
      'isBackToTopBtnVisible',
      'isContentfulPreview',
      'isContentfulInitialized',
    ]),
  },
}
</script>
