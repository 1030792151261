import { nextTick } from 'vue'

import pageTitle from '@/lib/utils/page-title'
import scroll from '@/lib/utils/scroll'

import contentfulInitMixin from '@/components/common/contentful/mixins/contentfulInitMixin'

export default {
  mixins: [contentfulInitMixin],
  methods: {
    removeGlobalEventListeners() {
      this.$emitter.off('auth.notAuthorized')
      this.$emitter.off('route.after')
    },
    addGlobalEventListeners() {
      this.$emitter.on('auth.notAuthorized', async (errors = []) => {
        const isLoggedIn = this.$store.getters['session/isUserLoggedIn']
        if (isLoggedIn) {
          await this.$store.dispatch('session/logout')
        } else {
          await this.$store.dispatch('session/resetApiSession')
          await this.$store.dispatch('cart/clearCart')
        }
        this.$store.commit('app/SET_NOT_AUTORIZED_ERRORS', errors || [])
        if (isLoggedIn) {
          this.$router.push({ name: 'authentication' })
        } else {
          window.location.reload()
        }
      })
      this.$emitter.on('route.after', async (payload) => {
        pageTitle.update(payload.to)
        await nextTick()
        if (payload.to.meta.isScrollingUp) await scroll.pageToTop()
        if (payload.to.hash) {
          setTimeout(() => {
            const element = document.querySelector(payload.to.hash)
            if (element) {
              scroll.scrollToElement(element)
            }
          }, 1)
        }
        this.$analytics.sendPageView()
      })
    },
  },
  beforeUnmount() {
    this.removeGlobalEventListeners()
  },
  async created() {
    const queryParams = new URLSearchParams(window.location.search)
    const punchoutErrorMessage = queryParams.get('punchoutSessionErrorMessage')
    if (punchoutErrorMessage) {
      this.$store.dispatch('app/setCriticalViewData', {
        templateName: 'punchout',
        data: punchoutErrorMessage,
      })
      return
    }

    this.$store.dispatch('app/toggleLoader', true)

    this.$store.watch(
      (state) => state.session.email,
      () => (value) => this.$err.configureClient(value),
    )

    await Promise.all([
      this.$store.dispatch('punchout/initPunchoutState'),
      this.$store.dispatch('asm/initAsmState'),
    ])
    await this.contentfulInit()
    await Promise.all([
      this.$store.dispatch('cart/initCartState'),
      this.$store.dispatch('session/initSessionState'),
    ])
      .then(() => {
        window.localStorage.setItem('bioForceReloadOnError', true)
        this.addGlobalEventListeners()
      })
      .catch((error) => {
        if (this.$store.state.punchout.isPunchoutSession) {
          const errorMessage =
            error?.response?.data?.message ||
            error?.response?.data?.map((el) => el.message)?.join('; ') ||
            'Something went wrong'
          this.$store.dispatch('app/setCriticalViewData', {
            templateName: 'punchout',
            data: errorMessage,
          })
          return
        }

        this.$err.decorateError(this.$options.name, error, {
          errorLevel: 'boot',
        })
        if (window.localStorage.getItem('bioForceReloadOnError')) {
          window.localStorage.clear()
          window.location.reload()
          // @TODO error will be sent but won't receive response in time as reload will cancel the request
          throw error
        } else {
          window.localStorage.clear()
          throw error
        }
      })

    const redirect = this.$contentful.service.getRedirect(window.location.pathname)
    if (redirect) window.location.href = redirect

    this.$store.dispatch('app/setInitialized', true)

    this.$store.dispatch('app/toggleLoader', false)
  },
}
