export default function dotNotationToObject(dotNotationObj) {
  const result = {}
  for (const [key, value] of Object.entries(dotNotationObj)) {
    const keys = key.split('.')
    let current = result

    keys.forEach((part, index) => {
      if (index === keys.length - 1) {
        current[part] = value
      } else {
        current[part] = current[part] || {}
        current = current[part]
      }
    })
  }

  return result
}
