import striptags from 'striptags'

import routes from '@/lib/utils/routes'

import { i18n } from '@/i18n'
import settings from '@/settings'

export default {
  /**
   * Get page's title by route name.
   * @param routeName
   * @returns {*}
   */
  getPageTitleByRouteName(routeName) {
    const routeData = routes.find(routeName) || routes.find('not-found')
    let title = settings.app.title.defaultTitle
    if (routeData.pageTitle) {
      const pageTitle = i18n.global.t(routeData.pageTitle)
      if (
        settings.app.title.separator &&
        settings.app.title.separator.length > 0 &&
        pageTitle !== settings.app.title.defaultTitle
      ) {
        title = `${pageTitle}${settings.app.title.separator}${settings.app.title.defaultTitle}`
      } else {
        title = `${pageTitle}`
      }
    }
    return title
  },
  getPageTitleByRouteCrumbs(crumb) {
    if (crumb) {
      const crumbName = striptags(crumb.name)
      return `${crumbName}${settings.app.title.separator}${settings.app.title.defaultTitle}`
    }
  },

  /**
   * Set the title.
   * @param title
   */
  setTitle(title) {
    window.document.title = title
  },

  /**
   * Set new title by passing the title as a string.
   * @param newTitle
   */
  updateAsString(newTitle) {
    let title = settings.app.title.defaultTitle
    if (newTitle && newTitle.length && newTitle !== title) {
      title = `${newTitle}${settings.app.title.separator}${title}`
    }
    this.setTitle(title)
  },

  /**
   * Update page's title.
   * @param route
   */
  update(route) {
    let title = settings.app.title.defaultTitle
    if (route && 'name' in route && route.name !== 'redirect') {
      title = this.getPageTitleByRouteName(route.name)
    } else {
      title = this.getPageTitleByRouteCrumbs(route.meta.crumbs?.slice(-1)[0])
    }
    this.setTitle(title)
  },
}
