<template>
  <svg width="25" height="24" viewBox="0 0 25 24" :fill="color" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M19.0633 11.97C18.7091 12.59 18.0214 13 17.24 13H9.47768L8.33157 15H20.8346V17H8.33157C6.74786 17 5.74762 15.37 6.50822 14.03L7.91481 11.59L4.16391 4H2.08008V2H5.48714L6.46655 4H21.8869C22.6788 4 23.1789 4.82 22.7934 5.48L19.0633 11.97ZM20.1156 6H7.45637L9.92571 11H17.24L20.1156 6ZM8.33157 18C7.18547 18 6.25816 18.9 6.25816 20C6.25816 21.1 7.18547 22 8.33157 22C9.47768 22 10.4154 21.1 10.4154 20C10.4154 18.9 9.47768 18 8.33157 18ZM16.6773 20C16.6773 18.9 17.6046 18 18.7507 18C19.8968 18 20.8346 18.9 20.8346 20C20.8346 21.1 19.8968 22 18.7507 22C17.6046 22 16.6773 21.1 16.6773 20Z"
    />
  </svg>
</template>
<script>
export default {
  name: 'IconShoppingCart',
  props: {
    color: {
      type: String,
      default: '#000',
    },
  },
}
</script>
