export const STATUS = {
  COMPANY_ADMIN: 'COMPANY ADMIN',
  DEPARTMENT_MEMBER: 'DEPARTMENT MEMBER',
}

export const STATUS_TO_LABEL = {
  'COMPANY ADMIN': 'Company Admin',
  'DEPARTMENT MEMBER': 'Department Member',
}

export const LABEL_TO_STATUS = {
  'Company Admin': 'COMPANY ADMIN',
  'Department Member': 'DEPARTMENT MEMBER',
}
