<template>
  <div class="accordion" :class="{ 'accordion--active': isActive }" @click.self="accordionClicked">
    <h4
      class="text-color-navy accordion__title text-h4"
      :class="{ 'accordion__title--active': isActive }"
      @click="accordionClicked"
    >
      <template v-if="header">
        {{ header }}
      </template>
      <slot v-else name="header" />
    </h4>
    <div v-if="isActive" :class="{ 'rich-text-wrapper': !main }">
      <template v-if="main">
        <RichRenderer v-if="isMainRich" :richTextData="main" />
        <template v-else>
          {{ main }}
        </template>
      </template>
      <slot v-else name="main" />
    </div>
  </div>
</template>
<script>
export default {
  name: 'Accordion',
  props: {
    header: {
      type: String,
      default: '',
    },
    main: {
      type: [String, Object],
      default: '',
    },
    isMainRich: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['clicked'],
  data() {
    return {
      isActive: false,
    }
  },
  created() {
    this.$emitter.on('accordion.click', this.close)
  },
  beforeUnmount() {
    this.$emitter.off('accordion.click', this.close)
  },
  methods: {
    accordionClicked() {
      const currentState = this.isActive
      this.$emitter.emit('accordion.click')
      this.isActive = !currentState
      this.$emit('clicked', this.isActive)
    },
    close() {
      this.isActive = false
    },
  },
}
</script>
