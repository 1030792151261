<template>
  <div
    ref="tableWrap"
    :style="{ width: `${tableWrapWidth}px` }"
    :class="{
      'table-initial': !isInitialized,
      'table-scroll': variant === 'Horizontal-Scroll-Static-Header' && !isTableScrollable,
      'table-scroll-active': variant === 'Horizontal-Scroll-Static-Header' && isTableScrollable,
      'table-scroll-sticky': variant === 'Horizontal-Scroll-Sticky-Header' && !isTableScrollable,
      'table-scroll-sticky-active':
        variant === 'Horizontal-Scroll-Sticky-Header' && isTableScrollable,
      'table-full-width': variant === 'Full-Width' && totalColWidth < tableWrapWidth,
      'table-full-width-active': variant === 'Full-Width' && totalColWidth > tableWrapWidth,
      'table-default': variant === 'Default',
      'table--no-borders': !showBorders,
    }"
    class="content-table"
  >
    <table>
      <tr
        v-for="(tabelRow, idx) in tabelRows"
        :key="idx"
        :style="[tabelRow.rowHeight ? `height: ${tabelRow.rowHeight}px` : '']"
      >
        <component
          :is="tabelCell.cellTag"
          v-for="(tabelCell, cellIdx) in tabelRow.rowContent"
          :key="cellIdx"
          :style="[tabelCell.cellWidth ? `width: ${tabelCell.cellWidth}px` : '']"
        >
          <RichRenderer :richTextData="tabelCell.cellContent" />
        </component>
      </tr>
    </table>
  </div>
</template>
<script>
import _ from 'lodash'
import { nextTick } from 'vue'

export default {
  props: {
    variant: {
      type: String,
      required: true,
    },
    totalColWidth: {
      type: [Number, String],
      required: true,
    },
    tabelRows: {
      type: Array,
      default: () => [],
    },
    showBorders: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isInitialized: false,
      tableWrapWidth: 0,
      isTableScrollable: true,
    }
  },
  mounted() {
    setTimeout(() => {
      this.calculateSizes()
      window.addEventListener('resize', this.debounceCalculateSizes)
    }, 1)
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.getCarouselWidth)
  },
  methods: {
    calculateSizes() {
      const tableWrap = this.$refs.tableWrap
      if (tableWrap) {
        this.tableWrapWidth = tableWrap.parentNode.offsetWidth
        nextTick(() => {
          const table = tableWrap.querySelector('table')
          const tableWidth = table.offsetWidth
          this.isTableScrollable = tableWidth > this.tableWrapWidth
          this.isInitialized = true
        })
      }
    },
    debounceCalculateSizes: _.debounce(function () {
      this.calculateSizes()
    }, 50),
  },
}
</script>
