import ApiBase from '@/lib/api/v1/api-base'

class User extends ApiBase {
  /**
   * Get User account info.
   * @param email
   * @return {Promise<any>}
   */

  constructor(transport) {
    super(transport)
    this.accountDetails = this.accountDetails.bind(this)
    this.getShippingAddresses = this.getShippingAddresses.bind(this)
    this.getBillingAddresses = this.getBillingAddresses.bind(this)
    this.updateUserInfo = this.updateUserInfo.bind(this)
    this.updateAddressInfo = this.updateAddressInfo.bind(this)
    this.updateSomeUserInfoFields = this.updateSomeUserInfoFields.bind(this)
    this.createShortUrl = this.createShortUrl.bind(this)
    this.resolveShortUrl = this.resolveShortUrl.bind(this)
    this.getQuotedProductsConfigurations = this.getQuotedProductsConfigurations.bind(this)
    this.getOrderedProductsConfigurations = this.getOrderedProductsConfigurations.bind(this)
    this.updateUserRoles = this.updateUserRoles.bind(this)
    this.getUsersList = this.getUsersList.bind(this)
    this.getCompanyUsers = this.getCompanyUsers.bind(this)
  }

  accountDetails(userId) {
    return new Promise((resolve, reject) => {
      this.transport
        .get(`/rest/api/v1/users/${this._getUserId(userId)}/`, null, {
          Accept: '*/*',
        })
        .then((response) => {
          if (!response.data || !Object.keys(response.data).length) {
            reject(this._decorateNetworkError(new Error('Failed to retrieve account details')))
          } else {
            resolve(response.data)
          }
        })
        .catch((reason) => {
          reject(this._decorateNetworkError(reason))
        })
    })
  }

  getShippingAddresses(userId) {
    return this._withDecoratedPromise(() => {
      return this.transport.get(`/rest/api/v1/users/${this._getUserId(userId)}/shippingaddresses`)
    })
  }

  getBillingAddresses(userId) {
    return this._withDecoratedPromise(() => {
      return this.transport.get(`/rest/api/v1/users/${this._getUserId(userId)}/billingaddresses`)
    })
  }

  submitAddressRequest(addressData, userId) {
    return this._withDecoratedPromise(() => {
      return this.transport.post(
        `/rest/api/v1/users/${this._getUserId(userId)}/addresses`,
        addressData,
      )
    })
  }

  updateUserInfo(data, userId) {
    return this._withDecoratedPromise(() => {
      return this.transport.put(`/rest/api/v1/users/${this._getUserId(userId)}`, data)
    })
  }

  updateSomeUserInfoFields(data, userId) {
    return this._withDecoratedPromise(() => {
      return this.transport.patch(`/rest/api/v1/users/${this._getUserId(userId)}`, data)
    })
  }

  updateAddressInfo(data, userId) {
    return this._withDecoratedPromise(() => {
      return this.transport.put(`/rest/api/v1/users/${this._getUserId(userId)}/addresses`, data)
    })
  }

  getInventorySavedSearches() {
    return this._withDecoratedPromise(() => {
      return this.transport.get(`/rest/api/v1/users/${this._getUserId()}/search`)
    })
  }

  addInventorySavedSearch(data) {
    return this._withDecoratedPromise(() => {
      return this.transport.post(`/rest/api/v1/users/${this._getUserId()}/search`, data)
    })
  }

  deleteInventorySavedSearches(code) {
    return this._withDecoratedPromise(() => {
      return this.transport.delete(`/rest/api/v1/users/${this._getUserId()}/search/${code}`)
    })
  }

  updateInventorySavedSearch(data) {
    return this._withDecoratedPromise(() => {
      return this.transport.put(`/rest/api/v1/users/${this._getUserId()}/search`, data)
    })
  }

  sendInventoryQueryAnalyticsData(data) {
    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded',
    }
    return this._withDecoratedPromise(() => {
      return this.transport.post(
        `/rest/api/v1/users/${this._getUserId()}/search/log`,
        encodeURIComponent(data),
        headers,
      )
    })
  }

  sendInventoryConversionData(data) {
    return this._withDecoratedPromise(() => {
      return this.transport.post(`/rest/api/v1/users/${this._getUserId()}/conversionEvent`, data)
    })
  }

  createShortUrl(url) {
    return this._withDecoratedPromise(() => {
      return this.transport.post('/rest/api/v1/url/create', { url })
    })
  }

  resolveShortUrl(hash) {
    return this._withDecoratedPromise(() => {
      return this.transport.get(`/rest/api/v1/url/resolve/${encodeURIComponent(hash)}`)
    })
  }

  getSupportInfo(userId) {
    return this._withDecoratedPromise(() => {
      return this.transport.get(`/rest/api/v1/users/${this._getUserId(userId)}/support-info`)
    })
  }

  getCompany() {
    return this._withDecoratedPromise(() => {
      return this.transport.get(`/rest/api/v1/users/${this._getUserId()}/its/company`)
    })
  }

  getCompanyUsers({ companyId }) {
    return this._withDecoratedPromise(() => {
      return this.transport.get(
        `/rest/api/v1/users/${this._getUserId()}/its/company/${companyId}/user`,
      )
    })
  }

  getCompanyShippingAddresses(companyId) {
    return this._withDecoratedPromise(() => {
      return this.transport.get(
        `/rest/api/v1/users/${this._getUserId()}/its/company/${companyId}/shippingaddresses`,
      )
    })
  }

  getCompanyBillingAddresses(companyId) {
    return this._withDecoratedPromise(() => {
      return this.transport.get(
        `/rest/api/v1/users/${this._getUserId()}/its/company/${companyId}/billingaddresses`,
      )
    })
  }

  getCompanyDeliveryContacts(companyId) {
    return this._withDecoratedPromise(() => {
      return this.transport.get(
        `/rest/api/v1/users/${this._getUserId()}/its/company/${companyId}/unitcustomers`,
      )
    })
  }

  getUsersList({ companyId, email }) {
    return this._withDecoratedPromise(() => {
      return this.transport.get(
        `/rest/api/v1/users/${this._getUserId()}/its/company/${companyId}/user/search?term=${encodeURIComponent(
          email,
        )}`,
      )
    })
  }

  updateUserRoles({ data, companyId }) {
    return this._withDecoratedPromise(() => {
      return this.transport.patch(
        `/rest/api/v1/users/${this._getUserId()}/its/company/${companyId}/user`,
        data,
      )
    })
  }

  getRecentProducts() {
    return this._withDecoratedPromise(() => {
      return this.transport.get(`/rest/api/v1/users/${this._getUserId()}/recentProducts`)
    })
  }

  addRecentProduct(pgpSlug) {
    return this._withDecoratedPromise(() => {
      return this.transport.post(
        `/rest/api/v1/users/${this._getUserId()}/recentlyViewedProduct?pgpSlug=${encodeURIComponent(
          pgpSlug,
        )}`,
      )
    })
  }

  getCountries() {
    return this._withDecoratedPromise(() => {
      return this.transport.get(`/rest/api/v1/countries`)
    })
  }

  getRegions(countryIsoCode) {
    return this._withDecoratedPromise(() => {
      return this.transport.get(
        `/rest/api/v1/countries/${encodeURIComponent(countryIsoCode)}/regions`,
      )
    })
  }

  getQuotedProductsConfigurations(skus) {
    return this._withDecoratedPromise(() => {
      return this.transport.post(
        `/rest/api/v2/products/users/${this._getUserId()}/quotes/configurations`,
        skus,
      )
    })
  }

  getOrderedProductsConfigurations(skus) {
    return this._withDecoratedPromise(() => {
      return this.transport.post(
        `/rest/api/v2/products/users/${this._getUserId()}/orders/configurations`,
        skus,
      )
    })
  }
}

export default User
