<template>
  <div v-if="isEmergencyPopupVisible && data.title" class="emergency-popup">
    <div class="popup__container emergency-popup__container container">
      <div class="emergency-popup__copy">
        <RichRenderer :richTextData="data.message" />
      </div>
      <i class="icon-close emergency-popup__close-icon" @click="closePopup" />
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'

import contentfulAutowireGlobalMixin from '@/components/common/contentful/mixins/contentfulAutowireGlobalMixin'

export default {
  name: 'EmergencyPopup',
  mixins: [contentfulAutowireGlobalMixin],
  data() {
    return {
      data: {},
    }
  },
  computed: {
    ...mapState('app', ['isEmergencyPopupVisible']),
  },
  methods: {
    ...mapActions('app', ['toggleEmergencyPopup']),
    initData(entry) {
      this.data = { ...entry }
    },
    closePopup() {
      this.toggleEmergencyPopup(false)
    },
  },
}
</script>
