<template>
  <svg width="25" height="24" viewBox="0 0 25 24" :fill="color" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.5031 2C6.75177 2 2.08398 6.48 2.08398 12C2.08398 17.52 6.75177 22 12.5031 22C18.2545 22 22.9223 17.52 22.9223 12C22.9223 6.48 18.2545 2 12.5031 2ZM12.5031 20C7.9083 20 4.16782 16.41 4.16782 12C4.16782 7.59 7.9083 4 12.5031 4C17.098 4 20.8385 7.59 20.8385 12C20.8385 16.41 17.098 20 12.5031 20ZM10.3881 9.47C10.3881 10.2 10.9819 10.69 12.8261 11.14C14.6599 11.6 16.6291 12.36 16.6396 14.56C16.6291 16.17 15.3788 17.04 13.7951 17.33V19H11.357V17.3C9.79416 16.99 8.48135 16.03 8.37716 14.33H10.1693C10.263 15.25 10.9194 15.97 12.5865 15.97C14.3682 15.97 14.7745 15.11 14.7745 14.58C14.7745 13.86 14.3682 13.17 12.3364 12.71C10.0755 12.19 8.52303 11.29 8.52303 9.5C8.52303 7.99 9.78374 7.01 11.357 6.69V5H13.7847V6.71C15.4726 7.11 16.327 8.34 16.3791 9.68H14.5974C14.5557 8.7 14.0139 8.04 12.5761 8.04C11.2112 8.04 10.3881 8.63 10.3881 9.47Z"
    />
  </svg>
</template>
<script>
export default {
  name: 'IconDollarInCircle',
  props: {
    color: {
      type: String,
      default: '#000',
    },
  },
}
</script>
