import { documentToHtmlString } from '@contentful/rich-text-html-renderer'
import { INLINES } from '@contentful/rich-text-types'
import striptags from 'striptags'

import { unEscapeString } from '@/lib/utils/strings'

export default {
  props: ['parentComponentName'],
  methods: {
    initData(entry) {
      this.item = { ...entry }
    },
    getPageBySlug(slug) {
      return this.$contentful.service.getPageBySlug(slug)
    },
    getSiteCopy(copyGroupId, copyItemId, stripTags = true) {
      const copyItem = this.$contentful.service.getSiteCopy(copyGroupId, copyItemId)
      return this.richToHtml(copyItem, stripTags)
    },
    richToHtml(richData, stripTags = true) {
      const getFields = (node) => node.data.target?.fields
      const typeHandlers = {
        page: (node) => {
          return `<router-link
                key='${getFields(node).slug}'
                to='${this.getPageBySlug(getFields(node).slug).link}'
              >${node.content[0].value}</router-link>`
        },
        resource: (node) => {
          const item = getFields(node)
          if (!item) return null
          let { wrappedSlug } = this.getSlugsForEducationalResource(
            item.resourceGroup.fields,
            item.title,
          )
          const isBlog = item?.resourceGroup?.fields?.slug === 'blogs'
          if (isBlog) wrappedSlug = !item.link ? `blogs/${item.slug}` : item.link
          const title = node.content[0]?.value || item.title
          return `<router-link
                    key='${item.slug}'
                    to='/${wrappedSlug}'
                  >${title}</router-link>`
        },
        pdf: (node) => {
          return `<a href="${getFields(node).file.url}" target="_blank">${
            node.content[0] ? node.content[0].value : getFields(node).title
          }</a>`
        },
        link: (node) => {
          const domains = ['https://bioivt.com', 'https://www.bioivt.com']
          const target = domains.some((domain) => node.data.uri.startsWith(domain))
            ? '_self'
            : '_blank'
          return `<a href='${node.data.uri}' target="${target}">${node.content[0]?.value}</a>`
        },
      }
      const options = {
        renderNode: {
          [INLINES.ENTRY_HYPERLINK]: (node) => {
            const typeId = this.getContentTypeId(node.data.target)
            if (typeHandlers[typeId]) return typeHandlers[typeId](node)
          },
          [INLINES.HYPERLINK]: (node) => typeHandlers.link(node),
        },
      }
      const tagsToIgnore = ['router-link', 'a', 'i', 'b', 'strong']
      const htmlString = richData ? unEscapeString(documentToHtmlString(richData, options)) : ''
      return stripTags ? `${striptags(htmlString, tagsToIgnore)}` : htmlString
    },
    getImageUrl(imageField, quality = 80, width = '', height = '') {
      if (
        !imageField ||
        !Object.keys(imageField).length ||
        !('fields' in imageField) ||
        !('file' in imageField.fields)
      ) {
        return ''
      }
      return `${imageField.fields.file.url}?q=${quality}&w=${width}&h=${height}&fm=webp`
    },
    getImageAlt(imageField, imageTitle = '') {
      if (
        !imageField ||
        !Object.keys(imageField).length ||
        !('fields' in imageField) ||
        !('description' in imageField.fields)
      ) {
        return imageTitle
      }
      return imageField.fields.description
    },
    getContentTypeId(item) {
      if (Array.isArray(item)) {
        return item[0]?.sys.contentType?.sys.id
      } else {
        return item.sys.contentType?.sys.id
      }
    },
    renderScript(snippet) {
      if (snippet) {
        return `<VueScriptComponent script='${snippet.replace(/'/g, '"')}'/>`
      }
    },
    fetchItems(query) {
      query = this.contentfulQuery || query
      return this.$contentful.client
        .getEntries(query)
        .then((response) => {
          this.contentfulState.loading = false
          return response.items
        })
        .catch((e) => {
          this.contentfulState.loading = false
          this.contentfulState.error = true
          throw e
        })
    },
    getSlugsForEducationalResource(parentPage, resourseTitle, objectID) {
      let slug, wrappedSlug, parentSlug
      if (this.$contentful.service.isEducationalContent(parentPage)) {
        slug = this.$contentful.service.getEducationalContentPage().fields.slug
        wrappedSlug = `${slug}?string=${encodeURIComponent(
          resourseTitle,
        )}&facets=(resourceGroup:"${encodeURIComponent(parentPage.title)}")`
        parentSlug = `${slug}?facets=(resourceGroup:"${encodeURIComponent(parentPage.title)}")`
      } else {
        slug = parentPage.slug === 'faq' ? 'resources/faq' : parentPage.slug
        const entryName = parentPage.slug === 'faq' ? objectID : resourseTitle
        wrappedSlug = `${slug}?entry=${encodeURIComponent(entryName)}`
        parentSlug = slug
      }
      return {
        slug,
        wrappedSlug,
        parentSlug,
      }
    },
  },
  data() {
    return {
      contentfulState: {
        error: false,
        loading: true,
      },
    }
  },
}
