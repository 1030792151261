<template>
  <modal :isLoading="isLoading" class="modal--small forgot-password" @close="$emit('close')">
    <div v-if="!isFormSubmitted">
      <h2 class="modal__title">
        {{ title }}
      </h2>
      <p
        v-if="isLinkExpired"
        class="forgot-password__text-gray"
        v-html="$t('forgotPassword.linkExpiredText')"
      />
      <p class="modal__subtitle" v-html="$t('forgotPassword.subHeading')" />
      <form class="forgot-password__form" @submit.prevent="onSubmit">
        <form-input
          v-model.trim="v$.form.email.$model.value"
          :field="v$.form.email"
          :isValidationRun="isValidationRun"
          class="forgot-password__input"
        />

        <app-button
          class="forgot-password__submit"
          :text="$t('forgotPassword.submit')"
          size="sub-middle"
          type="submit"
          :disabled="isLoaderVisible"
        />
      </form>
    </div>
    <div v-else>
      <h2 class="modal__title" v-html="$t('forgotPassword.resetRequestedTitle')" />
      <p class="text-h5 text-color-grey-dark forgot-password__email">
        {{ form.email.value }}
      </p>
      <p
        class="text-description forgot-password__subtitle"
        v-html="$t('forgotPassword.resetRequestedText')"
      />
      <div class="forgot-password__help">
        <i class="icon-help" />
        <p class="text-description" v-html="$t('forgotPassword.help')" />
      </div>
    </div>
  </modal>
</template>
<script>
import { useVuelidate } from '@vuelidate/core'
import { required, maxLength } from '@vuelidate/validators'

import api from '@/lib/api'
import { email } from '@/lib/utils/validationHelpers'

import formValidationMixin from '@/components/common/mixins/formValidationMixin'
import modalMixin from '@/components/common/mixins/modalMixin'

import FormInput from '@/components/common/FormInput.vue'
import Modal from '@/components/common/Modal.vue'

export default {
  components: {
    Modal,
    FormInput,
  },
  mixins: [formValidationMixin, modalMixin],
  props: {
    isLinkExpired: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
  },
  emits: ['close', 'formSubmited'],
  setup() {
    return {
      v$: useVuelidate(),
    }
  },
  data() {
    return {
      isLoading: false,
      isFormSubmitted: false,
      isValidationRun: false,
      form: {
        email: {
          placeholder: 'Work Email Address *',
          value: '',
          title: 'email',
          type: 'email',
          maxLength: 80,
          size: 'small',
          errors: {
            required: 'Please provide a work email address.',
            email: 'Please provide a valid email address.',
          },
        },
      },
      error: '',
    }
  },
  methods: {
    onSubmit() {
      this.withPresendRoutine(() => {
        this.isLoading = true
        api.auth.sendResetEmail(this.form.email.value).finally(() => {
          this.$emit('formSubmited')
          this.isFormSubmitted = true
          this.isLoading = false
        })
      })
    },
  },
  validations() {
    return {
      form: {
        email: {
          value: {
            required,
            email,
            maxLength: maxLength(80),
          },
        },
      },
    }
  },
}
</script>
