<template>
  <div
    class="my-account-menu"
    :class="{
      'my-account-menu--auth': isUserLoggedIn,
      'my-account-menu--mobile-view': isMobileView,
      'my-account-menu--sticky-header-view': isStickyHeaderView,
    }"
    @mouseenter="handleLabelHover(true)"
    @mouseleave="handleLabelHover(false)"
  >
    <div class="my-account-menu__label" @click="onLabelClick">
      <div v-if="isUserLoggedIn" class="my-account-menu__label-user">
        <span class="my-account-menu__label-user-initials" v-html="user.initials" />

        <span
          v-if="!isStickyHeaderView"
          class="my-account-menu__label-user-text"
          v-html="userLabelText"
        />
        <i
          v-if="!isStickyHeaderView && !isMobileView"
          class="icon-arrow my-account-menu__label-user-icon"
        ></i>
      </div>
      <a
        v-else
        id="header-my-account-register-button"
        href="/authentication"
        class="my-account-menu__guest-link"
        @click.prevent.stop="onLabelClick"
      >
        <IconPerson v-if="isStickyHeaderView" color="#15284b" class="my-account-menu__guest-icon" />
        <template v-else>
          <IconPerson v-if="isMobileView" color="#fff" class="my-account-menu__guest-icon" />
          <img v-else :src="userIcon" class="my-account-menu__guest-icon" />
          <span class="my-account-menu__guest-text" v-html="guestLabelText"></span>
        </template>
      </a>
      <i
        v-if="isMobileView && isUserLoggedIn"
        class="icon-arrow my-account-menu__label-icon"
        :class="{ 'my-account-menu__label-icon--up': isDrawerVisible }"
      ></i>
    </div>
    <div>
      <transition :name="isMobileView ? '' : 'opacity'">
        <div
          v-show="(isUserLoggedIn && isDrawerVisible) || isMobileView"
          class="my-account-menu__drawer"
          :class="{ 'mobile-menu-account__list--active': isDrawerVisible }"
        >
          <div v-if="!isMobileView" class="my-account-menu__drawer-header">
            <span class="my-account-menu__drawer-header-initials" v-html="user.initials" />
            <span class="my-account-menu__drawer-header-text" v-html="userLabelText" />
            <i class="icon-arrow my-account-menu__drawer-header-icon"></i>
          </div>
          <a
            v-if="dashboardDrawerItem"
            class="my-account-menu__drawer-contacts-line"
            :her="dashboardDrawerItem.link"
            @click.prevent="onDrawerItemClick(dashboardDrawerItem)"
          >
            <span class="my-account-menu__drawer-account-name" v-html="user.fullName" />
            <span class="my-account-menu__drawer-account-email" v-html="user.email" />
          </a>
          <div class="my-account-menu__drawer-list">
            <a
              v-for="drawerItem in availableDrawerMenuItems"
              :key="drawerItem.title"
              :href="drawerItem.link"
              class="my-account-menu__drawer-item"
              :class="{ 'my-account-menu__drawer-item--top-border': drawerItem.topBorder }"
              @click.prevent="onDrawerItemClick(drawerItem)"
            >
              <component
                :is="drawerItem.icon"
                v-if="drawerItem.icon"
                color="#757575"
                class="my-account-menu__drawer-icon"
              />
              <span class="my-account-menu__drawer-text" v-html="drawerItem.title" />
            </a>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
import { markRaw } from 'vue'
import { mapState, mapGetters } from 'vuex'

import userIcon from '@/assets/images/icons/user-icon.svg'

import IconBallot from '@/components/common/icons/IconBallot.vue'
import IconDollarInCircle from '@/components/common/icons/IconDollarInCircle.vue'
import IconExit from '@/components/common/icons/IconExit.vue'
import IconFileWithSpace from '@/components/common/icons/IconFileWithSpace.vue'
import IconGear from '@/components/common/icons/IconGear.vue'
import IconPerson from '@/components/common/icons/IconPerson.vue'
import IconShoppingCart from '@/components/common/icons/IconShoppingCart.vue'
import IconTablet from '@/components/common/icons/IconTablet.vue'
import IconTruckThick from '@/components/common/icons/IconTruckThick.vue'

export default {
  components: {
    IconPerson,
  },
  props: {
    isMobileView: {
      type: Boolean,
      default: false,
    },
    isStickyHeaderView: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['linkClicked', 'labelClicked'],
  data() {
    return {
      drawerMenuItems: [
        {
          title: 'Dashboard',
          link: '/dashboard',
          icon: markRaw(IconPerson),
        },
        {
          title: 'Quotes',
          link: '/quotes',
          icon: markRaw(IconFileWithSpace),
        },
        {
          title: 'Orders',
          link: '/orders',
          icon: markRaw(IconShoppingCart),
        },
        {
          title: 'Shipments',
          link: '/shipments',
          icon: markRaw(IconTruckThick),
        },
        {
          title: 'Reserves',
          link: '/reserves',
          icon: markRaw(IconBallot),
        },
        {
          title: 'Invoices',
          link: '/invoices',
          icon: markRaw(IconDollarInCircle),
        },
        {
          title: 'BioIVT Replenishment Program',
          link: '/replenishment-program?tab=order-template',
          icon: markRaw(IconTablet),
        },
        {
          title: 'Settings',
          link: '/settings',
          icon: markRaw(IconGear),
        },
        {
          title: 'Log Out',
          link: '/logout',
          icon: markRaw(IconExit),
          topBorder: true,
        },
      ],
      guestLabelText: this.$t('header.contacts.sign'),
      userLabelText: this.$t('header.contacts.signLoggedIn'),
      userIcon: userIcon,
      isDrawerVisible: false,
    }
  },
  computed: {
    ...mapState('reserve-orders', ['isReserveOrdersExist']),
    ...mapGetters('session', ['user', 'isUserLoggedIn', 'isInventoryTrackingVisible']),
    availableDrawerMenuItems() {
      return this.drawerMenuItems.filter((item) => {
        if (item.title === 'Reserves') return this.isReserveOrdersExist
        if (item.title === 'BioIVT Replenishment Program') return this.isInventoryTrackingVisible
        return true
      })
    },
    dashboardDrawerItem() {
      return this.availableDrawerMenuItems.find((item) => item.title === 'Dashboard')
    },
  },
  methods: {
    handleLabelHover(value) {
      if (!this.isMobileView) this.toggleDrawer(value)
    },
    onLabelClick() {
      if (this.isUserLoggedIn) {
        if (this.isMobileView && this.isUserLoggedIn) this.toggleDrawer()
        this.$emit('labelClicked')
      } else {
        this.$router.push('/authentication')
        this.$emit('linkClicked')
        this.isDrawerVisible = false
      }
    },
    toggleDrawer(value) {
      this.isDrawerVisible = typeof value === 'boolean' ? value : !this.isDrawerVisible
    },
    onDrawerItemClick(item) {
      this.$router.push(item.link)
      this.$emit('linkClicked')
      this.isDrawerVisible = false
    },
  },
}
</script>
