<template>
  <span v-if="tmpl" :class="className" v-html="tmpl"></span>
</template>
<script>
export default {
  props: {
    tmpl: {
      type: String,
      default: '',
    },
    className: {
      type: String,
      default: '',
    },
  },
}
</script>
