import algoliaInsights from 'search-insights'

import api from '@/lib/api'

import settings from '@/settings'

if (settings.services.google.gtm) {
  window.dataLayer = window.dataLayer || []
  window.gtag = function gtag() {
    window.dataLayer.push(arguments)
  }

  window.dataLayer.push({
    'gtm.start': new Date().getTime(),
    event: 'gtm.js',
  })

  const gtmScript = document.createElement('script')
  gtmScript.src = `https://www.googletagmanager.com/gtm.js?id=${settings.services.google.gtm}`
  gtmScript.async = true
  gtmScript.type = 'text/javascript'

  document.head.appendChild(gtmScript)

  const noScriptWrapper = document.createElement('noscript')
  const gtmIframe = document.createElement('iframe')
  gtmIframe.src = `https://www.googletagmanager.com/ns.html?id=${settings.services.google.gtm}`
  gtmIframe.height = 0
  gtmIframe.width = 0
  gtmIframe.style.display = 'none'
  gtmIframe.style.visibility = 'hidden'

  noScriptWrapper.appendChild(gtmIframe)
  document.body.appendChild(noScriptWrapper)
}

if (settings.services.google.gtm) {
  window.addEventListener('message', (event) => {
    if (event.origin !== 'https://forms.hsforms.com') return
    if (event.origin !== 'https://app.hubspot.com') return
    if (event.origin !== 'https://bioivt.com/') return
    if (event.origin !== 'https://zcom-stage.e.bioivt.com') return
    if (event.origin !== 'https://zcom-qa.e.bioivt.com') return
    if (event.origin !== 'https://zcom-dev.e.bioivt.com') return

    if (event.data?.type === 'hsFormCallback' && event.data?.eventName === 'onFormSubmitted') {
      const label = window.document.title.split('|')[0].trim()
      AnalyticsService.sendContact(label)
    }
  })
}

if (settings.services.algolia.analytics.enabled) {
  algoliaInsights('init', {
    appId: settings.services.algolia.appId,
    apiKey: settings.services.algolia.accessToken,
    useCookie: true,
  })
}

const AnalyticsService = {
  visitedSearchEntries: [],
  sendPageView() {
    const path = document.location.pathname + document.location.search

    if (window._hsq) {
      window._hsq.push(['setPath', path])
      window._hsq.push(['trackPageView'])
    }

    if (settings.services.google.gtm) {
      window.dataLayer.push({
        event: 'Page Loaded',
      })
      if (settings.services.google.adsToken)
        window.gtag('config', settings.services.google.adsToken)
    }
  },
  sendContact(label) {
    if (window.gtag && settings.services.google.gtm) {
      window.gtag('event', 'Submit', {
        event_category: 'Contact',
        event_label: label,
      })
    }
  },
  sendQuoteRequestCustomEvent({ quoteId }) {
    if (settings.services.google.gtm) {
      window.dataLayer.push({
        event: 'custom_Event',
        event_category: 'Contact',
        event_action: 'Submit',
        event_label: 'Request A Quote',
        quote_id: quoteId,
      })
    }
  },
  sendQuoteRequestEvent({ quoteId, currency, revenue, products }) {
    if (settings.services.google.gtm) {
      window.dataLayer.push({
        event: 'quote_request',
        ecommerce: {
          currencyCode: currency,
          purchase: {
            actionField: {
              id: quoteId,
              revenue,
            },
            products,
          },
        },
      })
    }
  },
  sendAddToCartEvent({ list, products }) {
    if (settings.services.google.gtm) {
      window.dataLayer.push({
        event: 'eec.add',
        ecommerce: {
          add: {
            actionField: {
              list,
            },
          },
          products,
        },
      })
    }
  },
  sendAdsConversion() {
    if (
      window.gtag &&
      settings.services.google.gtm &&
      settings.services.google.adsToken &&
      settings.services.google.adsLabel
    ) {
      window.gtag('event', 'conversion', {
        send_to: settings.services.google.adsToken + '/' + settings.services.google.adsLabel,
        value: 1.0,
        currency: 'USD',
      })
    }
  },
  sendSearchClick(params, item) {
    if (settings.services.algolia.analytics.enabled && params && params.objectIDs) {
      algoliaInsights('clickedObjectIDsAfterSearch', {
        eventName: 'item_click_on_search_results',
        index: params.indexName,
        queryID: params.queryID,
        objectIDs: params.objectIDs,
        positions: params.positions,
      })
      this.visitedSearchEntries.push({
        id: params.objectIDs[0],
        slug: item.slug,
        queryID: params.queryID,
      })
    }
  },
  sendInventoryClick(params) {
    if (settings.services.algolia.analytics.enabled && params?.objectIDs) {
      algoliaInsights('clickedObjectIDsAfterSearch', {
        eventName: 'item_click_on_inventory_results',
        index: settings.services.algolia.inventoryIndex,
        queryID: params.queryID,
        objectIDs: params.objectIDs,
        positions: params.positions,
      })
    }
  },
  sendInventoryQuoteConversion(params) {
    if (settings.services.algolia.analytics.enabled && params?.objectIDs) {
      algoliaInsights('convertedObjectIDsAfterSearch', {
        index: settings.services.algolia.primaryIndex,
        eventName: 'item_added_to_quote_on_inventory_results',
        queryID: params.queryID,
        objectIDs: params.objectIDs,
      })
    }
  },
  sendInventoryConversionData(data) {
    if (settings.services.hubspot.analytics.enabled) {
      api.user.sendInventoryConversionData(data)
    }
  },
  sendInventorySearchQueryAnalytics(queryString) {
    if (settings.services.hubspot.analytics.enabled) {
      api.user.sendInventoryQueryAnalyticsData(queryString)
    }
  },
  sendSearchQuoteConversion(productSlugs) {
    if (settings.services.algolia.analytics.enabled && productSlugs && productSlugs.length) {
      const conversionObjects = this.visitedSearchEntries
        .filter((el) => productSlugs.find((item) => item.includes(el.slug)))
        .map((el) => {
          return { id: el.id, queryID: el.queryID }
        })

      conversionObjects.forEach((object) => {
        if (object.id && object.queryID) {
          algoliaInsights('convertedObjectIDsAfterSearch', {
            index: settings.services.algolia.primaryIndex,
            eventName: 'quote_request_with_search_results',
            queryID: object.queryID,
            objectIDs: [object.id],
          })
        }
      })
      this.visitedSearchEntries = []
    }
  },
  sendUserLogginAnalytics({ userId }) {
    if (settings.services.google.gtm) {
      window.dataLayer.push({
        event: 'login',
        userId,
      })
    }
  },
  sendUserLogoutAnalytics() {
    if (settings.services.google.gtm) {
      window.dataLayer.push({
        event: 'login',
        userId: null,
      })
    }
  },
}

export default AnalyticsService
