import { nextTick } from 'vue'

export default {
  computed: {
    isDesktop() {
      return this.screenWidth >= 1180
    },
    isTablet() {
      return this.screenWidth < 1180
    },
    isMobile() {
      return this.screenWidth < 668
    },
  },
  methods: {
    calculateScreenWidth() {
      this.screenWidth = window.innerWidth
    },
  },
  mounted() {
    this.screenWidth = window.innerWidth
    nextTick(() => {
      window.addEventListener('resize', this.calculateScreenWidth)
    })
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.calculateScreenWidth)
  },
  data() {
    return {
      screenWidth: '',
    }
  },
}
