import contentfulComponentMixin from '@/components/common/contentful/mixins/contentfulComponentMixin'

export default {
  mixins: [contentfulComponentMixin],
  computed: {
    isPunchout() {
      return this.$store.state.punchout.isPunchoutSession
    },
    isTopFrame() {
      return window.self === window.top
    },
    getPunchout() {
      return this.$contentful.service.getPunchout()
    },
    getPunchoutTitle() {
      return this.getPunchout.title
    },
    getPunchoutLogo() {
      return {
        url: this.getImageUrl(this.getPunchout.logo)
          ? this.getImageUrl(this.getPunchout.logo, 80, '', 65)
          : null,
        alt: this.getImageAlt(this.getPunchout.logo),
      }
    },
    getProcurementSiteName() {
      const siteName = this.$store.state.punchout.punchoutRedirectUrl?.match(/^[^.]+\.(.+\..+)$/)
      return siteName ? siteName[1] : 'your procurement system'
    },
  },
}
