import algoliasearch from 'algoliasearch/lite'

import settings from '@/settings'

const client = algoliasearch(settings.services.algolia.appId, settings.services.algolia.accessToken)
export default {
  client: client,
  service: {
    getPrimaryIndexNameBySorting(sorting = 'relevance') {
      const indicesNamesBySorting = {
        relevance: settings.services.algolia.primaryIndex,
        alphaAsc: settings.services.algolia.primaryIndexAlphaAsc,
        alphaDesc: settings.services.algolia.primaryIndexAlphaDesc,
        featured: settings.services.algolia.primaryIndexFeatured,
      }
      return indicesNamesBySorting[sorting] || indicesNamesBySorting.relevance
    },
    getPrimaryIndex(sorting) {
      const indexName = this.getPrimaryIndexNameBySorting(sorting)
      return client.initIndex(indexName)
    },
    getResourceIndexNameBySorting(sorting = 'relevance') {
      const indicesNamesBySorting = {
        relevance: settings.services.algolia.resourceIndex,
        alphaAsc: settings.services.algolia.resourceIndexAlphaAsc,
        alphaDesc: settings.services.algolia.resourceIndexAlphaDesc,
      }
      return indicesNamesBySorting[sorting] || indicesNamesBySorting.relevance
    },
    getResourceIndex(sorting) {
      const indexName = this.getResourceIndexNameBySorting(sorting)
      return client.initIndex(indexName)
    },
    getProductIndexNameBySorting(sorting = 'relevance') {
      const indicesNamesBySorting = {
        relevance: settings.services.algolia.productIndex,
        alphaAsc: settings.services.algolia.productIndexAlphaAsc,
        alphaDesc: settings.services.algolia.productIndexAlphaDesc,
      }
      return indicesNamesBySorting[sorting] || indicesNamesBySorting.relevance
    },
    getProductIndex(sorting) {
      const indexName = this.getProductIndexNameBySorting(sorting)
      return client.initIndex(indexName)
    },
    getInventoryIndex() {
      return client.initIndex(settings.services.algolia.inventoryIndex)
    },
  },
}
