import _ from 'lodash'

export default {
  methods: {
    pushDataToForm(data) {
      if (data) {
        Object.values(this.form).forEach((field) => {
          if (Object.keys(data).includes(field.title)) {
            field.value = data[field.title]
          }
        })
      }
    },
    pushFormToData(data) {
      Object.values(this.form).forEach((field) => {
        if (field.title) {
          data[field.title] = field.value
        }
      })
    },
    mapFormErrors(errors) {
      if (errors && errors.length) {
        this.clearFormServerErrors()
        errors.forEach((error) => {
          const field = _.find(this.form, ['title', error.subject])
          if (field) {
            delete field.errors.server
            field.errors = { ...field.errors, server: error.message }
          } else {
            if (!error.message) error.message = 'Something went wrong'
            this.form._errors.push(error)
          }
        })
      }
    },
    mapFormValidationErrors(errors) {
      if (Array.isArray(errors)) {
        const filteredErrors = errors.filter((error) =>
          ['ValidationError', 'invalid_grant', 'unauthorized'].includes(error.type),
        )
        this.mapFormErrors(filteredErrors)
      }
    },
    onFieldChange(field) {
      this.v$.$touch()
      if (field && field.errors) {
        this.clearFieldServerError(field)
      }
    },
    onFieldChangeGuestCheckout(fieldName, field) {
      const formField = this.v$.form[fieldName]
      if (formField) formField.$touch()
      if (field && field.errors) {
        this.clearFieldServerError(field)
      }
    },
    clearFieldServerError(field) {
      delete field.errors.server
    },
    clearFormServerErrors() {
      this.form._errors = []
    },
    withPresendRoutine(cb) {
      this.clearFormServerErrors()
      if (this.isModelValid) {
        return cb()
      } else {
        this.isValidationRun = true
      }
    },
    updatePhoneMask(phone, countryIsoCode) {
      if (!countryIsoCode || !phone?.mask) return
      phone.mask = ['US', 'CA'].includes(countryIsoCode) ? '(###) ### - ####' : '+(##) ##-#### ####'
    },
    sendForm(action, data, asyncCallback) {
      this.isLoaderVisible = true
      this.isValidationRun = true
      return new Promise((resolve, reject) => {
        action(data)
          .then(async (res) => {
            if (asyncCallback) await asyncCallback()
            this.isValidationRun = false
            resolve(res)
          })
          .catch((reason) => {
            if (reason.response && reason.response.data && reason.response.data.length) {
              this.mapFormValidationErrors(reason.response?.data)
            }
            reject(reason)
          })
          .finally(() => {
            this.isLoaderVisible = false
          })
      })
    },
  },
  computed: {
    isModelValid() {
      return !this.v$.$invalid
    },
  },
  data() {
    return {
      isLoaderVisible: false,
    }
  },
}
