export default {
  industries: {
    title: 'Industry',
    sortOrder: 5,
    widgetType: 'checkbox',
  },
  'types.lvl0': {
    title: 'Products or Services',
    widgetType: 'checkbox',
    sortOrder: 1,
    isOpen: true,
  },
  'types.lvl1': {
    title: 'Products or Services',
    widgetType: 'checkbox',
  },
  'types.lvl2': {
    title: 'Products or Services',
    widgetType: 'checkbox',
  },
  applications: {
    title: 'Application Area',
    sortOrder: 4,
    widgetType: 'checkbox',
  },
  species: {
    title: 'Species',
    sortOrder: 2,
    widgetType: 'checkbox',
    isOpen: false,
  },
  'species.lvl0': {
    title: 'Species',
    sortOrder: 2,
    widgetType: 'checkbox',
    isOpen: false,
  },
  'species.lvl1': {
    title: 'Species',
    widgetType: 'checkbox',
    isOpen: false,
  },
  resourceGroup: {
    title: 'Resources',
    widgetType: 'checkbox',
  },
  'diseaseFocuses.lvl0': {
    title: 'Disease Focus',
    sortOrder: 3,
    widgetType: 'checkbox',
  },
  'diseaseFocuses.lvl1': {
    title: 'Disease Focus',
    widgetType: 'checkbox',
  },
  areaOfInterest: {
    isHidden: true,
  },
  'speciesFacet.lvl0': {
    title: 'Species',
    sortOrder: 2,
    widgetType: 'checkbox',
    isOpen: false,
  },
  'speciesFacet.lvl1': {
    title: 'Species',
    widgetType: 'checkbox',
    isOpen: false,
  },
}
