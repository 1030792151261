<script>
import { h } from 'vue'

export default {
  name: 'Renderer',
  props: {
    tmpl: {
      type: String,
      default: '',
    },
    wrapper: {
      type: String,
      default: 'div',
    },
    className: {
      type: String,
      default: '',
    },
    addWrapClass: {
      type: Boolean,
      default: true,
    },
    renderRaw: {
      type: Boolean,
      default: false,
    },
  },
  render() {
    const template = this.renderRaw
      ? this.tmpl
      : `<${this.wrapper} class="${this.addWrapClass ? 'rich-text-wrapper ' : ''}${
          this.className
        }">${this.tmpl}</${this.wrapper}>`
    return template ? h({ template }) : ''
  },
}
</script>
