const state = {
  previousRoute: null,
  isInitialized: false,
  isContentfulInitialized: false,
  isLoaderVisible: false,
  newsletterMail: '',
  isFooterQuoteVisible: true,
  isEmergencyPopupVisible: true,
  isBackToTopBtnVisible: false,
  criticalViewData: null,
  notAuthorizedErrors: [],
  headerSearchQuery: '',
  isContentfulPreview: false,
}

const mutations = {
  SET_PREVIOUS_ROUTE(state, route) {
    state.previousRoute = route
  },
  SET_INITIALIZED(state, value) {
    state.isInitialized = value
  },
  SET_CONTENTFUL_INITIALIZED(state, value) {
    state.isContentfulInitialized = value
  },
  SET_NEWSLETTER_MAIL(state, value) {
    state.newsletterMail = value
  },
  TOGGLE_LOADER(state, value) {
    state.isLoaderVisible = value
    if (value === true) {
      document.body.classList.add('overflow-hidden')
    } else if (value === false) {
      document.body.classList.remove('overflow-hidden')
    }
  },
  TOGGLE_FOOTER_QUOTE(state, isFooterQuoteVisible) {
    state.isFooterQuoteVisible = isFooterQuoteVisible
  },
  TOGGLE_EMERGENCY_POPUP(state, isEmergencyPopupVisible) {
    state.isEmergencyPopupVisible = isEmergencyPopupVisible
  },
  TOGGLE_BACK_TO_TOP_BTN(state, isBackToTopBtnVisible) {
    state.isBackToTopBtnVisible = isBackToTopBtnVisible
  },
  SET_CRITICAL_VIEW_DATA(state, data) {
    state.criticalViewData = data
  },
  SET_NOT_AUTORIZED_ERRORS(state, errors) {
    state.notAuthorizedErrors = errors
  },
  SET_HEADER_SEARCH_QUERY(state, query = '') {
    state.headerSearchQuery = query
  },
  SET_IS_CONTENTFUL_PREVIEW(state, isContentfulPreview) {
    state.isContentfulPreview = isContentfulPreview
  },
}

const actions = {
  setPreviousRoute({ commit }, route) {
    // Circular structure issue fix
    commit('SET_PREVIOUS_ROUTE', {
      query: route.query,
      params: route.params,
      name: route.name,
      hash: route.hash,
      meta: route.meta,
      fullPath: route.fullPath,
    })
  },
  setInitialized({ commit }, value) {
    commit('SET_INITIALIZED', value)
  },
  setNewsletterMail({ commit }, value) {
    commit('SET_NEWSLETTER_MAIL', value)
  },
  toggleLoader({ commit }, value) {
    commit('TOGGLE_LOADER', value)
  },
  toggleFooterQuote({ commit }, isFooterQuoteVisible) {
    commit('TOGGLE_FOOTER_QUOTE', isFooterQuoteVisible)
  },
  toggleEmergencyPopup({ commit }, isEmergencyPopupVisible) {
    commit('TOGGLE_EMERGENCY_POPUP', isEmergencyPopupVisible)
  },
  toggleBackToTopBtn({ commit }, isBackToTopBtnVisible) {
    commit('TOGGLE_BACK_TO_TOP_BTN', isBackToTopBtnVisible)
  },
  setCriticalViewData({ commit }, data) {
    commit('SET_CRITICAL_VIEW_DATA', data)
  },
}

export default {
  state,
  mutations,
  actions,
  namespaced: true,
}
