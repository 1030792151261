<template>
  <svg width="25" height="24" viewBox="0 0 25 24" :fill="color" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15.4414 4H19.7966C20.9427 4 21.8805 4.9 21.8805 6V20C21.8805 21.1 20.9427 22 19.7966 22H5.20981C5.06394 22 4.92849 21.99 4.79304 21.97C4.3867 21.89 4.02202 21.69 3.74071 21.42C3.55316 21.23 3.39687 21.02 3.29268 20.78C3.18849 20.54 3.12598 20.27 3.12598 20V6C3.12598 5.72 3.18849 5.46 3.29268 5.23C3.39687 4.99 3.55316 4.77 3.74071 4.59C4.02202 4.32 4.3867 4.12 4.79304 4.04C4.92849 4.01 5.06394 4 5.20981 4H9.56502C10.0026 2.84 11.1487 2 12.5032 2C13.8577 2 15.0038 2.84 15.4414 4ZM17.7128 8V10H7.29364V8H17.7128ZM7.29364 12V14H17.7128V12H7.29364ZM14.5871 16H7.29364V18H14.5871V16ZM12.5032 3.75C12.9304 3.75 13.2847 4.09 13.2847 4.5C13.2847 4.91 12.9304 5.25 12.5032 5.25C12.076 5.25 11.7218 4.91 11.7218 4.5C11.7218 4.09 12.076 3.75 12.5032 3.75ZM5.20981 20H19.7966V6H5.20981V20Z"
    />
  </svg>
</template>
<script>
export default {
  name: 'IconTablet',
  props: {
    color: {
      type: String,
      default: '#000',
    },
  },
}
</script>
