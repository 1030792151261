import api from '@/lib/api'

import {
  transformOrderTemplate,
  transformApiFormErrors,
} from '@/components/cart/helpers/transformHelpers'

const _defaultOrderTemplateData = {
  id: '',
  active: true,
  b2bUnit: '',
  b2bUnitName: '',
  department: {},
  billTo: '',
  billToAddress: {},
  billToAddressString: '',
  billingContact: '',
  billingEmails: [],
  contact: '',
  contactName: '',
  contactEmail: '',
  name: '',
  expirationDate: '',
  notes: '',
  poNumber: '',
  shipTo: '',
  shipToAddress: {},
  shipToAddressString: '',
  shippingContact: '',
  shippingEmails: [],
}

const _defaultState = {
  modes: {
    new: 'NEW',
    edit: 'EDIT',
    copy: 'COPY',
  },
  activeMode: null,
  orderTemplateFormData: Object.assign({}, _defaultOrderTemplateData),
  orderTemplatesTabData: [],
  pagination: {},
}
const getDefaultState = () => JSON.parse(JSON.stringify(_defaultState))
const state = getDefaultState()

const mutations = {
  SET_ORDER_TEMPLATES_TAB_DATA(state, { orderTemplatesTabData, pagination }) {
    state.orderTemplatesTabData = orderTemplatesTabData
    state.pagination = pagination
  },
  SET_ORDER_TEMPLATE_FORM_DATA(state, data) {
    state.orderTemplateFormData = data
  },
  SET_ACTIVE_MODE(state, mode) {
    state.activeMode = mode
  },
  RESET_ORDER_TEMPLATES_TAB_DATA(state) {
    state.orderTemplatesTabData = []
    state.pagination = {}
  },
  RESET_STATE(state) {
    Object.assign(state, getDefaultState())
  },
}

const actions = {
  async getOrderTemplatesTabData({ commit }, filters) {
    const response = await api.inventoryTracking.getOrderTemplates(filters)
    const { orderTemplates, pagination } = response.data
    const transformedOrderTemplates = orderTemplates.map(transformOrderTemplate)
    const orderTemplatesTabData =
      filters.page > 0
        ? [...state.orderTemplatesTabData, ...transformedOrderTemplates]
        : transformedOrderTemplates
    commit('SET_ORDER_TEMPLATES_TAB_DATA', {
      orderTemplatesTabData,
      pagination,
    })
    return response.data.pagination
  },
  async getCompanyOrderTemplates(context, { company = '', department = '' }) {
    const response = await api.inventoryTracking.getOrderTemplates({
      pageSize: 9999,
      company,
      department,
    })
    const { orderTemplates } = response.data
    return orderTemplates.map(transformOrderTemplate)
  },
  setupOrderTemplateForm({ state, commit, rootGetters }, { mode, orderTemplateData }) {
    const userCompany = {
      code: rootGetters['session/user'].unit?.uid,
      name: rootGetters['session/user'].unit?.name,
    }
    const formData =
      mode === state.modes.new
        ? {
            ..._defaultOrderTemplateData,
            b2bUnit: userCompany,
            billTo: userCompany,
            shipTo: userCompany,
          }
        : { ...orderTemplateData }
    commit('SET_ORDER_TEMPLATE_FORM_DATA', formData)
    commit('SET_ACTIVE_MODE', mode)
  },
  async sendOrderTemplate({ state }, orderTemplateData) {
    const handlers = {
      [state.modes.new]: api.inventoryTracking.addNewOrderTemplate,
      [state.modes.copy]: api.inventoryTracking.addNewOrderTemplate,
      [state.modes.edit]: api.inventoryTracking.updateOrderTemplate,
    }

    try {
      await handlers[state.activeMode](orderTemplateData)
    } catch (error) {
      const transformedError = new Error('Threshold validation error')
      transformedError.response = {
        data: transformApiFormErrors(error.response.data),
      }
      throw transformedError
    }
  },
  async validateOrderTemplate(context, thresholdData) {
    try {
      await api.inventoryTracking.validateOrderTemplate(thresholdData)
      return { ok: true }
    } catch (error) {
      return {
        ok: false,
        errors: transformApiFormErrors(error.response.data),
      }
    }
  },
  resetOrderTemplatesTabData({ commit }) {
    commit('RESET_ORDER_TEMPLATES_TAB_DATA')
  },
}

const getters = {}

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true,
}
