import { nextTick } from 'vue'

import anchor from '@/lib/utils/anchor'

export default {
  mixins: [anchor],
  emits: ['itemClicked'],
  methods: {
    anchorToEntry(arr) {
      if (arr && arr.length) {
        nextTick(() => {
          const item = arr.find((el) => el.title.trim() === this.$route.query.entry)
          setTimeout(() => this.anchorTo(item?.title), 1000)
        })
      }
    },
    navigateTo(link, target = '_self') {
      const redirect = this.$contentful.service.getRedirect(link)
      const navigateToUrl = redirect || link
      const isWindowNavigation =
        redirect ||
        target === '_blank' ||
        link.includes('blog.bioivt.com') ||
        link.startsWith('http')
      if (isWindowNavigation) {
        const link = document.createElement('a')
        link.href = navigateToUrl
        link.target = target
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      } else {
        this.$router.push(navigateToUrl)
        this.$emit('itemClicked')
      }
    },
    goBack() {
      const prevRoute = this.$store.state.app.previousRoute
      this.$router.push(prevRoute.fullPath || '/')
    },
  },
}
