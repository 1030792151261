<template>
  <div v-if="path && path.length" class="breadcrumbs-control container">
    <ul class="breadcrumbs">
      <li v-if="displayHome">
        <router-link :to="rootRoute.path">
          {{ homeTitle }}
        </router-link>
      </li>
      <li v-for="(item, n) in path" :key="n">
        <router-link
          v-if="item.link && item.link.length > 0 && n < path.length - 1"
          :to="item.link"
        >
          {{ item.name }}
        </router-link>
        <span v-else v-html="item.name" />
      </li>
    </ul>
  </div>
</template>
<script>
import { mapState } from 'vuex'

import routes from '@/lib/utils/routes'

export default {
  name: 'Breadcrumbs',
  props: {
    displayHome: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapState('breadcrumbs', ['path']),
    rootRoute() {
      return routes.getRoot()
    },
    homeTitle() {
      const route = this.rootRoute
      if (!route) {
        return this.$t('common.home')
      }
      return routes.getCaptionByRouteName(route.name)
    },
  },
}
</script>
