import api from '@/lib/api'

const _defaultState = {
  tableOrdersForWidget: [],
  totalOpenOrders: 0,
  documentTypes: 'INVOICES,PACKING_LISTS,RMA,PROJECT_BRIEFS',
}
const getDefaultState = () => JSON.parse(JSON.stringify(_defaultState))
const state = getDefaultState()

const mutations = {
  SET_ORDERS_FOR_WIDGET(state, { orders, totalOpenOrders }) {
    state.tableOrdersForWidget = orders
    state.totalOpenOrders = totalOpenOrders
  },
  RESET_STATE(state) {
    Object.assign(state, getDefaultState())
  },
}

const actions = {
  async getOrderDetails(context, orderCode) {
    const { data: orderDetailsData } = await api.orders.getOrderById(orderCode)
    return orderDetailsData
  },
  async getOrdersForWidget({ state, commit }) {
    const response = await api.orders.getOrders({
      sort: 'byDateDESC',
      pageSize: 5,
      statuses: 'Partially Shipped,Processing',
      orderDocumentTypes: state.documentTypes,
      statisticsByStatuses: true,
      showCompanyOrders: true,
    })
    const { orders = [], statisticsByStatuses = [] } = response.data
    const totalPartiallyShippedOrders =
      statisticsByStatuses.find((el) => el.name === 'Partially Shipped')?.count || 0
    const totalProcessingOrders =
      statisticsByStatuses.find((el) => el.name === 'Processing')?.count || 0
    const totalOpenOrders = totalPartiallyShippedOrders + totalProcessingOrders
    commit('SET_ORDERS_FOR_WIDGET', { orders, totalOpenOrders })
  },
}

export default {
  state,
  mutations,
  actions,
  namespaced: true,
}
