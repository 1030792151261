import ApiBase from '@/lib/api/v1/api-base'

class Products extends ApiBase {
  constructor(transport) {
    super(transport)
    this.getProduct = this.getProduct.bind(this)
    this.getProductsPrices = this.getProductsPrices.bind(this)
  }

  /**
   * Get product by SKU.
   */
  getProduct(productData, inventoryId = '') {
    const inventoryQuery = inventoryId ? `?inventoryId=${inventoryId}` : inventoryId
    const separator = inventoryId ? '&' : '?'
    const pricingQuery = productData.salesToStock
      ? `salesToStock=${productData.salesToStock}&aliqPerLot=${productData.aliqPerLot}&volPerAliq=${productData.volPerAliq}`
      : ''
    return this._withDecoratedPromise(() => {
      return this.transport.get(
        `/rest/api/v2/products/${encodeURIComponent(
          productData.code,
        )}${inventoryQuery}${separator}${pricingQuery}`,
        productData,
      )
    })
  }

  getProductsPrices(data) {
    return this._withDecoratedPromise(() => {
      return this.transport.post('/rest/api/v2/products/prices', data)
    })
  }
}

export default Products
