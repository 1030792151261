<template>
  <div class="mobile-menu">
    <ul class="mobile-menu__inner">
      <li class="mobile-menu__item">
        <my-account-menu
          ref="myAccountMenu"
          :isMobileView="true"
          @linkClicked="onMyAccountLinkClicked"
          @labelClicked="onMyAccountLabelClicked"
        />
      </li>
      <li
        v-for="menuItem in getMenuItems"
        :key="menuItem.title"
        :class="{ 'mobile-menu__item--open': menuItem.isOpen }"
        class="mobile-menu__item"
      >
        <div
          v-if="!activeSubMenu"
          class="mobile-menu__label mobile-menu__label--root"
          @click="onRootItemClick(menuItem)"
        >
          <span>{{ menuItem.title }}</span>
        </div>
        <template v-else>
          <div class="mobile-menu__label mobile-menu__label--nested">
            <a
              :href="activeSubMenu.parentLink"
              class="mobile-menu__label-back"
              @click.prevent="setPrevSubMenu"
            >
              <i class="icon-arrow mobile-menu__label-back-icon" />
              <span class="mobile-menu__label-back-text">{{ activeSubMenu.backSubMenuLabel }}</span>
            </a>
            <a
              :href="activeSubMenu.parentLink"
              class="mobile-menu__parent-title"
              @click.prevent="onLinkClick(activeSubMenu.parentLink)"
              v-html="activeSubMenu.parentTitle"
            />
          </div>
          <ul
            :class="{ 'mobile-menu__nested-list--active': activeSubMenu }"
            class="mobile-menu__nested-list"
          >
            <li
              v-if="menuItem.slug === 'products' && isUserLoggedIn"
              class="mobile-menu__nested-item mobile-menu__nested-item--filled"
              href="/search?tab=myProducts"
              @click.prevent="onLinkClick('/search?tab=myProducts')"
            >
              <span>{{ myProductsLabel }}</span>
              <i class="icon-arrow" />
            </li>
            <li
              :class="{
                'mobile-menu__nested-item--active': checkLinkActiveness(activeSubMenu.parentLink),
              }"
              class="mobile-menu__nested-item mobile-menu__nested-item--icon"
              @click.prevent="onLinkClick(activeSubMenu.parentLink)"
            >
              <span>All {{ activeSubMenu.parentTitle }}</span>
              <i class="icon-arrow" />
            </li>
            <li
              v-for="subitem in getRootSubitems"
              :key="subitem.title"
              :class="{
                'mobile-menu__nested-item--active': checkLinkActiveness(subitem.link),
              }"
              class="mobile-menu__nested-item"
              @click="onNestedItemClick(subitem)"
            >
              {{ subitem.title }}
            </li>
            <li
              v-if="getGroupedSubitemsTitle"
              class="mobile-menu__nested-item mobile-menu__nested-item--grouper"
              v-html="getGroupedSubitemsTitle"
            />
            <li
              v-for="subitem in getGroupedSubitems"
              :key="subitem.title"
              :class="{
                'mobile-menu__nested-item--active': checkLinkActiveness(subitem.link),
              }"
              class="mobile-menu__nested-item"
              @click="onNestedItemClick(subitem)"
            >
              {{ subitem.title }}
            </li>
          </ul>
        </template>
      </li>
      <li v-if="showInventory" class="mobile-menu__item">
        <div
          class="mobile-menu__label mobile-menu__label--root"
          @click="onLinkClick(inventoryButton.url)"
        >
          <span v-html="inventoryButton.title" />
        </div>
      </li>
      <li class="mobile-menu__item">
        <div
          class="mobile-menu__label mobile-menu__label--teal mobile-menu__label--root"
          @click="onLinkClick('/about/contact-us')"
        >
          <span v-html="$t('header.contacts.link')" />
        </div>
      </li>
    </ul>
    <div class="mobile-menu__bottom" />
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

import contentfulAutowireGlobalMixin from '@/components/common/contentful/mixins/contentfulAutowireGlobalMixin'
import contentfulComponentMixin from '@/components/common/contentful/mixins/contentfulComponentMixin'
import navigationMixin from '@/components/common/mixins/navigationMixin'

import shelfIcon from '@/assets/images/icons/shelf.svg'
import userIcon from '@/assets/images/icons/user-mobile.svg'

import MyAccountMenu from '@/components/common/AppHeader/MyAccountMenu.vue'

export default {
  name: 'MobileMenu',
  components: {
    MyAccountMenu,
  },
  mixins: [contentfulAutowireGlobalMixin, navigationMixin, contentfulComponentMixin],
  emits: ['linkClicked'],
  data() {
    return {
      menuItems: [],
      subMenusStack: [],
      shelfIcon: shelfIcon,
      userIcon: userIcon,
      myProductsLabel:
        this.getSiteCopy('copyGroupCommon', 'copyItemSearchPageMegamenuMyProductsButton') ||
        this.$t('common.myProducts'),
    }
  },
  computed: {
    ...mapGetters('session', ['isUserLoggedIn']),
    getMenuItems() {
      return this.isUserLoggedIn
        ? this.menuItems.filter((el) => el && el.isVisible)
        : this.menuItems.filter((el) => el && el.isVisible && el.slug !== 'inventory')
    },
    showInventory() {
      return this.subMenusStack?.[0]?.slug === 'products'
    },
    inventoryButton() {
      return {
        title: this.isUserLoggedIn
          ? this.$t('inventory.browseSpecimenInventory')
          : this.$t('inventory.createAccountInventory'),
        url: '/inventory',
      }
    },
    activeSubMenu() {
      return this.subMenusStack[this.subMenusStack.length - 1]
    },
    getRootSubitems() {
      return this.activeSubMenu.subitems.filter((category) => !category.group)
    },
    getGroupedSubitems() {
      return this.activeSubMenu.subitems.filter((category) => category.group)
    },
    getGroupedSubitemsTitle() {
      return this.activeSubMenu.subitemsGroupedTitle
    },
  },
  watch: {
    subMenusStack: {
      handler(value) {
        this.menuItems.forEach((menuItem) => {
          menuItem.isVisible = !value.length || value[0].slug === menuItem.slug
        })
      },
      deep: true,
    },
  },
  methods: {
    onRootItemClick(item) {
      this.$refs.myAccountMenu?.toggleDrawer(false)
      if (item.hasSubmenu) {
        this.setNextSubMenu(item)
      } else {
        this.onLinkClick(item.link)
      }
    },
    setNextSubMenu(item) {
      this.subMenusStack.push({
        slug: item.slug,
        parentTitle: item.title,
        parentLink: item.link,
        backSubMenuLabel: item.backSubMenuLabel,
        subitems: item.subitems,
        subitemsGroupedTitle: item.subitemsGroupedTitle,
      })
    },
    setPrevSubMenu() {
      this.subMenusStack.pop()
    },
    onLinkClick(link) {
      this.navigateTo(link)
      this.resetSubMenuStack()
      this.$emit('linkClicked')
    },
    resetSubMenuStack() {
      this.subMenusStack = []
    },
    onNestedItemClick(item) {
      if (item.hasSubmenu && item.subitems?.length) {
        this.setNextSubMenu(item)
      } else {
        this.onLinkClick(item.link)
      }
    },
    onMyAccountLabelClicked() {
      this.resetSubMenuStack()
    },
    onMyAccountLinkClicked() {
      this.resetSubMenuStack()
      this.$emit('linkClicked')
    },
    initData(entries) {
      this.menuItems = entries
        .map((entry) => {
          const page = this.$contentful.service.getPageBySlug(
            entry.fields?.catalogRoot.fields?.slug,
          )
          if (page) {
            const subpages = page.fields?.subpages?.filter((el) => el.fields) || []
            const rawSubpagesGrouped = page.fields?.subpagesGrouped?.filter((el) => el.fields) || []
            const subpagesGrouped = rawSubpagesGrouped.map((el) => ({
              ...el,
              group: page.fields.subpagesGroupedTitle || '',
            }))
            const rawSubpages = [...subpages, ...subpagesGrouped]
            const filteredSubitems = rawSubpages.filter(
              (category) => category?.fields && category.fields.title !== 'Services',
            )
            const subitems = filteredSubitems.map((category) => {
              const slug = category.fields.slug
              return {
                slug,
                title: category.fields.title,
                link: category.link,
                parentTitle: entry.fields.title,
                parentLink: page.link,
                backSubMenuLabel: entry.fields.title,
                hasSubmenu: !!category.fields.subpages?.length,
                group: category.group,
                subitems: category.fields.subpages?.map((el) => ({
                  title: el.fields.title,
                  link: el.link,
                  parentTitle: category.fields.title,
                  parentLink: category.link,
                  backSubMenuLabel: category.fields.title,
                })),
              }
            })

            return {
              slug: page.fields.slug,
              title: entry.fields.title,
              link: page.link,
              backSubMenuLabel: 'Menu',
              subitems,
              subitemsGroupedTitle: page.fields.subpagesGroupedTitle || '',
              hasSubmenu: !!page.fields?.subpages?.length,
              isVisible: true,
            }
          }
        })
        .filter((el) => el)
    },
    checkLinkActiveness(link) {
      return link === decodeURIComponent(window.location.pathname + window.location.search)
    },
  },
}
</script>
