import api from '@/lib/api'

import settings from '@/settings'

const defaultState = {
  asmAgentId: null,
  asmEmulatedUid: null,
  isAsmSession: false,
}

const getDefaults = () => JSON.parse(JSON.stringify(defaultState))

const state = { isAsmPanelVisible: false, ...getDefaults() }

const mutations = {
  SET_ASM_AGENT_ID(state, value) {
    state.asmAgentId = value
  },
  SET_ASM_EMULATED_UID(state, value) {
    state.asmEmulatedUid = value
  },
  TOGGLE_ASM_PANEL(state, isAsmPanelVisible) {
    if (!isAsmPanelVisible) {
      const queryParams = new URLSearchParams(window.location.search)
      queryParams.delete(settings.services.asm.asmToken)
      const queryString = queryParams.toString() ? '?' + queryParams.toString() : ''
      history.replaceState(
        history.state,
        document.title,
        window.location.origin + window.location.pathname + queryString,
      )
    }
    state.isAsmPanelVisible = isAsmPanelVisible
  },
  RESET_ASM_STATE(state) {
    Object.assign(state, getDefaults())
  },
  SET_IS_ASM_SESSION(state, value) {
    state.isAsmSession = value
  },
}

const actions = {
  asmLogin({ commit }, { login, password }) {
    return api.asm.login(login, password).then(() => {
      commit('SET_ASM_AGENT_ID', login)
      commit('SET_IS_ASM_SESSION', true)
    })
  },
  async asmLogout({ commit, dispatch }) {
    dispatch('app/toggleLoader', true, { root: true })
    await api.asm.logout()
    commit('session/RESET_AUTH_DATA', null, { root: true })
    commit('RESET_ASM_STATE')
    dispatch('session/resetStateOnLoginChange', null, { root: true })
    dispatch('app/toggleLoader', false, { root: true })
  },
  async startEmulation({ commit, dispatch, rootGetters }, email) {
    dispatch('app/toggleLoader', true, { root: true })
    await api.asm.emulate(email)
    await dispatch('session/resetStateOnLoginChange', null, { root: true })
    await dispatch('session/updateUserData', email, { root: true })
    await dispatch('cart/getCart', true, { root: true })
    if (rootGetters['cart/productsQty']) dispatch('cartFlow/setStep', 'view', { root: true })
    await dispatch('search/setCompanyCatalogAvailability', null, { root: true })
    await dispatch('search/setStandardCatalogAvailability', null, { root: true })
    commit('SET_ASM_EMULATED_UID', email)
    dispatch('app/toggleLoader', false, { root: true })
  },
  async stopEmulation({ commit, dispatch }) {
    try {
      dispatch('app/toggleLoader', true, { root: true })
      await api.asm.stopEmulation()
    } finally {
      commit('SET_ASM_EMULATED_UID', null)
      commit('session/RESET_AUTH_DATA', null, { root: true })
      commit('SET_LAST_REQUEST', null)
      dispatch('session/resetStateOnLoginChange', null, { root: true })
      dispatch('app/toggleLoader', false, { root: true })
    }
  },
  stopAsmMode({ commit, dispatch }) {
    dispatch('app/toggleLoader', true, { root: true })
    return dispatch('asmLogout').then(() => {
      commit('TOGGLE_ASM_PANEL', false)
      dispatch('app/toggleLoader', false, { root: true })
    })
  },
  initAsmState({ commit }) {
    const queryParams = new URLSearchParams(window.location.search)
    commit('TOGGLE_ASM_PANEL', !!queryParams.get(settings.services.asm.asmToken))
  },
}

const getters = {
  isAsmLoggedIn: (state) => !!state.asmAgentId,
  isAsmEmulation: (state) => !!state.asmEmulatedUid,
}

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true,
}
