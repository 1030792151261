<template>
  <div v-if="isVisible" class="gdpr-popup">
    <div class="gdpr-popup__title-wrapper">
      <h4 class="gdpr-popup__title" v-html="$t('gdpr.title')" />
    </div>
    <div class="gdpr-popup__copy-wrapper">
      <p class="gdpr-popup__copy" v-html="$t('gdpr.copy')" />
      <app-button
        class="gdpr-popup__btn"
        type="button"
        :text="$t('gdpr.btnText')"
        @clicked="closePopup"
      />
    </div>
    <i class="icon-close gdpr-popup__close-icon" @click="closePopup" />
  </div>
</template>
<script>
export default {
  name: 'GdprPopup',
  data() {
    return {
      isVisible: false,
    }
  },
  methods: {
    closePopup() {
      this.isVisible = false
    },
  },
}
</script>
