import routes from '@/lib/utils/routes'

const state = {
  path: [],
}

const mutations = {
  SET_PATH(state, path) {
    state.path = path
  },
  SET_PATH_EXTEND(state, crumbs) {
    state.path = [...state.path, ...crumbs]
  },
  SET_CUSTOM_PATH(state, customPath) {
    state.path = customPath
  },
}

const actions = {
  setPath({ rootState, commit }, to) {
    if (to.meta.crumbs) {
      to.meta.crumbs.forEach((el, idx, arr) => {
        el.link = el.link.startsWith('/') ? el.link : `/${el.link}`
        if (idx > 0 && arr[0].link !== '/products') el.link = arr[idx - 1].link + el.link
      })
      commit('SET_PATH', to.meta.crumbs)
    } else {
      const path = []

      const addItem = (start) => {
        if (start) {
          path.unshift({
            name: routes.getCaptionByRouteName(start.name),
            link: start.path,
          })
          if ('parent' in start) {
            addItem(routes.find(start.parent))
          }
        }
      }
      // Build path only if current route is not root one.
      if (!routes.isRoot(rootState.route.route.name)) {
        addItem(routes.find(rootState.route.route.name))
      }
      commit('SET_PATH', path)
    }
  },
}

export default {
  state,
  mutations,
  actions,
  namespaced: true,
}
