<template>
  <div v-if="menuItem" class="submenu">
    <div class="container">
      <div class="submenu__inner" @mouseleave="$emit('requestClose')">
        <div v-if="showRootCategories" ref="rootCategories" class="submenu__root-categories">
          <a
            v-if="menuItem.isProducts && isUserLoggedIn"
            class="submenu__root-category submenu__root-category--filled"
            href="/search?tab=myProducts"
            @click.prevent="onLinkClick('/search?tab=myProducts')"
          >
            <span>{{ myProductsLabel }}</span>
            <i class="icon-arrow" />
          </a>
          <div
            v-for="category in getRootCategories"
            :key="category.link"
            :class="{
              'submenu__root-category--active': category.link === activeRootCategory.link,
            }"
            class="submenu__root-category"
            @click="checkIfRedirect(category)"
          >
            <span>{{ category.title }}</span>
          </div>
          <div
            v-if="getGroupedCategoriesTitle"
            class="submenu__root-category submenu__root-category--grouper"
            v-html="getGroupedCategoriesTitle"
          />
          <div
            v-for="category in getGroupedCategories"
            :key="category.link"
            :class="{
              'submenu__root-category--active': category.link === activeRootCategory.link,
            }"
            class="submenu__root-category"
            @click="checkIfRedirect(category)"
          >
            <span>{{ category.title }}</span>
          </div>
        </div>
        <div
          :class="{ 'submenu__main-list-wrap--wide': !showRootCategories }"
          class="submenu__main-list-wrap"
          :style="{ 'max-height': mainListHeight }"
        >
          <div
            v-if="showRootCategories && activeRootCategory"
            class="submenu__active-category-block"
          >
            <a
              :href="activeRootCategory.link"
              class="submenu__active-category-link"
              @click.prevent="onLinkClick(activeRootCategory.link)"
            >
              {{ activeRootCategory.title }}
              <i class="icon-arrow submenu__active-category-icon" />
            </a>
          </div>
          <ul
            v-if="isInitialized && activeSubcategories"
            ref="subCategories"
            class="submenu__subcategories"
            :style="{ height: subCategoriesHeight }"
          >
            <li
              v-for="subcategory in activeSubcategories"
              :key="subcategory.link"
              class="submenu__subcategory"
            >
              <a
                :href="subcategory.link"
                :class="{
                  'submenu__subcategory-link--active': checkLinkActiveness(subcategory.link),
                }"
                class="submenu__subcategory-link"
                @click.prevent="onLinkClick(subcategory.link)"
                v-html="subcategory.title"
              />
            </li>
          </ul>
        </div>
        <div class="submenu__description-wrap">
          <div class="submenu__description">
            <div v-if="description" v-html="description" />
          </div>
          <div v-if="showInventory" class="submenu__inventory-wrap">
            <a
              :id="inventoryButton.id"
              :href="inventoryButton.url"
              class="submenu__inventory-button button button--small button--secondary"
              @click.prevent="onLinkClick(inventoryButton.url)"
              v-html="inventoryButton.title"
            />
            <span
              class="submenu__inventory-subtitle"
              v-html="$t('inventory.checkOutOurLiveCatalog')"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { nextTick } from 'vue'
import { mapGetters } from 'vuex'

import menuUnderlineMixin from '@/components/common/AppHeader/mixins/menuUnderlineMixin'
import contentfulComponentMixin from '@/components/common/contentful/mixins/contentfulComponentMixin'
import navigationMixin from '@/components/common/mixins/navigationMixin'

export default {
  name: 'SubMenu',
  mixins: [navigationMixin, menuUnderlineMixin, contentfulComponentMixin],
  props: {
    menuItem: {
      type: Object,
      required: true,
    },
  },
  emits: ['requestClose'],
  data() {
    return {
      activeRootCategory: null,
      activeSubcategories: null,
      rootCategories: null,
      description: null,
      mainListHeight: 'auto',
      subCategoriesHeight: '400px',
      isInitialized: false,
      myProductsLabel:
        this.getSiteCopy('copyGroupCommon', 'copyItemSearchPageMegamenuMyProductsButton') ||
        this.$t('common.myProducts'),
    }
  },
  computed: {
    ...mapGetters('session', ['isUserLoggedIn']),
    showRootCategories() {
      return !!(this.menuItem.isNestedSubmenu && this.menuItem.subitems?.length)
    },
    showInventory() {
      return this.menuItem.isProducts
    },
    inventoryButton() {
      return {
        id: this.isUserLoggedIn
          ? 'header-navigation-browse-inventory-button'
          : 'header-navigation-login-to-browse-inventory-button',
        title: this.isUserLoggedIn
          ? this.$t('inventory.browseSpecimenInventory')
          : this.$t('inventory.createAccountInventory'),
        url: '/inventory',
      }
    },
    getRootCategories() {
      return this.rootCategories.filter((category) => !category.group)
    },
    getGroupedCategories() {
      return this.rootCategories.filter((category) => category.group)
    },
    getGroupedCategoriesTitle() {
      return this.menuItem.subitemsGroupedTitle
    },
  },
  created() {
    if (this.showRootCategories) {
      this.rootCategories = this.menuItem.subitems?.map((subitem) => {
        return {
          title: subitem.fields.title,
          link: subitem.link,
          description: subitem.fields.subtitle,
          items: subitem.fields.subpages?.map((subitem) => ({
            title: subitem.fields.title,
            link: subitem.link,
            description: subitem.fields.subtitle,
          })),
          group: subitem.group || undefined,
        }
      })

      const currentPath = window.location.pathname
      const activeRootCategory = this.rootCategories.find((rootCategory) => {
        return (
          rootCategory.link === currentPath ||
          rootCategory.items?.some((item) => item.link === currentPath)
        )
      })
      const rootCategoryToActivate = activeRootCategory || this.rootCategories[0]
      this.activateRootCategory(rootCategoryToActivate)
    } else {
      this.activeSubcategories = this.menuItem.subitems?.map((subitem) => ({
        title: subitem.fields.title,
        link: subitem.link,
        description: subitem.fields.subtitle,
      }))
      this.description = this.menuItem.description
    }
  },
  mounted() {
    nextTick(() => {
      this.calculateMainListHeight()
      this.isInitialized = true
    })
  },
  methods: {
    activateRootCategory(category) {
      this.activeRootCategory = category
      this.activeSubcategories = this.activeRootCategory.items
      this.description = this.activeRootCategory.description
    },
    checkIfRedirect(category) {
      if (!category.items) this.onLinkClick(category.link)
      else this.activateRootCategory(category)
    },
    calculateMainListHeight() {
      const rootCategoriesHeight = this.$refs.rootCategories?.offsetHeight
      if (rootCategoriesHeight) {
        this.mainListHeight = `${rootCategoriesHeight}px`
        this.subCategoriesHeight = `${rootCategoriesHeight - 100}px`
      }
    },
    onLinkClick(link) {
      this.navigateTo(link)
      this.$emit('requestClose')
    },
    checkLinkActiveness(link) {
      return link === decodeURIComponent(window.location.pathname + window.location.search)
    },
  },
}
</script>
