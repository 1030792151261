import api from '@/lib/api'

const _defaultState = {
  currentInventoryData: [],
  pagination: {},
}
const getDefaultState = () => JSON.parse(JSON.stringify(_defaultState))
const state = getDefaultState()

const mutations = {
  SET_CURRENT_INVENTORY(state, { currentInventory, pagination = {} }) {
    state.currentInventoryData = currentInventory
    state.pagination = pagination
  },
  RESET_STATE(state) {
    Object.assign(state, getDefaultState())
  },
}

const actions = {
  async getCurrentInventory({ commit }, filters) {
    const response = await api.inventoryTracking.getCurrentInventory(filters)
    commit('SET_CURRENT_INVENTORY', {
      currentInventory: response.data.currentInventory,
    })
    return response.data.pagination
  },
  async editCurrentInventory(context, currentInventoryData) {
    return api.inventoryTracking.editCurrentInventory(currentInventoryData)
  },
  async downloadCurrentInventory(context, searchTerm) {
    const response = await api.inventoryTracking.downloadCurrentInventory(searchTerm)
    return response
  },
  resetState({ commit }) {
    commit('RESET_STATE')
  },
}

export default {
  state,
  mutations,
  actions,
  namespaced: true,
}
