export default {
  methods: {
    mainMenuMouseoverListener(event) {
      const menu = this.menuRef
      if (!menu) return
      const menuRect = menu.getBoundingClientRect()
      const menuLeftPosition = menuRect.left
      if (event.target?.classList?.contains('main-menu__link')) {
        const menuLink = event.target
        const menuItem = menuLink.offsetParent
        const menuItemRect = menuItem.getBoundingClientRect()
        const menuItemLeft = menuItemRect.left - menuLeftPosition
        const menuLinkPaddingLeft = parseFloat(
          getComputedStyle(menuLink).getPropertyValue('padding-left'),
        )
        const menuLinkPaddingRight = parseFloat(
          getComputedStyle(menuLink).getPropertyValue('padding-right'),
        )
        const menuLinkLeft = menuItemLeft + menuLinkPaddingLeft
        const menuLinkWidth = menuLink.offsetWidth - menuLinkPaddingLeft - menuLinkPaddingRight

        menu.style.setProperty(
          '--underline-transition',
          menu.style.getPropertyValue('--underline-width') === 0
            ? 'width 0.15s'
            : 'transform 0.15s, width 0.15s',
        )
        menu.style.setProperty('--underline-width', `${menuLinkWidth}px`)
        menu.style.setProperty('--underline-offset-x', `${menuLinkLeft}px`)
      }
    },
    mainMenuMouseleaveListener() {
      const menu = this.menuRef
      if (!menu) return
      menu.style.setProperty('--underline-width', '0')
    },
    subMenuMouseoverListener(event) {
      const menu = this.menuRef
      if (!menu) return
      if (event.target?.classList?.contains('submenu__root-category')) {
        const menuItem = event.target
        menu.style.setProperty(
          '--mobile-underline-transition',
          menu.style.getPropertyValue('--mobile-underline-opacity') === 0
            ? 'opacity 0.15s'
            : 'transform 0.15s, opacity 0.15s',
        )
        menu.style.setProperty('--mobile-underline-opacity', '1')
        menu.style.setProperty('--mobile-underline-height', `${menuItem.offsetHeight}px`)
        menu.style.setProperty('--mobile-underline-offset-y', `${menuItem.offsetTop}px`)
      }
    },
    subMenuMouseleaveListener() {
      const menu = this.menuRef
      if (!menu) return
      menu.style.setProperty('--mobile-underline-opacity', '0')
    },
  },
  mounted() {
    const menuRefsByComponentName = {
      MainMenu: 'mainMenu',
      SubMenu: 'rootCategories',
    }
    const mouseoverListenersByComponentName = {
      MainMenu: this.mainMenuMouseoverListener,
      SubMenu: this.subMenuMouseoverListener,
    }
    const mouseleaveListenersByComponentName = {
      MainMenu: this.mainMenuMouseleaveListener,
      SubMenu: this.subMenuMouseleaveListener,
    }

    this.menuRef = this.$refs[menuRefsByComponentName[this.$options.name]]
    this.mouseoverListener = mouseoverListenersByComponentName[this.$options.name]
    this.mouseleaveListener = mouseleaveListenersByComponentName[this.$options.name]

    if (this.menuRef) {
      this.menuRef.addEventListener('mouseover', this.mouseoverListener)
      this.menuRef.addEventListener('mouseleave', this.mouseleaveListener)
    }
  },
  beforeUnmount() {
    if (this.menuRef) {
      this.menuRef.removeEventListener('mouseover', this.menuMouseoverListener)
      this.menuRef.removeEventListener('mouseleave', this.menuMouseleaveListener)
    }
  },
  data() {
    return {
      menuRef: null,
      mouseoverListener: null,
      mouseleaveListener: null,
    }
  },
}
