import app from '@/main'

export const getProductCrumbs = (cProduct) => {
  const typePageSlugsHierarchy = [
    cProduct.fields.types?.[0]?.fields?.slug,
    cProduct.fields.subtypes?.[0]?.fields?.slug,
    cProduct.fields.subtypesLevel2?.[0]?.fields?.slug,
  ].filter((el) => el)
  const typePagesHierarchy = typePageSlugsHierarchy
    .map((pageSlug) => app.config.globalProperties.$contentful.service.getPageBySlug(pageSlug))
    .filter((el) => el)
  const deepestAvailableTypePage = typePagesHierarchy[typePagesHierarchy.length - 1]
  return deepestAvailableTypePage
    ? deepestAvailableTypePage.crumbs.map((el) => ({
        name: el.fields.title,
        link: el.fields.slug,
      }))
    : []
}
