import api from '@/lib/api'

import app from '@/main'

const _defaultState = {
  productPricesCache: {},
  isCompanyCatalogAvailable: false,
  isStandardCatalogAvailable: false,
}
const getDefaultState = () => JSON.parse(JSON.stringify(_defaultState))
const state = getDefaultState()

const mutations = {
  SET_PRODUCT_PRICES_CACHE(state, data) {
    state.productPricesCache = Object.assign({}, state.productPricesCache, data)
  },
  SET_COMPANY_CATALOG_AVAILABILITY(state, isCompanyCatalogAvailable) {
    state.isCompanyCatalogAvailable = isCompanyCatalogAvailable
  },
  SET_STANDART_CATALOG_AVAILABILITY(state, isStandardCatalogAvailable) {
    state.isStandardCatalogAvailable = isStandardCatalogAvailable
  },
  RESET_STATE(state) {
    Object.assign(state, getDefaultState())
  },
}

const actions = {
  async getPriceForProducts({ commit, state }, products) {
    try {
      const cacheKeys = products.reduce((sum, curr) => {
        sum[curr.objectID] = {
          objectID: curr.objectID,
          cacheKey: Object.entries({
            code: curr.code,
            salesToStock: curr.salesToStock || 1,
            aliqPerLot: curr.aliqPerLot || 0,
            volPerAliq: curr.volPerAliq || 0,
          })
            .map(([key, value]) => `${key}:${value}`)
            .join('&'),
        }
        return sum
      }, {})

      const productsToLoad = products.filter(
        (el) => !state.productPricesCache[cacheKeys[el.objectID].cacheKey],
      )
      if (productsToLoad.length) {
        const track = new Set()
        const uniqueProductsToLoad = productsToLoad.filter(({ objectID }) =>
          track.has(cacheKeys[objectID].cacheKey) ? false : track.add(cacheKeys[objectID].cacheKey),
        )
        const productsToLoadPayloads = uniqueProductsToLoad.map(
          ({ code, salesToStock, aliqPerLot, volPerAliq }) => ({
            productCode: code,
            priceParametersData: {
              salesToStock,
              aliqPerLot,
              volPerAliq,
            },
          }),
        )

        const allPriceEntries = []
        const amountToLoadPerRequest = 20 // Prevent endpoint performance issues
        for (let i = 0; i < productsToLoadPayloads.length; i += amountToLoadPerRequest) {
          const chunk = productsToLoadPayloads.slice(i, i + amountToLoadPerRequest)
          const { data: priceEntries } = await api.products.getProductsPrices(chunk)
          allPriceEntries.push(...priceEntries)
        }

        const productPrices = allPriceEntries
          .map((priceEntry, idx) => {
            const objectID = uniqueProductsToLoad[idx].objectID
            return {
              objectID,
              cacheKey: cacheKeys[objectID].cacheKey,
              itemNetPrice: priceEntry.itemNetPrice,
            }
          })
          .reduce((sum, { objectID, cacheKey, itemNetPrice = {} }) => {
            sum[cacheKey] = {
              objectID,
              cacheKey,
              itemNetPrice: {
                formattedValue: itemNetPrice.formattedValue,
                value: itemNetPrice.value,
                currencyIso: itemNetPrice.currencyIso,
              },
            }
            return sum
          }, {})

        await commit('SET_PRODUCT_PRICES_CACHE', productPrices)
      }
      return products.reduce((sum, curr) => {
        sum[curr.objectID] = state.productPricesCache[cacheKeys[curr.objectID].cacheKey]
        return sum
      }, {})
    } catch {
      return []
    }
  },
  async setCompanyCatalogAvailability({ commit, rootGetters }) {
    const filters = [
      'myCatalog:true',
      rootGetters['session/isUserLoggedIn']
        ? `businessUnit:${rootGetters['session/user']?.unit?.uid}`
        : '',
    ].filter((el) => el)
    const { hits } = await app.config.globalProperties.$search.service
      .getProductIndex()
      .search('', {
        hitsPerPage: 1,
        filters: filters.join(' AND '),
        clickAnalytics: false,
      })
    commit('SET_COMPANY_CATALOG_AVAILABILITY', !!hits.length)
  },
  async setStandardCatalogAvailability({ commit }) {
    const filters = ['myCatalog:false', 'standardCatalog:true'].filter((el) => el)
    const { hits } = await app.config.globalProperties.$search.service
      .getProductIndex()
      .search('', {
        hitsPerPage: 1,
        filters: filters.join(' AND '),
        clickAnalytics: false,
      })
    commit('SET_STANDART_CATALOG_AVAILABILITY', !!hits.length)
  },
}

export default {
  state,
  mutations,
  actions,
  namespaced: true,
}
