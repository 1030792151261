<template>
  <div class="video">
    <video ref="video" :controls="controls" :src="url" :poster="preview" />
    <div v-if="showHolder" class="video-inner" @click="playVideo">
      <button class="video__btn">
        <svg height="1792" viewBox="0 0 1792 1792" width="1792" xmlns="http://www.w3.org/2000/svg">
          <path
            fill="#ffffff"
            d="M896 128q209 0 385.5 103t279.5 279.5 103 385.5-103 385.5-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103zm384 823q32-18 32-55t-32-55l-544-320q-31-19-64-1-32 19-32 56v640q0 37 32 56 16 8 32 8 17 0 32-9z"
          />
        </svg>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppVideo',
  props: {
    url: {
      type: String,
      required: true,
    },
    preview: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      showHolder: true,
      controls: false,
    }
  },
  methods: {
    playVideo() {
      this.showHolder = false
      this.controls = true
      this.$refs.video.play()
    },
  },
}
</script>
