<template>
  <div ref="formWrap">
    <VueScriptComponent v-if="script" :script="script" />
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    script: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      observer: null,
    }
  },
  computed: {
    ...mapGetters('session', ['user', 'isUserLoggedIn']),
  },
  mounted() {
    if (!this.isUserLoggedIn) return
    this.observer = new MutationObserver((mutations) => {
      for (let mutation of mutations) {
        for (let node of mutation.addedNodes) {
          if (!(node instanceof HTMLElement)) continue
          const inputSelector = 'input[name="firstname"]'
          const isInputRendered = node.matches(inputSelector) || !!node.querySelector(inputSelector)
          if (isInputRendered) this.pushDataToForm()
        }
      }
    })
    const formWrapNode = this.$refs.formWrap
    this.observer.observe(formWrapNode, { childList: true, subtree: true })
  },
  beforeUnmount() {
    if (this.observer) this.observer.disconnect()
  },
  methods: {
    async pushDataToForm() {
      setTimeout(() => {
        const formWrapNode = this.$refs.formWrap
        const firstNameInput = formWrapNode.querySelector('input[name="firstname"]')
        const lastNameInput = formWrapNode.querySelector('input[name="lastname"]')
        const emailInput = formWrapNode.querySelector('input[name="email"]')
        const companyInput = formWrapNode.querySelector('input[name="company"]')
        const phoneInput = formWrapNode.querySelector('input[name="phone"]')
        const countrySelect = formWrapNode.querySelector('select[name="country"]')

        if (firstNameInput) {
          firstNameInput.value = this.user.firstName
          firstNameInput.dispatchEvent(new Event('input'))
        }
        if (lastNameInput) {
          lastNameInput.value = this.user.lastName
          lastNameInput.dispatchEvent(new Event('input'))
        }
        if (emailInput) {
          emailInput.value = this.user.email
          emailInput.dispatchEvent(new Event('input'))
        }
        if (companyInput) {
          companyInput.value = this.user.contactInformation?.companyName || ''
          companyInput.dispatchEvent(new Event('input'))
        }
        if (phoneInput) {
          phoneInput.value = this.user.contactInformation?.phone || ''
          phoneInput.dispatchEvent(new Event('input'))
        }

        const isUserCountryExistsInOptions = countrySelect?.childNodes
          ? Array.from(countrySelect.childNodes).some(
              (childNode) => childNode.outerText === this.user.contactInformation?.country?.name,
            )
          : false
        if (isUserCountryExistsInOptions) {
          countrySelect.value = this.user.contactInformation?.country?.name || ''
          countrySelect.dispatchEvent(new Event('input'))
        }
      }, 500)
    },
  },
}
</script>
