<template>
  <div class="app-header">
    <div v-if="isPunchout" class="app-header__punchout">
      <div class="container">
        <p class="text-color-navy app-header__punchout--copy text-h4" v-html="punchoutCopy" />
      </div>
    </div>
    <header class="app-header__container">
      <div class="app-header__top-line">
        <div class="container app-header__top-line-wrap">
          <logo class="menu-row__item menu-row__item--logo" @clicked="onLogoClick" />
          <div v-if="!isDesktop" class="app-header__mobile-actions">
            <div class="app-header__mobile-action-item" @click="onMyQuoteClick">
              <a href="/cart" class="app-header__mobile-action" @click.prevent="onMyQuoteClick">
                <span class="app-header__mobile-quote-btn-number">{{ productsQtyLabel }}</span>
                <span class="app-header__mobile-action-text" v-html="$t('header.myQuote')" />
              </a>
            </div>
            <div class="app-header__mobile-action-item" @click="toggleMobileMenu">
              <div class="app-header__mobile-action">
                <div
                  :class="{ 'app-header__hamburger--active': showMobileMenu }"
                  class="app-header__hamburger"
                >
                  <div class="app-header__hamburger-item" />
                  <div class="app-header__hamburger-item" />
                  <div class="app-header__hamburger-item" />
                  <div class="app-header__hamburger-item" />
                </div>
                <span class="app-header__mobile-action-text" v-html="$t('header.menu')" />
              </div>
            </div>
          </div>
          <div class="app-header__search-block">
            <search-form-with-overlay
              :isIconShow="true"
              :isOverlayEnabled="isSearchOverlayEnabled"
              :searchQuery="headerSearchQuery"
              @close="toggleMobileMenu(false)"
              @searchQueryChange="SET_HEADER_SEARCH_QUERY"
            />
          </div>
          <mobile-menu
            v-if="!isDesktop && !isPunchout"
            :class="{ show: !isDesktop && showMobileMenu }"
            @linkClicked="toggleMobileMenu"
          />
          <my-account-menu
            v-if="isDesktop && !isPunchout"
            ref="mainMyAccountMenu"
            @linkClicked="toggleMobileMenu"
          />
        </div>
      </div>
      <div class="app-header__line" />
      <main-menu v-if="isDesktop" ref="mainMenu" @linkClicked="toggleMobileMenu" />
    </header>

    <transition :name="isStickyHeaderSearchFormVisible ? 'slide' : 'slide-short'">
      <div
        v-if="isDesktop && isStickyHeaderVisible"
        id="sticky-header"
        class="sticky-header app-header__sticky-header"
      >
        <div class="container">
          <div class="sticky-header__container">
            <div class="sticky-header__logo">
              <logo class="app-header__sticky-header_logo" @clicked="onLogoClick" />
            </div>
            <div class="sticky-header__menu">
              <main-menu :isRightMenuVisible="false" @linkClicked="toggleMobileMenu" />
              <transition name="squeeze">
                <search-form-with-overlay
                  v-if="isStickyHeaderSearchFormVisible"
                  :isIconShow="true"
                  :isOverlayEnabled="isSearchOverlayEnabled"
                  :searchQuery="headerSearchQuery"
                  class="app-header__sticky-header_search"
                  @close="toggleMobileMenu(false), (isStickyHeaderSearchFormVisible = false)"
                  @searchQueryChange="SET_HEADER_SEARCH_QUERY"
                />
              </transition>
            </div>
            <div class="sticky-header__actions">
              <ul class="app-header__sticky-header_actions-list">
                <li class="app-header__sticky-header_actions-item">
                  <div
                    class="app-header__sticky-header_actions-button"
                    @click="isStickyHeaderSearchFormVisible = !isStickyHeaderSearchFormVisible"
                  >
                    <icon-search
                      :class="{
                        'app-header__sticky-header_actions-icon--grey':
                          isStickyHeaderSearchFormVisible,
                      }"
                      class="app-header__sticky-header_actions-icon"
                    />
                  </div>
                  <transition name="opacity">
                    <button
                      v-if="isStickyHeaderSearchFormVisible"
                      class="button app-header__sticky-header_actions-icon--search-close"
                      @click="isStickyHeaderSearchFormVisible = !isStickyHeaderSearchFormVisible"
                    >
                      <icon-close />
                    </button>
                  </transition>
                </li>
                <li class="app-header__sticky-header_actions-item">
                  <router-link
                    to="/about/contact-us"
                    class="app-header__sticky-header_actions-button"
                  >
                    <icon-question-dialog class="app-header__sticky-header_actions-icon" />
                  </router-link>
                </li>
                <li v-if="!isPunchout" class="app-header__sticky-header_actions-item">
                  <my-account-menu :isStickyHeaderView="true" />
                </li>
                <li class="app-header__sticky-header_actions-item">
                  <router-link
                    id="header-view-quote-button-sticky"
                    to="/cart"
                    class="app-header__sticky-header_actions-button"
                  >
                    <icon-shopping-cart
                      class="app-header__sticky-header_actions-icon app-header__sticky-header_actions-icon--shop"
                    />
                    <span class="app-header__sticky-header_actions-cart-qty">{{
                      productsQtyLabel
                    }}</span>
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import _ from 'lodash'
import { mapState, mapGetters, mapMutations } from 'vuex'

import contentfulComponentMixin from '@/components/common/contentful/mixins/contentfulComponentMixin'
import punchoutMixin from '@/components/common/mixins/punchoutMixin'
import screenWidthMixin from '@/components/common/mixins/screenWidthMixin'

import shelfIcon from '@/assets/images/icons/shelf.svg'
import userIcon from '@/assets/images/icons/user-icon.svg'

import MainMenu from '@/components/common/AppHeader/MainMenu.vue'
import MobileMenu from '@/components/common/AppHeader/MobileMenu.vue'
import MyAccountMenu from '@/components/common/AppHeader/MyAccountMenu.vue'
import IconClose from '@/components/common/icons/IconClose.vue'
import IconQuestionDialog from '@/components/common/icons/IconQuestionDialog.vue'
import IconSearch from '@/components/common/icons/IconSearch.vue'
import IconShoppingCart from '@/components/common/icons/IconShoppingCart.vue'
import Logo from '@/components/common/Logo.vue'

import SearchFormWithOverlay from '@/components/search/SearchFormWithOverlay.vue'

export default {
  name: 'AppHeader',
  components: {
    MainMenu,
    MobileMenu,
    MyAccountMenu,
    IconClose,
    IconQuestionDialog,
    IconSearch,
    IconShoppingCart,
    Logo,
    SearchFormWithOverlay,
  },
  mixins: [contentfulComponentMixin, screenWidthMixin, punchoutMixin],
  data() {
    return {
      showMobileMenu: false,
      shelfIcon: shelfIcon,
      userIcon: userIcon,
      isStickyHeaderVisible: false,
      isStickyHeaderSearchFormVisible: false,
    }
  },
  computed: {
    ...mapState('app', ['isInitialized', 'headerSearchQuery']),
    ...mapState('punchout', ['punchoutRedirectUrl']),
    ...mapState('cart', ['referenceNumbers']),
    ...mapGetters('cart', ['productsQty']),
    productsQtyLabel() {
      return this.referenceNumbers.length ? 0 : this.productsQty
    },
    punchoutCopy() {
      return this.isTopFrame
        ? this.isDesktop
          ? this.$t('punchout.headerCopyDesktop', {
              procurementSite: this.getProcurementSiteName,
              procurementLink: this.punchoutRedirectUrl,
            })
          : this.$t('punchout.headerCopyMobile', {
              procurementLink: this.punchoutRedirectUrl,
            })
        : this.isDesktop
          ? this.$t('punchout.headerCopyDesktopNoAction', {
              procurementSite: this.getProcurementSiteName,
            })
          : this.$t('punchout.headerCopyMobileNoAction', {
              procurementSite: this.getProcurementSiteName,
            })
    },
    isSearchOverlayEnabled() {
      return this.$route.name !== 'search'
    },
    showAccountMenu() {
      return !this.isPunchout && (this.isDesktop || this.showMobileMenu)
    },
  },
  mounted() {
    window.addEventListener('scroll', this.stickyHeaderListener)
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.stickyHeaderListener)
  },
  methods: {
    ...mapMutations('app', ['SET_HEADER_SEARCH_QUERY']),
    toggleMobileMenu(value) {
      if (this.isDesktop) return
      this.showMobileMenu = typeof value === 'boolean' ? value : !this.showMobileMenu
      if (this.showMobileMenu) document.body.classList.add('overflow-hidden')
      else document.body.classList.remove('overflow-hidden')
    },
    toggleAccountMenuDrawer(value) {
      this.$refs.mainMyAccountMenu?.toggleDrawer(value)
    },
    onLogoClick() {
      this.toggleMobileMenu(false)
      this.$router.push('/')
    },
    onMyQuoteClick() {
      this.toggleMobileMenu(false)
      this.$router.push('/cart')
    },
    stickyHeaderListener: _.throttle(function () {
      const topScrollPosition = window.pageYOffset || document.documentElement.scrollTop
      const stickyHeaderTriggerPosition = 200
      if (!this.isStickyHeaderVisible && topScrollPosition >= stickyHeaderTriggerPosition) {
        this.$refs.mainMenu?.setActiveItem(null)
        this.toggleAccountMenuDrawer(false)
        this.isStickyHeaderVisible = true
        return
      }
      if (this.isStickyHeaderVisible && topScrollPosition <= stickyHeaderTriggerPosition) {
        this.isStickyHeaderVisible = false
        this.isStickyHeaderSearchFormVisible = false
      }
    }, 100),
  },
}
</script>
