<template>
  <div class="asm-container">
    <div class="asm-interface">
      <div class="container">
        <div class="asm-interface-control">
          <div class="asm-field asm-field-fill">
            <h2 class="text-h3 text-color-navy asm-top_title">
              {{ $t('ASM.title') }}
            </h2>
          </div>
          <div class="asm-field">
            <div class="asm-field-wrap">
              <div class="asm-row asm-flex-column">
                <div>{{ $t('ASM.labels.signedInAS') }}:</div>
                <b>{{ asmAgentId }}</b>
              </div>
            </div>
            <div class="asm-password-field" @click="togglePasswordTab">
              {{ $t('ASM.buttons.changePassword') }}
            </div>
            <div class="asm-signout" @click="asmLogout">
              {{ $t('ASM.buttons.signOut') }}
            </div>
          </div>
          <div class="asm-field asm-field--icon">
            <i class="icon-close asm-top_close" @click="stopAsmMode" />
          </div>
        </div>
        <div v-if="showPasswordTab" class="asm-password-tab-wrap">
          <AsmPasswordTab @requestClose="togglePasswordTab" />
        </div>
        <div v-else class="asm-interface-search">
          <div class="asm-input-wrap">
            <AsmSearch
              :disabled="!!isAsmEmulation"
              @selected="customerSearchItemSelected"
              @emulate="emulate"
            />
          </div>
          <div class="asm-input-wrap">
            <button
              v-if="!isAsmEmulation"
              :disabled="!emulationAllowed"
              type="button"
              class="button button--primary button--large"
              @click="emulate"
            >
              {{ $t('ASM.buttons.startSession') }}
            </button>
            <button
              v-else
              type="button"
              class="button button--secondary-inversed button--large"
              @click="stopEmulation"
            >
              {{ $t('ASM.buttons.endSession') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters, mapActions } from 'vuex'

import AsmPasswordTab from '@/components/asm/AsmPasswordTab.vue'
import AsmSearch from '@/components/asm/AsmSearch.vue'

export default {
  name: 'ASMInterface',
  components: {
    AsmSearch,
    AsmPasswordTab,
  },
  data() {
    return {
      selectedCustomer: null,
      errorMessage: '',
      showPasswordTab: false,
    }
  },
  computed: {
    ...mapState('asm', ['asmAgentId']),
    ...mapGetters('asm', ['isAsmEmulation']),
    emulationAllowed() {
      return !!this.selectedCustomer
    },
  },
  methods: {
    ...mapActions('asm', ['stopAsmMode']),
    customerSearchItemSelected(customer) {
      this.selectedCustomer = customer
    },
    emulate() {
      this.$store
        .dispatch('asm/startEmulation', this.selectedCustomer.email)
        .then(() => this.$router.push('/'))
    },
    stopEmulation() {
      this.$store.dispatch('asm/stopEmulation').then(() => {
        if (this.$route.meta.privateRoute) this.$router.push('/authentication')
      })
    },
    asmLogout() {
      this.$store.dispatch('asm/asmLogout').then(() => this.$router.push('/'))
    },
    togglePasswordTab(value) {
      this.showPasswordTab = typeof value === 'boolean' ? value : !this.showPasswordTab
    },
  },
}
</script>
