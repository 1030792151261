import cookieUtils from '@/lib/utils/cookies'

const defaultState = {
  punchoutDunsId: null,
  punchoutRedirectUrl: null,
  isPunchoutSession: false,
}

const getDefaults = () => JSON.parse(JSON.stringify(defaultState))

const state = getDefaults()

const mutations = {
  SET_PUNCHOUT_DUNS_ID(state, punchoutDunsId) {
    state.punchoutDunsId = punchoutDunsId
  },
  SET_PUNCHOUT_REDIRECT_URL(state, value) {
    state.punchoutRedirectUrl = value
  },
  RESET_PUNCHOUT_STATE(state) {
    Object.assign(state, getDefaults())
  },
  SET_IS_PUNCHOUT_SESSION(state, value) {
    state.isPunchoutSession = value
  },
}

const actions = {
  punchoutLogout({ dispatch, commit }) {
    commit('RESET_PUNCHOUT_STATE')
    return dispatch('session/logout', null, { root: true })
  },
  initPunchoutState({ commit }) {
    const punchoutDunsId = cookieUtils.getCookie('punchoutDunsId')
    const punchoutRedirectUrl = cookieUtils.getCookie('punchoutRedirectUrl')

    commit('SET_PUNCHOUT_DUNS_ID', punchoutDunsId)
    commit('SET_PUNCHOUT_REDIRECT_URL', punchoutRedirectUrl)

    if (punchoutDunsId && punchoutRedirectUrl) {
      commit('SET_IS_PUNCHOUT_SESSION', true)
    }

    cookieUtils.removeCookie('punchoutDunsId')
    cookieUtils.removeCookie('punchoutRedirectUrl')
  },
}

export default {
  state,
  mutations,
  actions,
  namespaced: true,
}
