<template>
  <div class="asm-container">
    <div class="asm-login">
      <div class="container">
        <div class="asm-top">
          <h2 class="asm-top_title text-h3">
            {{ $t('ASM.title') }}
          </h2>
          <i class="icon-close asm-top_close" @click="stopAsmMode" />
        </div>
        <form class="asm-bottom" @submit.prevent="submitLogin">
          <div class="asm-control">
            <div class="form-group" :class="{ error: !!errorMessage }">
              <label>
                <input
                  v-model="login"
                  type="text"
                  class="form-input form-input--large"
                  :placeholder="$t('ASM.placeholders.agentId')"
                  name="username"
                  autocomplete="off"
                  maxlength="50"
                />
              </label>
              <p v-if="error" class="error-text">
                {{ errorMessage }}
              </p>
            </div>
          </div>

          <div class="asm-control">
            <div class="form-group" :class="{ error: !!errorMessage }">
              <label>
                <input
                  v-model="password"
                  type="password"
                  class="form-input form-input--large"
                  :placeholder="$t('ASM.placeholders.password')"
                  name="password"
                  autocomplete="off"
                  maxlength="50"
                />
              </label>
            </div>
          </div>

          <div class="asm-control">
            <app-button
              class="asm-control__button"
              :text="$t('ASM.buttons.signIn')"
              type="submit"
              size="large"
              :disabled="submitDisabled"
            />
          </div>
        </form>
        <div class="asm-password-field" @click="toggleResetPassword(true)">
          {{ $t('ASM.buttons.resetPassword') }}
        </div>
      </div>
      <forgot-password
        v-if="isModalShow"
        :title="$t('ASM.resetPassword.title')"
        @close="toggleResetPassword(false)"
      />
    </div>
  </div>
</template>
<script>
import _ from 'lodash'
import { mapActions } from 'vuex'

import ForgotPassword from '@/components/auth/ForgotPassword.vue'

export default {
  name: 'AsmLogin',
  components: {
    ForgotPassword,
  },
  data() {
    return {
      login: '',
      password: '',
      error: false,
      isModalShow: false,
      errorMessage: false,
    }
  },
  computed: {
    submitDisabled() {
      return !(this.login && this.password)
    },
  },
  methods: {
    ...mapActions('asm', ['stopAsmMode']),
    ...mapActions('app', ['toggleLoader']),
    submitLogin() {
      if (this.submitDisabled) return
      this.toggleLoader(true)
      this.$store
        .dispatch('asm/asmLogin', {
          login: this.login,
          password: this.password,
        })
        .catch((error) => {
          if (
            _.get(error, 'response.status') === 401 ||
            _.get(error, 'response.data[0].type') === 'AssistedServiceAgentBadCredentialsError'
          ) {
            this.failsWithError(this.$t('ASM.errors.invalidCreds'))
          } else {
            this.failsWithError(
              _.get(error, 'response.data[0].message') || this.$t('ASM.errors.badRequest'),
            )
            throw error
          }
        })
        .finally(() => this.toggleLoader(false))
    },
    failsWithError(message) {
      this.error = true
      this.errorMessage = message
    },
    toggleResetPassword(value) {
      this.isModalShow = value
    },
  },
}
</script>
