export default {
  props: {
    modelValue: [String, Boolean, Number, Object],
    field: Object,
    isValidationRun: Boolean,
    customServerErrorMessage: String,
    readonly: {
      type: Boolean,
      default: false,
    },
    isLabelVisible: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    model() {
      return this.field.$model || this.field
    },
    errors() {
      if (!this.field.$model) return null

      const fieldValue = this.field.value
      const fieldErrors = this.field.$model.errors
      let invalidErrorKeys = Object.keys(fieldErrors).filter((errorKey) => {
        const isRuleExists = typeof fieldValue[errorKey]?.$invalid === 'boolean'
        const isRuleValid = !fieldValue[errorKey]?.$invalid
        return isRuleExists && !isRuleValid
      })
      const containsServerError = 'server' in fieldErrors
      const isFieldContainsErrorsBesideServer = containsServerError && invalidErrorKeys.length > 1
      if (isFieldContainsErrorsBesideServer)
        invalidErrorKeys = invalidErrorKeys.filter((errorName) => errorName !== 'server')

      const allActiveErrors = invalidErrorKeys
        .map((errorName) => {
          const customMessage = errorName === 'server' ? this.customServerErrorMessage : ''
          const errorMessage = customMessage || this.field.$model.errors[errorName]
          return errorMessage
        })
        .filter((error) => error)

      const uniqueErrorsSet = new Set(allActiveErrors)
      return Array.from(uniqueErrorsSet)
    },
  },
}
