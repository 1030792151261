<template>
  <form class="search-with-overlay" @submit.prevent="searchSubmit">
    <div class="search-with-overlay__wrapper">
      <input
        ref="searchInput"
        type="search"
        :placeholder="$t('home.hero.search')"
        class="form-input form-input--sub-middle search-with-overlay__search"
        :value="searchQuery"
        @input="(e) => $emit('searchQueryChange', e.target.value)"
      />
      <app-button
        type="submit"
        :text="searchButtonText"
        :variant="searchButtonVariant"
        size="sub-middle"
        class="search-with-overlay__submit"
      />
    </div>

    <search-overlay
      v-if="isOverlayEnabled && searchQuery && overlayQuery"
      :queryString="overlayQuery"
      class="search-with-overlay__overlay"
      @itemClicked="$emit('close')"
    />
    <i v-if="isIconShow" class="icon-search search-with-overlay__icon" />
  </form>
</template>
<script>
import { debounce } from '@/lib/utils/debounce'

import SearchOverlay from '@/components/search/SearchOverlay.vue'

export default {
  name: 'SearchFormWithOverlay',
  components: {
    SearchOverlay,
  },
  props: {
    isIconShow: {
      type: Boolean,
      default: false,
    },
    isFocus: {
      type: Boolean,
      default: false,
    },
    searchButtonText: {
      type: String,
      default: '',
    },
    searchButtonVariant: {
      type: String,
      default: 'primary',
    },
    isOverlayEnabled: {
      type: Boolean,
      default: true,
    },
    searchQuery: {
      type: String,
      default: '',
    },
  },
  emits: ['close', 'searchQueryChange'],
  data() {
    return {
      overlayQuery: '',
    }
  },
  watch: {
    isFocus() {
      this.$refs.searchInput.focus()
    },
    searchQuery: {
      handler: debounce(function () {
        this.overlayQuery = this.searchQuery
      }, 300),
      immediate: true,
    },
  },
  methods: {
    searchSubmit() {
      if (this.$route.name !== 'search') {
        this.$router.push({
          name: 'search',
          query: { string: this.searchQuery, tab: 'myProducts', changeToAllProducts: true },
        })
        this.$emit('close')
      }
    },
  },
}
</script>
