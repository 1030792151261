import { mapMutations } from 'vuex'

import contentfulComponentMixin from '@/components/common/contentful/mixins/contentfulComponentMixin'

export default {
  mixins: [contentfulComponentMixin],
  methods: {
    ...mapMutations('app', ['SET_IS_CONTENTFUL_PREVIEW', 'SET_CONTENTFUL_INITIALIZED']),
    async contentfulInit() {
      const queryParams = new URLSearchParams(window.location.search)
      const previewToken = queryParams.get('preview-token')
      if (previewToken) {
        this.$contentful.initPreviewClient(previewToken)
      } else {
        this.$contentful.initDefaultClient()
      }
      this.SET_IS_CONTENTFUL_PREVIEW(previewToken)

      const layoutConfig = {
        query: {
          skip: 0,
          limit: 1,
          include: 4,
          order: 'sys.createdAt',
          content_type: 'layout',
        },
        key: 'Layout',
      }

      const productConfig = {
        query: {
          skip: 0,
          limit: 400,
          include: 1,
          order: 'sys.createdAt',
          content_type: 'product',
          select:
            'sys.id,fields.slug,fields.title,fields.types,fields.subtypes,fields.subtypesLevel2',
        },
        key: 'Products',
      }

      const pagesConfig = {
        query: {
          skip: 0,
          limit: 200,
          include: 1,
          order: 'sys.createdAt',
          content_type: 'page',
        },
        key: 'Pages',
      }

      const punchoutConfig = {
        query: {
          skip: 0,
          limit: 1,
          include: 1,
          order: 'sys.createdAt',
          content_type: 'punchoutUnit',
          'fields.dunsId': this.$store.state.punchout.punchoutDunsId,
        },
        key: 'PunchoutUnit',
      }

      const loadAllEntries = async (requestConig) => {
        const query = { ...requestConig.query }
        const entries = []
        let skip = 0
        let shouldLoad = true
        while (shouldLoad) {
          const { items, total } = await this.$contentful.client.getEntries(query)
          entries.push(...items)
          skip = skip + query.limit
          query.skip = skip
          shouldLoad = entries.length < total
        }
        return entries
      }

      const isPunchout = this.$store.state.punchout.punchoutDunsId
      const isContentfulPreview = this.$contentful.isPreviewMode

      const [configurationResponse, productsMeta, punchoutResponse, pageEntriesResponse] =
        await Promise.all([
          this.fetchItems(layoutConfig.query, layoutConfig.key),
          loadAllEntries(productConfig),
          isPunchout ? this.fetchItems(punchoutConfig.query, punchoutConfig.key) : [],
          isContentfulPreview ? loadAllEntries(pagesConfig) : [],
        ])
      const configuration = configurationResponse[0]?.fields || {}
      const punchout = punchoutResponse[0]?.fields || {}
      const slots = {}
      const settings = {}
      const allPageEntries = pageEntriesResponse

      Object.entries(configuration).forEach((el) => {
        if (el[0].includes('settings')) {
          let propName = el[0].replace('settings', '')
          propName = propName.charAt(0).toLowerCase() + propName.slice(1)
          settings[propName] = el[1]
        }
        if (el[0].includes('slot')) {
          let propName = el[0].replace('slot', '')
          propName = propName.charAt(0).toLowerCase() + propName.slice(1)
          slots[propName] = el[1]
        }
      })
      await this.$contentful.service.initData({
        productsMeta,
        settings,
        slots,
        punchout,
        allPageEntries,
      })
      this.hubspotTrackerTmpl = this.renderScript(
        this.$contentful.service.getHubspotGlobalTrackerSnippet(),
      )
      this.SET_CONTENTFUL_INITIALIZED(true)
    },
  },
}
