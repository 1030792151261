import ApiBase from '@/lib/api/v1/api-base'

class Inventory extends ApiBase {
  constructor(transport) {
    super(transport)
    this.downloadSearchResults = this.downloadSearchResults.bind(this)
  }

  /**
   * Download inventory search results.
   */
  downloadSearchResults(data, userId) {
    return this._withDecoratedPromise(() => {
      return this.transport.post(
        `/rest/api/v1/users/${this._getUserId(userId)}/search/export`,
        data,
      )
    })
  }
}

export default Inventory
