<template>
  <section :class="classList" class="text-section">
    <template v-if="media">
      <app-video
        v-if="media.isVideoMedia"
        :preview="media.preview"
        :url="media.url"
        class="text-section__image"
      />
      <img v-else :src="media.url" class="text-section__image" />
    </template>
    <div class="text-section__text">
      <RichRenderer :richTextData="mainContent" />
    </div>
  </section>
</template>
<script>
import AppVideo from '@/components/common/AppVideo.vue'

export default {
  name: 'TextSection',
  components: {
    AppVideo,
  },
  props: {
    mainContent: {
      type: Object,
      default: () => {},
    },
    media: {
      type: Object,
      default: () => {},
    },
    mediaAlignment: {
      type: String,
      default: '',
    },
    mediaFloating: {
      type: Boolean,
      default: false,
    },
    paragraphMediaProportion: {
      type: String,
      default: '',
    },
    marginTop: {
      type: String,
      default: '',
    },
    container: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classList() {
      const mediaAlignmentClassName = this.mediaAlignment === 'Right' ? 'text-section--right' : ''
      const mediaFloatingClassName = this.mediaFloating ? 'text-section--floating' : ''
      const proportionMap = {
        '6/0': 'text-section--proportion-6-0',
        '5/1': 'text-section--proportion-5-1',
        '4/2': 'text-section--proportion-4-2',
        '3/3': 'text-section--proportion-3-3',
        '2/4': 'text-section--proportion-2-4',
        '1/5': 'text-section--proportion-1-5',
      }
      const marginTopMap = {
        None: '',
        Small: 'margin-top-small',
        Medium: 'margin-top-medium',
        Large: 'margin-top-large',
      }
      const proportionClassName = proportionMap[this.paragraphMediaProportion]
      const marginTopClassName = marginTopMap[this.marginTop]
      const containerClassName = this.container ? 'text-section--container' : ''
      return [
        mediaAlignmentClassName,
        mediaFloatingClassName,
        proportionClassName,
        marginTopClassName,
        containerClassName,
      ].filter((el) => el)
    },
  },
}
</script>
