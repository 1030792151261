import Transport from '@/lib/api/transport'
import ASM from '@/lib/api/v1/asm'
import Auth from '@/lib/api/v1/auth'
import Cart from '@/lib/api/v1/cart'
import Inventory from '@/lib/api/v1/inventory'
import InventoryTracking from '@/lib/api/v1/inventory-tracking'
import Invoices from '@/lib/api/v1/invoices'
import Orders from '@/lib/api/v1/orders'
import Products from '@/lib/api/v1/products'
import Quotes from '@/lib/api/v1/quotes'
import ReserveOrders from '@/lib/api/v1/reserve-orders'
import User from '@/lib/api/v1/user'

class Api {
  /**
   * Initialize a new instance of the API class.
   * @param settings
   * @return {*}
   */
  constructor(settings = null) {
    if (Api.instance) {
      return Api.instance
    }
    Api.instance = this
    this.initApiModules(settings)
  }

  /**
   * Initialize API modules.
   * @param settings
   */
  initApiModules(settings) {
    this.transport = new Transport(settings.services.api.baseUrl)
    this.user = new User(this.transport)
    this.orders = new Orders(this.transport)
    this.auth = new Auth(this.transport, settings.services.api.apiKey)
    this.products = new Products(this.transport)
    this.cart = new Cart(this.transport)
    this.invoices = new Invoices(this.transport)
    this.asm = new ASM(this.transport)
    this.quotes = new Quotes(this.transport)
    this.inventory = new Inventory(this.transport)
    this.reserveOrders = new ReserveOrders(this.transport)
    this.inventoryTracking = new InventoryTracking(this.transport)
  }
}

export default Api
