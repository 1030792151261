import { createRouter as _createRouter, createWebHistory } from 'vue-router'

import { setCanonicalLink } from '@/lib/utils/browserHelpers'
import routeUtils from '@/lib/utils/routes'

import dynamicRoutes from '@/router/dynamic'
import routes from '@/router/routes'

import { getProductCrumbs } from '@/components/common/contentful/helpers/contentfulHelpers'

export function createRouter() {
  return _createRouter({ routes, history: createWebHistory() })
}

export function initRouter({ router, store, emitter, ContentfulService }) {
  let isEnabled = true

  /**
   * Add css class to body with a name of the current route.
   */
  router.afterEach((to, from) => {
    const isGoToSameRoute = to.fullPath === from.fullPath
    if (!(isGoToSameRoute && to.name === 'redirect')) {
      document.body.classList.remove('overflow-hidden')
      if (from.meta.className) document.body.classList.remove(from.meta.className)
      if (to.meta.className) document.body.classList.add(to.meta.className)
    }
    if (isGoToSameRoute) return
    if (!(to.redirectedFrom && to.name === 'authentication'))
      store.dispatch('app/setPreviousRoute', from)
    store.dispatch('breadcrumbs/setPath', to)
    if (to.name !== 'search') store.commit('app/SET_HEADER_SEARCH_QUERY', '')
    emitter.emit('route.after', { to, from })
    setCanonicalLink()
  })

  router.beforeEach((to, from, next) => {
    if (!isEnabled) {
      return
    }

    const redirect = ContentfulService.service.getRedirect(to.path)
    if (redirect) {
      if (redirect.includes('http')) {
        window.location.href = redirect
      } else {
        next(redirect)
      }
      return
    }

    const isFullPathUrl = to.fullPath.includes('://')
    const isContainsDoubleSlashes = to.fullPath.includes('//')
    if (!isFullPathUrl && isContainsDoubleSlashes) {
      next({
        path: to.fullPath.replaceAll('//', '/'),
        params: to.params,
        query: to.query,
      })
      return
    }

    if (to.meta.isBackToTopBtn) {
      store.dispatch('app/toggleBackToTopBtn', true)
    } else {
      if (store.state.app.isBackToTopBtnVisible) store.dispatch('app/toggleBackToTopBtn', false)
    }

    if (!store.state.app.isInitialized) {
      store.watch(
        (state) => state.app.isInitialized,
        (value) => {
          if (value === true) {
            checkRoute()
          }
        },
      )
    } else {
      checkRoute()
    }

    async function checkRoute() {
      if (store.getters['session/isUserLoggedIn'] && to.matched[0].meta.guestRoute) {
        next('/')
      } else if (!store.getters['session/isUserLoggedIn'] && to.matched[0]?.meta?.privateRoute) {
        store.dispatch('app/setPreviousRoute', to)
        next({ name: 'authentication' })
      } else if (store.getters['session/isUserLoggedIn'] && to.matched[0]?.meta?.logoutRoute) {
        store.dispatch('session/logout').then(() => {
          store.dispatch('app/setPreviousRoute', from)
          next('/authentication')
        })
      } else if (to.name === 'redirect') {
        const rootSlug = routeUtils.getSlugFromPathMatch(to.params.pathMatch, 0)
        const endSlug = routeUtils.getSlugFromPathMatch(to.params.pathMatch)

        let cProduct, route, slugPath, crumbs
        const cPage = ContentfulService.service.getPageBySlug(endSlug)

        if (!cPage) {
          cProduct = ContentfulService.service.getProductMetaBySlug(rootSlug)
          slugPath = rootSlug === endSlug ? endSlug : `${rootSlug}/${endSlug}`
        }

        if (cPage && cPage.fields) {
          crumbs = cPage.crumbs.map((el) => ({
            name: el.fields?.title,
            link: el.fields?.slug,
          }))
          slugPath = cPage.slug

          if (ContentfulService.service.isEducationalContent(cPage)) {
            next(cPage.link)
            return
          }

          Object.entries(dynamicRoutes).forEach((k) => {
            if (k[1].pageTypes && k[1].pageTypes.includes(cPage.fields.pageType)) {
              route = k[1]
              if (k[0] === 'category' && !cPage.fields.subpages) {
                route = dynamicRoutes.subcategory
              }

              const afterLoginprevRouteRedirectPageTypes = ['catalog', 'category', 'subcategory']
              if (afterLoginprevRouteRedirectPageTypes.includes(k[0]))
                to.meta.isAfterLoginRedirectToPrevRoute = true
            }
          })

          to.matched[0].components.default = await route
            .component()
            .then((module) => module.default)
          to.matched[0].props.default = {
            cPage: cPage,
            catalog: cPage.crumbs[0].fields,
          }
          to.meta.className = route.className
          to.meta.crumbs = crumbs
          to.meta.isScrollingUp = !(
            from.meta.className === to.meta.className && to.meta.className === 'catalog-page'
          )
        } else if (cProduct) {
          crumbs = getProductCrumbs(cProduct)

          route = dynamicRoutes.product

          to.matched[0].components.default = await route
            .component()
            .then((module) => module.default)
          to.meta.className = route.className
          to.meta.crumbs = [...crumbs, { name: cProduct.fields.title, link: cProduct.fields.slug }]
          to.params.key = rootSlug
          to.meta.isAfterLoginRedirectToPrevRoute = true
        } else {
          if (to.path !== from.path) {
            const routesList = router.getRoutes()
            const notFoundRoute = routesList.find((route) => route.name === 'not-found')
            const dynamicallyCreatedToPathRoute = routesList.find((route) => route.name === to.path)
            if (!dynamicallyCreatedToPathRoute) {
              const newPageRoute = { ...notFoundRoute }
              newPageRoute.path = to.path
              newPageRoute.name = to.path
              router.addRoute(newPageRoute)
            }
            next({ name: to.path })
          }
          return
        }

        next()
        const hash = to.hash || ''
        const newLocation = '/' + slugPath + hash + location.search
        history.replaceState(history.state, '', newLocation)
      } else {
        next()
      }
    }
  })

  emitter.on('contentful.rich:bio-link', (aNode) => {
    const link = aNode.pathname + aNode.search
    router.push(link)
  })

  /**
   * Disable router.
   */
  emitter.on('router.stop', () => {
    isEnabled = false
  })

  /**
   * Enable router.
   */
  emitter.on('router.start', () => {
    isEnabled = true
  })
}
