import ApiBase from '@/lib/api/v1/api-base'

class ASM extends ApiBase {
  constructor(transport) {
    super(transport)
    this.login = this.login.bind(this)
    this.logout = this.logout.bind(this)
    this.suggestions = this.suggestions.bind(this)
    this.emulate = this.emulate.bind(this)
    this.stopEmulation = this.stopEmulation.bind(this)
  }

  static getApiUrl(endpoint = '') {
    return `/rest/api/v1/asm${endpoint}`
  }

  login(username, password) {
    return this._withDecoratedPromise(() => {
      return this.transport.post(ASM.getApiUrl('/login'), {
        username,
        password,
      })
    })
  }

  logout() {
    return this._withDecoratedPromise(() => {
      return this.transport.get(ASM.getApiUrl('/logout'))
    })
  }

  suggestions(query = '') {
    return this._withDecoratedPromise(() => {
      const endpoint = `/customers/suggestions?customerId=${encodeURIComponent(query)}`
      return this.transport.get(ASM.getApiUrl(endpoint))
    })
  }

  emulate(customerId) {
    return this._withDecoratedPromise(() => {
      const headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
      }
      const data = `customerId=${encodeURIComponent(customerId)}&cartId=current`
      return this.transport.post(ASM.getApiUrl('/customers/emulate'), data, headers)
    })
  }

  stopEmulation() {
    return this._withDecoratedPromise(() => {
      return this.transport.post(ASM.getApiUrl('/customers/stopEmulate'))
    })
  }
}

export default ASM
