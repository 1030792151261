import accountPortal from '@/store/modules/account-portal'
import app from '@/store/modules/app'
import asm from '@/store/modules/asm'
import breadcrumbs from '@/store/modules/breadcrumbs'
import cart from '@/store/modules/cart'
import cartFlow from '@/store/modules/cartFlow'
import company from '@/store/modules/company'
import currentInventory from '@/store/modules/current-inventory'
import inventory from '@/store/modules/inventory'
import inventoryLog from '@/store/modules/inventory-log'
import inventoryThresholds from '@/store/modules/inventory-thresholds'
import orderTemplate from '@/store/modules/order-template'
import orders from '@/store/modules/orders'
import product from '@/store/modules/product'
import punchout from '@/store/modules/punchout'
import quotes from '@/store/modules/quotes'
import reserveOrders from '@/store/modules/reserve-orders'
import roles from '@/store/modules/roles'
import search from '@/store/modules/search'
import session from '@/store/modules/session'

export default {
  'account-portal': accountPortal,
  app,
  asm,
  breadcrumbs,
  cart,
  cartFlow,
  inventory,
  product,
  punchout,
  quotes,
  search,
  session,
  company,
  roles,
  orders,
  'reserve-orders': reserveOrders,
  'current-inventory': currentInventory,
  'inventory-log': inventoryLog,
  'inventory-thresholds': inventoryThresholds,
  'order-template': orderTemplate,
}
