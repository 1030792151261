<template>
  <ul v-if="menu && menu.length" class="footer-menu">
    <li v-for="menuItem in menu" :key="menuItem.title" class="footer-menu__item">
      <span class="footer-menu__title-line">
        <span
          v-if="isMobile"
          :class="{ 'footer-menu__toggle-icon--open': menuItem.isOpen }"
          class="footer-menu__toggle-icon"
          @click="toggleSubmenu(menuItem)"
        />
        <a
          :href="menuItem.link"
          class="footer-menu__title"
          @click.prevent="navigateTo(menuItem.link)"
          >{{ menuItem.title }}</a
        >
      </span>
      <ul v-if="menuItem.isOpen" class="footer-menu__sublist">
        <li v-for="subitem in menuItem.subitems" :key="subitem.title" class="footer-menu__subitem">
          <a
            :href="subitem.link"
            class="footer-menu__link"
            @click.prevent="navigateTo(subitem.link)"
            v-html="subitem.title"
          />
        </li>
      </ul>
    </li>
  </ul>
</template>
<script>
import contentfulAutowireGlobalMixin from '@/components/common/contentful/mixins/contentfulAutowireGlobalMixin'
import navigationMixin from '@/components/common/mixins/navigationMixin'
import screenWidthMixin from '@/components/common/mixins/screenWidthMixin'

export default {
  name: 'FooterMenu',
  mixins: [screenWidthMixin, contentfulAutowireGlobalMixin, navigationMixin],
  data() {
    return {
      menu: null,
    }
  },
  methods: {
    toggleSubmenu(menuItem) {
      menuItem.isOpen = !menuItem.isOpen
    },
    initData(entries) {
      if (!entries?.length) return
      this.menu = entries
        .map((el) => {
          const page = this.$contentful.service.getPageBySlug(el.fields?.catalogRoot.fields?.slug)
          return page
            ? {
                isOpen: !this.isMobile,
                title: el.fields.title,
                link: page.link,
                subitems: page.fields?.subpages
                  ?.map((category) => {
                    // @TODO Fragile workaround. Subject to refactor Service type category into separate facet
                    if (category && category.fields && category.fields.title !== 'Services') {
                      return {
                        title: category.fields.title,
                        link: category.link,
                      }
                    }
                  })
                  .filter((el) => el),
              }
            : null
        })
        .filter((el) => el)
    },
  },
}
</script>
