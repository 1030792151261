<template>
  <footer class="app-footer">
    <div class="app-footer__inner">
      <div class="app-footer__top-block">
        <div class="container">
          <footer-menu />
          <div class="app-footer__contacts-line">
            <div class="app-footer__contacts-wrap">
              <h3 class="text-color-navy app-footer__contacts-title text-h3">Questions?</h3>
              <ul v-if="contactItems" class="app-footer__contacts-items">
                <li v-for="item in contactItems" :key="item.title" class="contacts-item">
                  <h4 class="text-color-navy contacts-item__title text-h4">
                    {{ item.title }}
                  </h4>
                  <ul class="contacts-item__lines">
                    <li class="contacts-item__line">
                      <img :src="icons.phoneIcon" class="contacts-item__icon" />
                      <div class="contacts-item__text">
                        <span class="contacts-item__label">Phone:</span>
                        <a
                          v-if="item.phone"
                          :href="`tel:${item.phone}`"
                          class="contacts-item__link"
                          v-html="item.phone"
                        />
                      </div>
                    </li>
                    <li class="contacts-item__line">
                      <img :src="icons.emailIcon" class="contacts-item__icon" />
                      <div class="contacts-item__text">
                        <span class="contacts-item__label">Email:</span>
                        <a
                          v-if="item.email"
                          :href="`mailto:${item.email}`"
                          class="contacts-item__link"
                          v-html="item.email"
                        />
                      </div>
                    </li>
                  </ul>
                </li>
              </ul>
              <div class="app-footer__contacts-actions">
                <a
                  v-if="contactButton"
                  id="footer-contact-us-button"
                  :href="contactButton.url"
                  class="button button--sub-middle button--secondary-inversed app-footer__contact-button"
                  @click.prevent="navigateTo(contactButton.url)"
                  v-html="contactButton.title"
                />
                <ul v-if="socials" class="app-footer__socials">
                  <li v-for="social in socials" :key="social.url" class="app-footer__social">
                    <a
                      v-if="social.url"
                      :href="social.url"
                      class="app-footer__social-link"
                      @click.prevent="navigateTo(social.url)"
                    >
                      <i :class="social.iconClass" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="app-footer__form-wrapper">
              <h2 class="text-h3 text-color-navy app-footer__title" v-html="$t('footer.title')" />
              <p class="app-footer__subtitle" v-html="$t('footer.subtitle')" />
              <form class="app-footer__form" @submit.prevent="newsletterRequest">
                <form-input
                  v-model.trim="v$.newsletterEmail.$model.value"
                  :field="v$.newsletterEmail"
                  :isValidationRun="isValidationRun"
                />
                <app-button
                  :text="$t('footer.btnText')"
                  type="submit"
                  size="sub-middle"
                  variant="secondary-inversed"
                />
              </form>
              <p
                v-if="newsletterSuccessMsg"
                class="app-footer__form-subtext"
                v-html="$t('footer.successMsg')"
              />
              <div v-if="privacyText" class="app-footer__privacy" v-html="privacyText"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="app-footer__copyright copyright">
        <div class="container">
          <div class="copyright__inner">
            <p class="copyright__text" v-html="$t('footer.copyright', { year: currentYear })" />
            <ul v-if="copyrightLinks && copyrightLinks.length" class="copyright__items-list">
              <li v-for="item in copyrightLinks" :key="item.title" class="copyright__item">
                <router-link v-if="!item.external" :to="item.link" class="copyright__link">
                  {{ item.title }}
                </router-link>
                <a
                  v-else
                  class="copyright__link"
                  :href="item.link"
                  rel="noopener noreferrer"
                  target="_blank"
                  v-html="item.title"
                />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
import { useVuelidate } from '@vuelidate/core'

import contentfulComponentMixin from '@/components/common/contentful/mixins/contentfulComponentMixin'
import navigationMixin from '@/components/common/mixins/navigationMixin'
import newsletterFormMixin from '@/components/common/mixins/newsletterFormMixin'

import emailIcon from '@/assets/images/icons/email-icon.svg'
import phoneIcon from '@/assets/images/icons/phone-icon.svg'

import FooterMenu from '../common/FooterMenu.vue'

export default {
  name: 'AppFooter',
  components: {
    FooterMenu,
  },
  mixins: [contentfulComponentMixin, newsletterFormMixin, navigationMixin],
  setup() {
    return {
      v$: useVuelidate(),
    }
  },
  data() {
    return {
      contactItems: [
        {
          title: 'North America & Asia Pacific',
          phone: '+1 516-483-1196',
          email: 'customerservice@bioivt.com',
        },
        {
          title: 'Europe, Middle East & Africa',
          phone: '+44 (0) 1444 707333',
          email: 'cseurope@bioivt.com',
        },
      ],
      icons: {
        emailIcon: emailIcon,
        phoneIcon: phoneIcon,
      },
      socials: [
        {
          iconClass: 'icon-twitter',
          url: 'https://twitter.com/BioIVT',
        },
        {
          iconClass: 'icon-linkedin',
          url: 'https://www.linkedin.com/company/bioivt',
        },
      ],
      contactButton: {
        title: 'Contact Us',
        url: '/about/contact-us',
      },
      copyrightLinks: [
        { title: this.$t('footer.trademark'), link: '/trademarks' },
        { title: this.$t('footer.privacy'), link: '/privacy-policy' },
        { title: this.$t('footer.terms'), link: '/terms-and-conditions' },
        {
          title: this.$t('footer.websiteTerms'),
          link: '/about/website-terms-of-use',
        },
      ],
      currentYear: new Date().getFullYear(),
      privacyText:
        this.getSiteCopy('copyGroupCommon', 'copyItemFooterSignUpForNewsletterPrivacyText') || '',
    }
  },
}
</script>
