import _ from 'lodash'

import ApiBase from '@/lib/api/v1/api-base'

class Orders extends ApiBase {
  constructor(transport) {
    super(transport)
    this.getOrders = this.getOrders.bind(this)
    this.sendReorder = this.sendReorder.bind(this)
    this.getOrderById = this.getOrderById.bind(this)
    this.downloadDocs = this.downloadDocs.bind(this)
    this.getOrderLines = this.getOrderLines.bind(this)
  }

  /**
   * Get a list of recent orders.
   * @returns {Promise<any>}
   */
  getOrders(params, userId) {
    return this._withDecoratedPromise(() => {
      params = _.pickBy(params)
      params = new URLSearchParams(params).toString()
      return this.transport.get(`/rest/api/v1/users/${this._getUserId(userId)}/v2/orders?${params}`)
    })
  }

  /**
   * Send Reorder
   * @param data
   * @param userId
   */
  sendReorder(data, userId) {
    return this._withDecoratedPromise(() => {
      return this.transport.post(
        `/rest/api/v1/users/${this._getUserId(userId)}/carts/duplicate/quote/reorder`,
        data,
      )
    })
  }

  /**
   * Get an order by ID.
   * @mocked
   * @returns {Promise<any>}
   */
  getOrderById(orderId, userId) {
    return this._withDecoratedPromise(() => {
      return this.transport.get(
        `/rest/api/v1/users/${this._getUserId(userId)}/v2/orders/${encodeURIComponent(orderId)}`,
      )
    })
  }

  /**
   * Download an archive of order docs.
   * @returns {Promise<any>}
   */
  downloadDocs(docsList) {
    return this._withDecoratedPromise(() => {
      const requestOptions = {
        responseType: 'arraybuffer',
      }
      return this.transport.post('/rest/api/v1/media/zip', docsList, null, requestOptions)
    })
  }

  getOrderLines(params) {
    return this._withDecoratedPromise(() => {
      params = _.pickBy(params)
      params = new URLSearchParams(params).toString()
      return this.transport.get(`/rest/api/v1/users/${this._getUserId()}/order-lines?${params}`)
    })
  }
}

export default Orders
