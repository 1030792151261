<template>
  <div class="logo">
    <a href="/" @click.prevent="$emit('clicked')">
      <img v-if="logo" :src="getLogoData.url" :alt="getLogoData.alt" height="66" />
    </a>
  </div>
</template>
<script>
import contentfulAutowireGlobalMixin from '@/components/common/contentful/mixins/contentfulAutowireGlobalMixin'

export default {
  name: 'Logo',
  mixins: [contentfulAutowireGlobalMixin],
  emits: ['clicked'],
  data() {
    return {
      logo: null,
    }
  },
  computed: {
    getLogoData() {
      return this.logo
        ? {
            url: `${this.logo.file.url}?q=90`,
            alt: this.logo.description || this.logo.title,
          }
        : null
    },
  },
  methods: {
    initData(entry) {
      this.logo = entry
    },
  },
}
</script>
