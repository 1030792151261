<template>
  <div
    class="form-group"
    :class="[`form-group--${sizeClass}`, { 'form-group--inline': model.inline }]"
  >
    <span
      v-if="model.isLabelVisible"
      :class="`form-group__label--${sizeClass}`"
      class="form-group__label"
      v-html="model.fieldLabel"
    />
    <div
      :class="{
        'form-group__input-wrapper--mark-check': isValidationRun && !field.value.$invalid,
        'form-group__input-wrapper--mark-cross': isValidationRun && field.value.$invalid,
      }"
      class="form-group__input-wrapper"
    >
      <input
        v-if="model.type === 'mask'"
        :id="id || null"
        v-maska="model.maskData"
        :value="modelValue"
        :data-maska="model.mask"
        class="form-input"
        :class="inputClasses"
        :disabled="disabled"
        type="tel"
        :placeholder="model.placeholder"
        :maxlength="model.maxLength"
        :readonly="model.readonly || readonly"
        @input="onMaskInput"
        @focus="$emit('focus', $event.target.value)"
        @blur="$emit('blur', $event.target.value)"
      />
      <input
        v-else
        :id="id || null"
        class="form-input"
        :class="inputClasses"
        :disabled="disabled"
        :type="model.type"
        :placeholder="model.placeholder"
        :maxlength="model.maxLength"
        :readonly="model.readonly || readonly"
        :value="modelValue"
        @input="$emit('update:modelValue', $event.target.value)"
        @focus="$emit('focus', $event.target.value)"
        @blur="$emit('blur', $event.target.value)"
      />
    </div>
    <template v-if="isValidationRun && errors">
      <p v-for="(error, idx) in errors" :key="idx" :class="hintClass" class="form-hint">
        <span v-html="error" />
      </p>
    </template>
  </div>
</template>
<script>
import { vMaska } from 'maska'

import formControlMixin from '@/components/common/mixins/formControlMixin'

export default {
  name: 'FormInput',
  directives: { maska: vMaska },
  mixins: [formControlMixin],
  props: {
    id: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue', 'blur', 'focus'],
  computed: {
    inputStyleAsArray() {
      return Array.isArray(this.model.inputStyle)
        ? this.model.inputStyle.filter((el) => el).map((el) => `form-input--${el}`)
        : this.model.inputStyle
          ? [`form-input--${this.model.inputStyle}`]
          : []
    },
    inputClasses() {
      return [
        ...this.inputStyleAsArray,
        `form-input--${this.sizeClass}`,
        {
          'form-input--orange': this.isValidationRun && this.field.value.$invalid,
          'form-input--green': this.isValidationRun && !this.field.value.$invalid,
          'form-input--disabled': this.disabled,
        },
      ]
    },
    hintClass() {
      return ['small', 'sub-small'].includes(this.model.size) ? 'form-hint--small' : ''
    },
    sizeClass() {
      return this.model.size || 'sub-middle'
    },
  },
  methods: {
    onMaskInput() {
      this.$emit('update:modelValue', this.model.maskData.unmasked)
    },
  },
}
</script>
